import React, { Component } from 'react';

import QRcode from '../../assets/NewsBanner/WechatIMG.jpeg';

export default class QRcodeNews extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		if (this.props.state.currentLanguage === 'english') {
			return (
				<div>
					<div style={{ marginTop: '120px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
						<h4 className="boldFont" style={{ marginBottom: '20px' }}>
							J-Von medical's social media
						</h4>
						<h5 className="regFont">To follow our clinic new, you can add our clinic’s wechat.</h5>
						<img src={QRcode} alt="qrCode" style={{ width: '500px', height: '600px' }} />
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<div style={{ marginTop: '120px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
						<h4 className="boldFont" style={{ marginBottom: '20px' }}>
							J-Von medical的社交媒体
						</h4>
						<h5 className="regFont">请加我们的微信来了解最新资讯</h5>
						<img src={QRcode} alt="qrCode" style={{ width: '500px', height: '600px' }} />
					</div>
				</div>
			);
		}
	}
}
