import React, { Component } from 'react';
import axios from 'axios';

export default class Admin extends Component {
	constructor(props) {
		super(props);

		this.state = {
			status: '',
			display: 'none',
			nameFil: []
		};
	}

	submit = () => {
		axios
			.post('/login', {
				username: this.username.value,
				password: this.password.value
			})
			.then((res) => {
				console.log(res);
				if (res.status === 200) {
					window.location.href = 'http://localhost:3000/control';
					localStorage.setItem('token', JSON.stringify(res.data));
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	render() {
		return (
			<div
				style={{
					width: '100vw',
					height: '100vh',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}}
			>
				<input type="text" autoComplete="off" placeholder="username" ref={(ref) => (this.username = ref)} />
				<input type="password" autoComplete="off" placeholder="password" ref={(ref) => (this.password = ref)} />
				<button className="login-btn" type="button" onClick={this.submit}>
					Submit
				</button>
				{/* <div className="login-errDiv" style={{ display: this.state.display }}>
					{this.state.status}
				</div> */}
			</div>
		);
	}
}
