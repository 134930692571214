import React, { Component } from 'react';

import FemalePic1 from '../../assets/docImage/female1.jpeg';
import MalePic1 from '../../assets/docImage/rsz_male_doctor.jpg';

export default class ClinicDoctor extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        if (this.props.props.currentLanguage === 'english') {
            return ( <
                div className = "clinicDoctorDiv" >
                <
                div className = "clinicDoctorDiv-textOverDiv" > { /* <h1 className="clinicDoctorDiv-header">our doctors</h1> */ }

                <
                section className = "clinicDoctorDiv__section" >
                <
                img className = "clinicDoctorDiv__section__Div-img"
                src = { FemalePic1 }
                alt = "docImage" / >
                <
                div className = "clinicDoctorDiv__section__Div genderChangeDiv" >
                <
                h3 className = "clinicDoctorDiv__section-header" > Dr.Cheung < /h3> <
                section >
                Dr.Cheung is a family physician at the J - Von Medical Centre.Dr.Cheung graduated from UBC and trained at Calgary and had a full - service family practice in
                for 12 years.She is a female doctor, and she is working every Tuesday and Thursday <
                /section> <
                /div> <
                /section> <
                section className = "clinicDoctorDiv__section" >
                <
                img className = "clinicDoctorDiv__section__Div-img"
                src = { FemalePic1 }
                alt = "docImage" / >
                <
                div className = "clinicDoctorDiv__section__Div" >
                <
                h3 className = "clinicDoctorDiv__section-header" > Dr.Wei < /h3> <
                section >
                UBC graduated and she a family physician at J-Von Medical Centre in Burnaby. Though she maintains a broad scope of practice, Dr. Wei is most passionate about women’s health and care of young families. Dr Wei enjoys travelling and discovering unique restaurants. In her spare time she enjoys to travel, cook and spend time with family. She does not accepte  any new patient now. <
                /section> <
                /div> <
                /section> {
                    /* <section className="clinicDoctorDiv__section">
                    							<img className="clinicDoctorDiv__section__Div-img" src={FemalePic1} alt="docImage" />
                    							<div className="clinicDoctorDiv__section__Div">
                    								<h3 className="clinicDoctorDiv__section-header">Dr.Liu</h3>
                    								<section>
                    									She is a family physician at Jvon Medical Centre in Burnaby. Dr.Liu obtained her
                    									Medical Degree from the University of Columbia. Dr liu has been practicing the full
                    									scope of family medicine. She has a special interest in women’s health and she has a
                    									passion for patient health education. She also graduated from UBC. She Speaks
                    									English & Mandarin; and she is Working Monday & Wednesday
                    								</section>
                    							</div>
                    						</section> */
                } <
                section className = "clinicDoctorDiv__section" >
                <
                img className = "clinicDoctorDiv__section__Div-img"
                src = { FemalePic1 }
                alt = "docImage" / >
                <
                div className = "clinicDoctorDiv__section__Div" >
                <
                h3 className = "clinicDoctorDiv__section-header" > Dr.Zhai < /h3> <
                section >
                she is also Female doctor and she graduated from UBC.While not practicing medicine,
                Dr.Zhai has been practicing the full scope of family medicine.She has a special interest in women’ s health and she has a passion
                for patient health education.She enjoys spending time outdoors with her family.she is working Tuesday, Wednesday,
                Thursday, Saturday. <
                /section> <
                /div> <
                /section> <
                section className = "clinicDoctorDiv__section" >
                <
                img className = "clinicDoctorDiv__section__Div-img"
                src = { FemalePic1 }
                alt = "docImage" / >
                <
                div className = "clinicDoctorDiv__section__Div" >
                <
                h3 className = "clinicDoctorDiv__section-header" > Dr.Chen < /h3> <
                section >
                Dr. Chen graduated from the UBC Family Medicine Residency Program in 2019 and has been a locum physician at our clinic since 2020.
Dr. Chen had worked as a hospital pharmacist for 3 years prior to becoming a family doctor and therefore is very knowledgeable with pharmacotherapeutics.
She is a strong advocate for her patients and cares deeply for her patients' well-being.
She is fluent in English, Mandarin, and Taiwanese.
She is currently working on Mondays and Tuesdays.
She is seeing patients only on a walk-in basis and is not accepting new patients. <
                /section> <
                /div> <
                /section> <
                section className = "clinicDoctorMaleDiv__section genderChangeDiv" >
                <
                img className = "clinicDoctorMaleDiv__section__Div-img"
                src = { MalePic1 }
                alt = "docImage" / >
                <
                div className = "clinicDoctorMaleDiv__section__Div" >
                <
                h3 className = "clinicDoctorMaleDiv__section-header" > Dr.Lau < /h3> <
                section className = "paddingLeft" >
                He used to practice family medicine in HongKong.He studied medication in Canada and become a doctors in 1990 s.He practice full scope of family medicine and has been
                for a doctor
                for many years.he is a male doctor and he speaks English, Mandarin &
                Cantonese and he is Working every Monday and Saturday <
                /section> <
                /div> <
                /section> <
                section className = "clinicDoctorMaleDiv__section" >
                <
                img className = "clinicDoctorMaleDiv__section__Div-img"
                src = { MalePic1 }
                alt = "docImage" / >
                <
                div className = "clinicDoctorMaleDiv__section__Div" >
                <
                h3 className = "clinicDoctorDiv__section-header" > Dr.Zong < /h3> <
                section className = "paddingLeft" >
                he is passionate about identifying the cause of his patients’ symptoms.He was able to help many patients with complex medical problems He graduated from UBC and he is a Male doctor and he Speaks English & Mandarin and he working every Monday, Tuesday,
                Thursday, Friday and Saturday <
                /section> <
                /div> <
                /section> <
                section className = "clinicDoctorMaleDiv__section" >
                <
                img className = "clinicDoctorMaleDiv__section__Div-img"
                src = { MalePic1 }
                alt = "docImage" / >
                <
                div className = "clinicDoctorMaleDiv__section__Div" >
                <
                h3 className = "clinicDoctorDiv__section-header" > Dr.Gill < /h3> <
                section className = "paddingLeft" >
                Dr.Gill graduated from the University of British Columbia(UBC) Medical School and subsequently completed medical training in Family Medicine in calgary.Dr.Gill has been providing a full scope of care in all areas of family medicine.he believes in
                preventative care.He is a Male doctor and he Speaks English and little Punjabi and he is working Monday to Friday.in his spare time, he likes to travel. <
                /section> <
                /div> <
                /section> <
                /div> <
                /div>
            );
        } else {
            return ( <
                div className = "clinicDoctorDiv" > { /* <h1 className="clinicDoctorDiv-header">我们的医生</h1> */ } <
                div className = "clinicDoctorDiv-textOverDiv" >
                <
                section className = "clinicDoctorDiv__section" >
                <
                img className = "clinicDoctorDiv__section__Div-img"
                src = { FemalePic1 }
                alt = "docImage" / >
                <
                div className = "clinicDoctorDiv__section__Div genderChangeDiv" >
                <
                h3 className = "clinicDoctorDiv__section-header" > Dr.Cheung < /h3> <
                section >
                Dr.Cheung is a family physician at the J - Von Medical Centre.Dr.Cheung graduated from UBC and trained at Calgary and had a full - service family practice in
                for 12 years.She is a female doctor, and she is working every Tuesday and Thursday { ' ' } <
                h4 > 女医生 说普通话 广东话 工作周二和周四 < /h4> <
                /section> <
                /div> <
                /section> <
                section className = "clinicDoctorDiv__section" >
                <
                img className = "clinicDoctorDiv__section__Div-img"
                src = { FemalePic1 }
                alt = "docImage" / >
                <
                div className = "clinicDoctorDiv__section__Div" >
                <
                h3 className = "clinicDoctorDiv__section-header" > Dr.Wei < /h3> <
                section >
                UBC graduated and she a family physician at J-Von Medical Centre in Burnaby. Though she maintains a broad scope of practice, Dr. Wei is most passionate about women’s health and care of young families. Dr Wei enjoys travelling and discovering unique restaurants. In her spare time she enjoys to travel, cook and spend time with family. She does not accepte  any new patient now. <
                h4 > 女医生 还在收新病人 说普通话， 工作周一 周二 周五 < /h4> <
                /section> <
                /div> <
                /section> {
                    /* <section className="clinicDoctorDiv__section">
                    							<img className="clinicDoctorDiv__section__Div-img" src={FemalePic1} alt="docImage" />
                    							<div className="clinicDoctorDiv__section__Div">
                    								<h3 className="clinicDoctorDiv__section-header">Dr.Liu</h3>
                    								<section>
                    									She is a family physician at Jvon Medical Centre in Burnaby. Dr.Liu obtained her
                    									Medical Degree from the University of Columbia. Dr liu has been practicing the full
                    									scope of family medicine. She has a special interest in women’s health and she has a
                    									passion for patient health education. She also graduated from UBC. She Speaks
                    									English & Mandarin; and she is Working Monday & Wednesday
                    									<h4>女医生 说普通话 工作周一和周三</h4>
                    								</section>
                    							</div>
                    						</section> */
                } <
                section className = "clinicDoctorDiv__section" >
                <
                img className = "clinicDoctorDiv__section__Div-img"
                src = { FemalePic1 }
                alt = "docImage" / >
                <
                div className = "clinicDoctorDiv__section__Div" >
                <
                h3 className = "clinicDoctorDiv__section-header" > Dr.Zhai < /h3> <
                section >
                she is also Female doctor and she graduated from UBC.While not practicing medicine,
                Dr.Zhai has been practicing the full scope of family medicine.She has a special interest in women’ s health and she has a passion
                for patient health education.She enjoys spending time outdoors with her family.she is working Tuesday, Wednesday,
                Thursday, Saturday. <
                h4 > 女医生说普通话 工作周二 周三周四 < /h4> <
                /section> <
                /div> <
                /section> <
                section className = "clinicDoctorDiv__section" >
                <
                img className = "clinicDoctorDiv__section__Div-img"
                src = { FemalePic1 }
                alt = "docImage" / >
                <
                div className = "clinicDoctorDiv__section__Div" >
                <
                h3 className = "clinicDoctorDiv__section-header" > Dr.Chen < /h3> <
                section >
                Dr. Chen graduated from the UBC Family Medicine Residency Program in 2019 and has been a locum physician at our clinic since 2020.
Dr. Chen had worked as a hospital pharmacist for 3 years prior to becoming a family doctor and therefore is very knowledgeable with pharmacotherapeutics.
She is a strong advocate for her patients and cares deeply for her patients' well-being.
She is fluent in English, Mandarin, and Taiwanese.
She is currently working on Mondays and Tuesdays.
She is seeing patients only on a walk-in basis and is not accepting new patients. <
                h4 > 女医生 说国语， 工作周五 周六 < /h4> <
                /section> <
                /div> <
                /section> <
                section className = "clinicDoctorMaleDiv__section genderChangeDiv" >
                <
                img className = "clinicDoctorMaleDiv__section__Div-img"
                src = { MalePic1 }
                alt = "docImage" / >
                <
                div className = "clinicDoctorMaleDiv__section__Div" >
                <
                h3 className = "clinicDoctorMaleDiv__section-header" > Dr.Lau < /h3> <
                section className = "paddingLeft" >
                He used to practice family medicine in HongKong.He studied medication in Canada and become a doctors in 1990 s.He practice full scope of family medicine and has been
                for a doctor
                for many years.he is a male doctor and he speaks English, Mandarin &
                Cantonese and he is Working every Monday and Saturday <
                h4 > 男医生， 说普通话， 广东话， 工作周一和周六 < /h4> <
                /section> <
                /div> <
                /section> <
                section className = "clinicDoctorMaleDiv__section" >
                <
                img className = "clinicDoctorMaleDiv__section__Div-img"
                src = { MalePic1 }
                alt = "docImage" / >
                <
                div className = "clinicDoctorMaleDiv__section__Div" >
                <
                h3 className = "clinicDoctorDiv__section-header" > Dr.Zong < /h3> <
                section className = "paddingLeft" >
                he is passionate about identifying the cause of his patients’ symptoms.He was able to help many patients with complex medical problems He graduated from UBC and he is a Male doctor and he Speaks English & Mandarin and he working every Monday, Tuesday,
                Thursday, Friday and Saturday <
                h4 > 男医生， 说普通话 工作周一周三周四周五周六 < /h4> <
                /section> <
                /div> <
                /section> <
                section className = "clinicDoctorMaleDiv__section" >
                <
                img className = "clinicDoctorMaleDiv__section__Div-img"
                src = { MalePic1 }
                alt = "docImage" / >
                <
                div className = "clinicDoctorMaleDiv__section__Div" >
                <
                h3 className = "clinicDoctorDiv__section-header" > Dr.Gill < /h3> <
                section className = "paddingLeft" >
                Dr.Gill graduated from the University of British Columbia(UBC) Medical School and subsequently completed medical training in Family Medicine in calgary.Dr.Gill has been providing a full scope of care in all areas of family medicine.he believes in
                preventative care.He is a Male doctor and he Speaks English and little Punjabi and he is working Monday to Friday.in his spare time, he likes to travel. <
                h4 > 男医生， 说英文， 工作周一到周五 < /h4> <
                /section> <
                /div> <
                /section> <
                /div> <
                /div>
            );
        }
    }
}