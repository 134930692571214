import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import BackButton from '../../assets/icons/arrow-left-thick.svg';

export default class Renewal extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		if (this.props.state.currentLanguage === 'english') {
			return (
				<div className="renewal">
					<div className="renewal__center">
						<h2 className="renewal__center-header">Prescription Renewal</h2>
						<div>
							Prescription renewals can only be done in person or see doctor virtually. For seeing doctor
							virtually, the referrals cannot guarantee provided to patient and will depend on what kind
							of prescription.
						</div>
						<div>
							Any faxed request referrals will be returned to the pharmacy and letting pharmacists know
							the patient must see doctor in person or see doctors virtually to get it approved.
						</div>
						<div>Renewals are unable to be done without seeing a doctor by phone, email or fax.</div>
					</div>
					<Link className="backButton" to="/">
						<img src={BackButton} alt="backButton" /> Go Back
					</Link>
				</div>
			);
		} else {
			return (
				<div className="renewal">
					<div className="renewal__center">
						<h2 className="renewal__center-header">续处方药</h2>
						<div>
							续处方药只能是本人亲自和诊所医生面诊才可以续，我们现在可以通过网络和本人亲自到诊所来续处方药。由于疫情的原因，我们现在是提供网络视频看病，但是病人医生无法保证你一定会拿到你想要的处方药，需要医生来根据病因来推断，给出适合的方案。
						</div>
						<div>任何病人通过药局发过来的药单，会被退回。病人一定要看医生，无论是通过什么方式，亲自来，还是网络和医生交谈之后才能开出药单。</div>
					</div>
					<Link className="backButton" to="/">
						<img src={BackButton} alt="backButton" /> 返回
					</Link>
				</div>
			);
		}
	}
}
