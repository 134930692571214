import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Arrow from '../../assets/icons/corner-down-right.svg';

// import Plus from '../../assets/icons/plus.svg';
// import Minus from '../../assets/icons/minus.svg';

export default class FAQ extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showAnswer1: 'none',
			showAnswer2: 'none',
			showAnswer3: 'none',
			showAnswer4: 'none',
			showAnswer5: 'none',
			showAnswer6: 'none',
			showAnswer7: 'none',
			showAnswer8: 'none',
			showAnswer9: 'none',
			showAnswer10: 'none',
			showAnswer11: 'none',
			showAnswer12: 'none',
			showAnswer13: 'none',
			showAnswer14: 'none',
			showAnswer15: 'none',
			showAnswer16: 'none',
			showAnswer17: 'none',
			showAnswer18: 'none',
			showAnswer19: 'none',
			showAnswer20: 'none',
			showAnswer21: 'none',
			showAnswer22: 'none',
			showAnswer23: 'none',
			showAnswer24: 'none',
			showAnswer25: 'none',
			showAnswer26: 'none',
			showAnswer27: 'none',
			showAnswer28: 'none',
			showAnswer29: 'none',
			showAnswer30: 'none',
			showAnswer31: 'none',
			showAnswer32: 'none',
			showAnswer33: 'none'
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	divClicked1 = () => {
		if (this.state.showAnswer1 === 'none') {
			this.setState({ showAnswer1: 'block' });
		} else {
			this.setState({ showAnswer1: 'none' });
		}
	};

	divClicked2 = () => {
		if (this.state.showAnswer2 === 'none') {
			this.setState({ showAnswer2: 'block' });
		} else {
			this.setState({ showAnswer2: 'none' });
		}
	};
	divClicked3 = () => {
		if (this.state.showAnswer3 === 'none') {
			this.setState({ showAnswer3: 'block' });
		} else {
			this.setState({ showAnswer3: 'none' });
		}
	};
	divClicked4 = () => {
		if (this.state.showAnswer4 === 'none') {
			this.setState({ showAnswer4: 'block' });
		} else {
			this.setState({ showAnswer4: 'none' });
		}
	};
	divClicked5 = () => {
		if (this.state.showAnswer5 === 'none') {
			this.setState({ showAnswer5: 'block' });
		} else {
			this.setState({ showAnswer5: 'none' });
		}
	};
	divClicked6 = () => {
		if (this.state.showAnswer6 === 'none') {
			this.setState({ showAnswer6: 'block' });
		} else {
			this.setState({ showAnswer6: 'none' });
		}
	};
	divClicked7 = () => {
		if (this.state.showAnswer7 === 'none') {
			this.setState({ showAnswer7: 'block' });
		} else {
			this.setState({ showAnswer7: 'none' });
		}
	};
	divClicked8 = () => {
		if (this.state.showAnswer8 === 'none') {
			this.setState({ showAnswer8: 'block' });
		} else {
			this.setState({ showAnswer8: 'none' });
		}
	};
	divClicked9 = () => {
		if (this.state.showAnswer9 === 'none') {
			this.setState({ showAnswer9: 'block' });
		} else {
			this.setState({ showAnswer9: 'none' });
		}
	};

	divClicked10 = () => {
		if (this.state.showAnswer10 === 'none') {
			this.setState({ showAnswer10: 'block' });
		} else {
			this.setState({ showAnswer10: 'none' });
		}
	};
	divClicked11 = () => {
		if (this.state.showAnswer11 === 'none') {
			this.setState({ showAnswer11: 'block' });
		} else {
			this.setState({ showAnswer11: 'none' });
		}
	};
	divClicked12 = () => {
		if (this.state.showAnswer12 === 'none') {
			this.setState({ showAnswer12: 'block' });
		} else {
			this.setState({ showAnswer12: 'none' });
		}
	};
	divClicked13 = () => {
		if (this.state.showAnswer13 === 'none') {
			this.setState({ showAnswer13: 'block' });
		} else {
			this.setState({ showAnswer13: 'none' });
		}
	};
	divClicked14 = () => {
		if (this.state.showAnswer14 === 'none') {
			this.setState({ showAnswer14: 'block' });
		} else {
			this.setState({ showAnswer14: 'none' });
		}
	};
	divClicked15 = () => {
		if (this.state.showAnswer15 === 'none') {
			this.setState({ showAnswer15: 'block' });
		} else {
			this.setState({ showAnswer15: 'none' });
		}
	};
	divClicked16 = () => {
		if (this.state.showAnswer16 === 'none') {
			this.setState({ showAnswer16: 'block' });
		} else {
			this.setState({ showAnswer16: 'none' });
		}
	};
	divClicked17 = () => {
		if (this.state.showAnswer17 === 'none') {
			this.setState({ showAnswer17: 'block' });
		} else {
			this.setState({ showAnswer17: 'none' });
		}
	};
	divClicked18 = () => {
		if (this.state.showAnswer18 === 'none') {
			this.setState({ showAnswer18: 'block' });
		} else {
			this.setState({ showAnswer18: 'none' });
		}
	};
	divClicked19 = () => {
		if (this.state.showAnswer19 === 'none') {
			this.setState({ showAnswer19: 'block' });
		} else {
			this.setState({ showAnswer19: 'none' });
		}
	};
	divClicked20 = () => {
		if (this.state.showAnswer20 === 'none') {
			this.setState({ showAnswer20: 'block' });
		} else {
			this.setState({ showAnswer20: 'none' });
		}
	};
	divClicked21 = () => {
		if (this.state.showAnswer21 === 'none') {
			this.setState({ showAnswer21: 'block' });
		} else {
			this.setState({ showAnswer21: 'none' });
		}
	};
	divClicked22 = () => {
		if (this.state.showAnswer22 === 'none') {
			this.setState({ showAnswer22: 'block' });
		} else {
			this.setState({ showAnswer22: 'none' });
		}
	};
	divClicked23 = () => {
		if (this.state.showAnswer23 === 'none') {
			this.setState({ showAnswer23: 'block' });
		} else {
			this.setState({ showAnswer23: 'none' });
		}
	};
	divClicked24 = () => {
		if (this.state.showAnswer24 === 'none') {
			this.setState({ showAnswer24: 'block' });
		} else {
			this.setState({ showAnswer24: 'none' });
		}
	};
	divClicked25 = () => {
		if (this.state.showAnswer25 === 'none') {
			this.setState({ showAnswer25: 'block' });
		} else {
			this.setState({ showAnswer25: 'none' });
		}
	};
	divClicked26 = () => {
		if (this.state.showAnswer26 === 'none') {
			this.setState({ showAnswer26: 'block' });
		} else {
			this.setState({ showAnswer26: 'none' });
		}
	};
	divClicked27 = () => {
		if (this.state.showAnswer27 === 'none') {
			this.setState({ showAnswer27: 'block' });
		} else {
			this.setState({ showAnswer27: 'none' });
		}
	};
	divClicked28 = () => {
		if (this.state.showAnswer28 === 'none') {
			this.setState({ showAnswer28: 'block' });
		} else {
			this.setState({ showAnswer28: 'none' });
		}
	};
	divClicked29 = () => {
		if (this.state.showAnswer29 === 'none') {
			this.setState({ showAnswer29: 'block' });
		} else {
			this.setState({ showAnswer29: 'none' });
		}
	};
	divClicked30 = () => {
		if (this.state.showAnswer30 === 'none') {
			this.setState({ showAnswer30: 'block' });
		} else {
			this.setState({ showAnswer30: 'none' });
		}
	};
	divClicked31 = () => {
		if (this.state.showAnswer31 === 'none') {
			this.setState({ showAnswer31: 'block' });
		} else {
			this.setState({ showAnswer31: 'none' });
		}
	};
	divClicked32 = () => {
		if (this.state.showAnswer32 === 'none') {
			this.setState({ showAnswer32: 'block' });
		} else {
			this.setState({ showAnswer32: 'none' });
		}
	};
	divClicked33 = () => {
		if (this.state.showAnswer33 === 'none') {
			this.setState({ showAnswer33: 'block' });
		} else {
			this.setState({ showAnswer33: 'none' });
		}
	};

	render() {
		console.log(this.props);
		if (this.props.state.currentLanguage === 'english') {
			return (
				<div className="faq">
					<div className="faq__centerDiv">
						<h2 className="faq__centerDiv-header">FAQs</h2>
						<div className="faq__centerDiv__questDiv">
							<div onClick={this.divClicked1}>
								Q1.If I come here once, am I become that doctor’s patient?{' '}
							</div>
							<h4 style={{ display: this.state.showAnswer1 }}>
								<img src={Arrow} alt="arrow" />
								Sorry, No. frist, some of the doctors do not accept patients anymore. Secondly, the
								doctor that accepts patient has to accept you as their patient.
							</h4>
							<div onClick={this.divClicked2}>
								Q2. If I declear that Dr.x is my family doctor, will that become the truth?
							</div>
							<h4 style={{ display: this.state.showAnswer2 }}>
								<img src={Arrow} alt="arrow" />
								Sorry, No. first, the doctor needs to accept you. Second, the staff needs to be notified
								by the doctor that you are taken.
							</h4>
							<div onClick={this.divClicked3}>Q3.If I am doctor L’s patient, can I switch to Dr C?</div>
							<h4 style={{ display: this.state.showAnswer3 }}>
								<img src={Arrow} alt="arrow" />
								Sorry, No. our doctors will not take other doctor's patients in the clinic. Some of the
								patients were one of our male doctor's patients, and then later, one female doctor joins
								the clinic, and this patient wants to switch to the female doctor. Sorry, the clinic
								does not accept this behavior. Female doctors are willing to help you with any pap and
								female examination, but she is not your family doctor
							</h4>
							<div onClick={this.divClicked4}>Q4. If I am doctor L’s patient, can I see Dr C?</div>
							<h4 style={{ display: this.state.showAnswer4 }}>
								<img src={Arrow} alt="arrow" />
								Preferably you have to see your own doctor for any medical issue. If your family doctor
								is away, then you can see another doctor in the meantime, but one your doctor is back,
								he should still be the one taking you.
							</h4>
							<div onClick={this.divClicked5}>
								Q5.Can I register under one doctor, and I keep seeing another doctor{' '}
							</div>
							<h4 style={{ display: this.state.showAnswer5 }}>
								<img src={Arrow} alt="arrow" />
								Sorry, No. whoever you register and become your family doctor is responsible for your
								care. If you keep seeing another doctor that is not your family doctor, your family
								doctor has the right to terminate the patient, and you will have no family doctor.
							</h4>
							<div onClick={this.divClicked6}>Q6.Can I have 2-3 medical doctors at the same time?</div>
							<h4 style={{ display: this.state.showAnswer6 }}>
								<img src={Arrow} alt="arrow" />
								Sorry, no. You only entitled to have one family doctor, not 2-3 family doctor, at the
								same time because one doctor is responsible for your care. If you have many doctors,
								then your reports are everywhere. Also, the hospital doesn't know who your family doctor
								is, and they also get confused. So stay with one family
							</h4>
							<div onClick={this.divClicked7}>Q7.Do I need to book an appointment to see the doctor?</div>
							<h4 style={{ display: this.state.showAnswer7 }}>
								<img src={Arrow} alt="arrow" />
								Preferably, yes. You can book an appointment to see a doctor. Otherwise, you might need
								to wait until there is a gap we can accommodate you. Some problems do require an
								appointment such as pap tests or complete physicals. Same-day reservations are available
								24 hours 7 days a week online as well. Whoever has been here before and reservations can
								be made via our website.
							</h4>
							<div onClick={this.divClicked8}>
								Q8. Can I book a walk in reservation with a specific doctor?
							</div>
							<h4 style={{ display: this.state.showAnswer8 }}>
								<img src={Arrow} alt="arrow" />
								Walk-in reservations are not doctor specific, it is the first doctor available at the
								time of your booking. If you have been to our clinic before, you can book anytime with
								anyone
							</h4>
							<div onClick={this.divClicked9}>
								Q9. Does my child have to be with me to discuss their care?
							</div>
							<h4 style={{ display: this.state.showAnswer9 }}>
								<img src={Arrow} alt="arrow" />
								The child must present for the doctor to help him/her. the parents cannot come in place
								for their child’s visit
							</h4>
							<div onClick={this.divClicked10}>Q10. What services are not covered by MSP?</div>
							<div style={{ display: this.state.showAnswer10 }}>
								<img src={Arrow} alt="arrow" />
								<h4>The following services are not covered under MSP:</h4>
								<ul style={{ backgroundColor: 'white', margin: 0 }}>
									<li>Driver’s license form</li>
									<li>Sick Notes</li>
									<li>Travel advice and medicine</li>
									<li>School or work form</li>
									<li>Government form</li>
									<li>Msp phone number is: 604-683-7151</li>
								</ul>
								<h4>
									For more information, please click <Link to="/uninsuredservice">Here</Link>.
								</h4>
							</div>
							<div onClick={this.divClicked11}>Q11.How do you contact the clinic?</div>
							<h4 style={{ display: this.state.showAnswer11 }}>
								<img src={Arrow} alt="arrow" />
								If you have any questions, the website covers all questions and provides booking
								instruction videos. Please visit our website: www.jvonmedicals.ca . You can access any
								information you need by the website.
							</h4>
							<div onClick={this.divClicked12}>Q12.What if I do not have internet access?</div>
							<h4 style={{ display: this.state.showAnswer12 }}>
								<img src={Arrow} alt="arrow" />
								Usually, family members will help. If you live alone, cannot see or don't have the
								internet access, please inform one of our receptionists, and we can find an alternative
								to help you
							</h4>
							<div onClick={this.divClicked13}>Q13.What if I do not have valid medical coverage?</div>
							<h4 style={{ display: this.state.showAnswer13 }}>
								<img src={Arrow} alt="arrow" />
								If we are unable to validate your medical coverage or do not have medical coverage,
								there will be a charge for the visit.
							</h4>
							<div onClick={this.divClicked14}>
								Q14.can you help me to bill my visit if my msp expired
							</div>
							<h4 style={{ display: this.state.showAnswer14 }}>
								<img src={Arrow} alt="arrow" />Sorry No, it is not legal.
							</h4>
							<div onClick={this.divClicked15}>
								Q15.What if I live in another province/another country
							</div>
							<h4 style={{ display: this.state.showAnswer15 }}>
								<img src={Arrow} alt="arrow" />
								If you are from another province, you have to phone your previous province MSP service
								to confirm if your care card is still valid. If not, you have to pay as privately. We
								need to get their verbal verifications saying you have a valid MSP card. If you from
								another country. We have to charge you if you don't have MSP coverage. But, you might
								have private insurance. We will provide you a receipt for you to claim from your
								insurance. If you are from Quebec, your MSP card will not work in BC; therefore, your
								fee would not be covered. And you can only make an appointment during Monday-Friday 9
								am-2 pm.
							</h4>
							<div onClick={this.divClicked16}>Q16.Refund Policy</div>
							<h4 style={{ display: this.state.showAnswer16 }}>
								<img src={Arrow} alt="arrow" />
								J-Von Medical Center do not offer refunds once services have been
								provided. If your BC Care Card has recently become reinstated and would like
								reimbursement for a visit you paid privately, please contact our office, and the bank
								fee might need to be paid.
							</h4>
							<div onClick={this.divClicked17}>
								Q17.Why does the clinic sometimes stop accepting patients prior to the posted closing
								time?
							</div>
							<h4 style={{ display: this.state.showAnswer17 }}>
								<img src={Arrow} alt="arrow" />
								Due to more patient demand for family doctors, the doctors in our clinic may reach this
								MSP “cap” before the clinic is scheduled to close. We apologize for any inconvenience
								that this may cause, please visit our website if the doctors are no longer accepting new
								patients, will post on our website.
							</h4>
							<div onClick={this.divClicked18}>
								Q18. Can I get Hep A and B shots for travel at the clinic?
							</div>
							<h4 style={{ display: this.state.showAnswer18 }}>
								<img src={Arrow} alt="arrow" />
								You can get shots from our clinic, but MSP does not cover the travel advice and
								medication. You have to pay privately for the cost.
							</h4>
							<div onClick={this.divClicked19}>Q19. Will you call me with my results?</div>
							<h4 style={{ display: this.state.showAnswer19 }}>
								<img src={Arrow} alt="arrow" />
								If your results are abnormal, we will make every effort to contact you. However, you are
								encouraged to follow up with the doctor is symptoms persist and worsen. Patients are
								responsible for following up with the doctor for all test results.
							</h4>
							<div onClick={this.divClicked20}>
								Q20. can my baby get routine immunicaiton in the clinic
							</div>
							<h4 style={{ display: this.state.showAnswer20 }}>
								<img src={Arrow} alt="arrow" />
								Sorry, we don't do baby vaccine in our clinic, but you can call public health for this
								phone number (604) 918-7605
							</h4>
							<div onClick={this.divClicked21}>Q21. Can I get HPV shot in the clinic.</div>
							<h4 style={{ display: this.state.showAnswer21 }}>
								<img src={Arrow} alt="arrow" />
								Yes, doctors will prescribe you the medication. Then you buy from the pharmacy. You will
								have to write down the dates.
							</h4>

							<div onClick={this.divClicked23}>Q22.Can I email you for any questions?</div>
							<h4 style={{ display: this.state.showAnswer23 }}>
								<img src={Arrow} alt="arrow" />
								Sorry, we donot respond you with email. You must call us.1 privacy issue to protect your
								own personal information 2. The front doesnot check emails
							</h4>
							<div onClick={this.divClicked24}>Q23. How do I apply for BC msp card</div>
							<h4 style={{ display: this.state.showAnswer24 }}>
								<img src={Arrow} alt="arrow" />
								<a
									href="https://www2.gov.bc.ca/gov/content/health/health-drug-coverage/msp"
									target="_blank"
									rel="noopener noreferrer"
								>
									Medical Service Plan Website
								</a>
								<section>MSP phone number: 604-683-7151</section>
							</h4>
							<div onClick={this.divClicked25}>Q24. Can I get report without seeing doctor</div>
							<h4 style={{ display: this.state.showAnswer25 }}>
								<img src={Arrow} alt="arrow" />
								Sorry, no your report must reviewed the doctor then we can provide you a copy. Copy is
								1$ per charge. If it is E-transfer we need to charge additional bank fee as well.
							</h4>
							<div onClick={this.divClicked26}>Q25.Can I get report over the phone?</div>
							<h4 style={{ display: this.state.showAnswer26 }}>
								<img src={Arrow} alt="arrow" />
								Sorry, we don't discuss your report over the phone from receptionist.
							</h4>
							<div onClick={this.divClicked27}>
								Q26. Can you send my referral to two speciliast and compare who is fast
							</div>
							<h4 style={{ display: this.state.showAnswer27 }}>
								<img src={Arrow} alt="arrow" />
								Sorry, we are not allowed to that. If you want to change, we can help you to change but
								cannot send two referrals and compare with speed.
							</h4>
							<div onClick={this.divClicked28}>
								Q27. Can you help me to call specialist/ hospital appointment to change to another date?
							</div>
							<h4 style={{ display: this.state.showAnswer28 }}>
								<img src={Arrow} alt="arrow" />
								Sorry, our staff is not responsible for changing or rescheduling your appointment.
								Please call specialist office directly to find the best time suitable for your schedule?
							</h4>
							<div onClick={this.divClicked29}>
								Q28. Can you do translation for me if I donot speak English? Can you help me to call any
								place because I donot speak enligh?
							</div>
							<h4 style={{ display: this.state.showAnswer29 }}>
								<img src={Arrow} alt="arrow" />
								Sorry our clinic does not have any translation service. We are a medical office like
								many other medical offices who speak English. Please find relatives or family member to
								help you.
							</h4>
							<div onClick={this.divClicked30}>
								Q29. Where can I find a lab or radiology place in Burnaby?
							</div>
							<h4 style={{ display: this.state.showAnswer30 }}>
								<img src={Arrow} alt="arrow" />
								Burnaby lifelab near us Address: 4250 Kingsway Suite 210, Burnaby, BC V5H 4T7 Phone:
								(604) 431-7206 Radiology near us Brooke Radiology X-ray lab 4980 Kingsway Phone: (604)
								434-1345
							</h4>
							<div onClick={this.divClicked31}>Q30.Virtual visit how can I get my prescription?</div>
							<h4 style={{ display: this.state.showAnswer31 }}>
								<img src={Arrow} alt="arrow" />
								A. you need to give us a fax number B you need to give us 24 hours timing to finish
								sending to them C.you can contact your pharmacy after one day then you can phone them to
								ask if it is ready sometimes pharmacist is busy as well so double check with them.
							</h4>
							<div onClick={this.divClicked32}>Q31.how do I see doctor virtually?</div>
							<h4 style={{ display: this.state.showAnswer32 }}>
								<img src={Arrow} alt="arrow" />
								Please go to our website:
								<a href="www.jvonmedicals.ca" target="_blank" rel="noopener noreferrer">
									www.jvonmedicals.ca
								</a>,Select booking instruction, it will show you how can you contact with you doctors
								Or click <Link to="/onlinebookinginstruction">Here</Link>
							</h4>
							<div onClick={this.divClicked33}>
								Q32. how do I book online for virtual or telephone medical visit.
							</div>
							<h4 style={{ display: this.state.showAnswer33 }}>
								<img src={Arrow} alt="arrow" />
								Select booking instruction, it will show you how can you contact with you doctors Or
								click <Link to="/onlinebookinginstruction">Here</Link>
							</h4>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div className="faq">
					<div className="faq__centerDiv">
						<h2 className="faq__centerDiv-header">FAQ常问问题</h2>
						<div className="faq__centerDiv__questDiv">
							<div onClick={this.divClicked1}>Q1.我来过只看过一次病，是不是我就是这个医生的家庭病人了？</div>
							<h4 style={{ display: this.state.showAnswer1 }}>
								<img src={Arrow} alt="arrow" />
								很抱歉的通知您，不是这样子的。第一，这个医生可能没有在收新病人。第二，医生如果在收 必须得到医生的首肯，她/他肯接受你才算是成为他的病人。
							</h4>
							<div onClick={this.divClicked2}>Q2. 如果我觉得这个家庭医生是我的家庭医生 是不是我就能够成为他的病人了呢？</div>
							<h4 style={{ display: this.state.showAnswer2 }}>
								<img src={Arrow} alt="arrow" />
								很抱歉的通知您，不是这样子的。第一他/她必须同意接收您 第二，医生需要通知前台您被接收了才能成为他/她的病人。
							</h4>
							<div onClick={this.divClicked3}>Q3.举例来说如果我是L医生的病人，我可以换成C医生的病人吗？</div>
							<h4 style={{ display: this.state.showAnswer3 }}>
								<img src={Arrow} alt="arrow" />
								很抱歉的通知您，我们医生不会接受另外一个医生的病人。有一些病人是之前注册过在一个男医生的名下，之后他们发现我们有新的女医生来了，就不再看男医生，以为就可以换成女医生。这么做是不可以的。
							</h4>
							<div onClick={this.divClicked4}>Q4. 如果我是L姓的病人，我可以看C姓的医生吗</div>
							<h4 style={{ display: this.state.showAnswer4 }}>
								<img src={Arrow} alt="arrow" />
								您尽量看自己的家庭医生，如果您的家庭放假去了你可以选择其他人来看，其他还是一直保持看自己的医生，因为您的家庭医生对您的情况会比较了解。
							</h4>
							<div onClick={this.divClicked5}>Q5.我可以注册在一个医生名下，然后不看这个医生，一直看另外一个医生吗？</div>
							<h4 style={{ display: this.state.showAnswer5 }}>
								<img src={Arrow} alt="arrow" />
								不可以这样的，因为如果你注册了一个家庭医生，他是需要对你的健康跟踪的。 你看了另外一个医生，而另外一个医生并没有接受你做他新病人，所以还是需要看注册的家庭医生。
							</h4>
							<div onClick={this.divClicked6}>Q6.我可以一次拥有2-3个家庭医生吗？</div>
							<h4 style={{ display: this.state.showAnswer6 }}>
								<img src={Arrow} alt="arrow" />
								很抱歉的通知您，您只能有一位家庭医生。2-3 家庭医生是不对的，您的报告会到处都有，不在一个诊所，不方便医生诊断您的病情。而且你去医院的 时候，医院也会很迷惑。
								为何你的家庭医生好几个，到底哪一个是？ 他们也不知道如何联系到哪一位是你的家庭医生
							</h4>
							<div onClick={this.divClicked7}>Q7.我需要预约来看病吗？</div>
							<h4 style={{ display: this.state.showAnswer7 }}>
								<img src={Arrow} alt="arrow" />
								是的，如果你想来看病，可以有三种选择 1. 网络看病，2.医生可以给你打电话看病
								3.进来看病。由于疫情原因，保护病人安全，所有的新冠病情和感冒症状请去选择网络看病。其他的可以打电话给我们预约进来看病。
							</h4>
							<div onClick={this.divClicked8}>Q8. 我可以预约一个指定的医生临时看病吗 </div>
							<h4 style={{ display: this.state.showAnswer8 }}>
								<img src={Arrow} alt="arrow" />
								临时看病时不需要制定一个医生的，看当天谁有位置，如果你想要看的医生也有位置，就可以帮忙预约。
							</h4>
							<div onClick={this.divClicked9}>Q9. 我孩子需要看病，但是他/她在学校，我可以替她/他来吗。</div>
							<h4 style={{ display: this.state.showAnswer9 }}>
								<img src={Arrow} alt="arrow" />您的孩子必须来看医生，您不可以你取代他们来看病
							</h4>
							<div onClick={this.divClicked10}>Q10. 哪一些项目是医疗卡不包括的</div>
							<div style={{ display: this.state.showAnswer10 }}>
								<img src={Arrow} alt="arrow" />
								<ul>
									<li>驾照填写</li>
									<li>病假条</li>
									<li>旅行建议</li>
									<li>工作或者申请学校的表格</li>
									<li>政府的表格</li>
									<li>医疗保险电话： 604-683-7151</li>
								</ul>
								<Link to="/uninsuredservice">了解更多</Link>
							</div>
							<div onClick={this.divClicked11}>Q11. 我们通常怎么和诊所联系？</div>
							<h4 style={{ display: this.state.showAnswer11 }}>
								<img src={Arrow} alt="arrow" />
								我们的网站上面会提供你需要了解的信息，请去我们的网站www.jvonmedicals.ca 查询你需要的信息
							</h4>
							<div onClick={this.divClicked12}>Q12.如果我年纪很大没有网络怎么办？</div>
							<h4 style={{ display: this.state.showAnswer12 }}>
								<img src={Arrow} alt="arrow" />
								通常长者都是由家里的亲属帮忙，如果您是自己居住，并且没有其他家人可以帮忙，请告知我们前台如何帮助你
							</h4>
							<div onClick={this.divClicked13}>Q13.如果我的医疗卡实效了怎么办？</div>
							<h4 style={{ display: this.state.showAnswer13 }}>
								<img src={Arrow} alt="arrow" />如果您没有医疗保险，您需要自费来看诊
							</h4>
							<div onClick={this.divClicked14}>Q14.我当时医疗卡失效了，你们诊所可不可以等我开通了 那一天，给我算成那一天看病的？</div>
							<h4 style={{ display: this.state.showAnswer14 }}>
								<img src={Arrow} alt="arrow" />不可以，这是不合法的。不可以之前看病的日期改成以后的。.
							</h4>
							<div onClick={this.divClicked15}>Q15.如果我是另外一个省份的，或者是另外一个国家的 我怎么看病，如何收取费用</div>
							<h4 style={{ display: this.state.showAnswer15 }}>
								<img src={Arrow} alt="arrow" />
								如果您是从另外一个省份过来的，您首先得给您那个省份打一个电话，问一下您那个省份的医疗机构您的卡是否还有效，要不然您就白跑来一趟。如果有，请到我们诊所打电话我们需要核实。如果可以就可以看病。但是必须是周一到周五的9-2pm预约
								<br />
								魁北克省分不包含在内。
								<br />如果您是从其他国家过来，您是需要自费的。 我们可以给你提供收据，然后是从你们国家申请拿回费用，还是从你们买的保险当中申请费用。
							</h4>
							<div onClick={this.divClicked16}>Q16.退款政策</div>
							<h4 style={{ display: this.state.showAnswer16 }}>
								<img src={Arrow} alt="arrow" />
								J-Von Medical Center
								不会看完病提供退款服务。但是如果您的医疗卡失效之后又有效了，我们可以试着帮你去申请之前的看病费用，如果通过，可以返还费用 但是银行部分的费用需要收取。
							</h4>
							<div onClick={this.divClicked17}>Q17.为何有时候诊所不再收新病人了，而我们还希望成为新病人？ </div>
							<h4 style={{ display: this.state.showAnswer17 }}>
								<img src={Arrow} alt="arrow" />
								家庭医生非常缺乏，家庭医生很容易就被约满了。我们很抱歉无法满足每一个人都能够被接收。请经常浏览我们的网站，有新的消息会更新发布。
							</h4>
							<div onClick={this.divClicked18}>Q18.我可以在你们诊所打甲肝乙肝疫苗吗？</div>
							<h4 style={{ display: this.state.showAnswer18 }}>
								<img src={Arrow} alt="arrow" />可以来我们诊所打这些疫苗，但是这些项目不属于免费医疗范畴，需要自费。
							</h4>
							<div onClick={this.divClicked19}>Q19. 我的报告结果，你们诊所会给我打电话通知吗</div>
							<h4 style={{ display: this.state.showAnswer19 }}>
								<img src={Arrow} alt="arrow" />
								如果您的检查结果有问题，我们会尽力联系您并且通知你回来复诊。但是，无论您的报告有问题还是没问题，我们建议你们还是有职责自己跟踪报告，比如验血最晚一个礼拜到结果，你们可以约一个礼拜之后就回来复诊，无论报告结果如何，都可以和医生商量一下需不要注意一些什么？或者作其他的检查
							</h4>
							<div onClick={this.divClicked20}>Q20. 我的宝宝可以在诊所里面打常规的疫苗吗</div>
							<h4 style={{ display: this.state.showAnswer20 }}>
								<img src={Arrow} alt="arrow" />
								抱歉，我们诊所不提供儿童大疫苗，但是政府有专门给儿童大疫苗的地方 请拨打这个电话预约。 Burnaby public health unit (604) 918-7605
							</h4>
							<div onClick={this.divClicked21}>Q21. 你们诊所能够开HPV针吗?</div>
							<h4 style={{ display: this.state.showAnswer21 }}>
								<img src={Arrow} alt="arrow" />
								可以的我们诊所的医生可以给您开9价的hpv针。如果您是这边长大的，通常在学校就会统一安排注射。如果是留学生，需要来我们诊所开药单，在我们诊所打都可以。
							</h4>

							<div onClick={this.divClicked23}>Q22.我可以email你们任何问题吗？</div>
							<h4 style={{ display: this.state.showAnswer23 }}>
								<img src={Arrow} alt="arrow" />
								很抱歉，我们不会回复邮件，你可以给我们打电话。原因第一是邮件的保密性，第二前台工作很忙碌，没有时间时刻回复邮件。
							</h4>
							<div onClick={this.divClicked24}>Q23. 我怎么申请msp保险</div>
							<h4 style={{ display: this.state.showAnswer24 }}>
								<img src={Arrow} alt="arrow" />
								<a
									href="https://www2.gov.bc.ca/gov/content/health/health-drug-coverage/msp"
									target="_blank"
									rel="noopener noreferrer"
								>
									MSP官网
								</a>
								<section> 医疗保险电话： 604-683-7151</section>
							</h4>
							<div onClick={this.divClicked25}>Q24. 我可以通过邮件拿一份我的报告吗</div>
							<h4 style={{ display: this.state.showAnswer25 }}>
								<img src={Arrow} alt="arrow" />
								非常抱歉，我们不通过邮件传送任何的报告。如果您看完病，可以去前台要一份报告，但是一定要医生看完之后才能给你，因为医生需要诊断需不需要做其他检查，打印费用是一元钱一张。你也可以去lab
								申请一个帐户，就不用我们给你打印了。每个复印件会收取$1的费用
							</h4>
							<div onClick={this.divClicked26}>Q25.我可不可以通过电话得到我的报告结果</div>
							<h4 style={{ display: this.state.showAnswer26 }}>
								<img src={Arrow} alt="arrow" />
								我们前台的工作人员不允许提供任何的报告结果，因为他们不是医生，必须由医生看过，和你解释关于您的报告，所有无法通过前台得到报告。{' '}
							</h4>
							<div onClick={this.divClicked27}>
								Q26. 如果需要转诊转给专科医生，你们诊所可以发送我的推荐信给同一个项目的专科，但是同时发给两个专科 我比较哪一个比较快吗
							</div>
							<h4 style={{ display: this.state.showAnswer27 }}>
								<img src={Arrow} alt="arrow" />很抱歉，我们不允许这么做，我们只能发给一个专科医生。
							</h4>
							<div onClick={this.divClicked28}>Q27. 可不可以让前台给我改专科和医院的预约时间或者取消？ </div>
							<h4 style={{ display: this.state.showAnswer28 }}>
								<img src={Arrow} alt="arrow" />
								很抱歉，任何时间上面改动都需要病人直接打电话，去和他们更改，第一可以找一个适合你们的时间。其二我们的工作繁忙，无法负荷帮助每一个人更改时间。
							</h4>
							<div onClick={this.divClicked29}>
								Q28. 我不会说英文，你们可不可以给我们看诊做翻译。去给我需要的地方联系帮我翻译，例如给我打电话去和其他我需要解决的地方提供翻译？
							</div>
							<h4 style={{ display: this.state.showAnswer29 }}>
								<img src={Arrow} alt="arrow" />
								很抱歉我们诊所和其他诊所没有区别，我们诊所里面有中文这个便捷服务，并不代表我们所有的都需要提供翻译给大家。我们诊所和西人的诊所是做同样的工作的，其实工作本来就任务量很大，这些翻译工作我们负荷不了。请你们不会说的可以通过家人或者朋友，还有就是中侨Success
								机构可以辅助与你们。 你们可以问一下中侨能否帮助你们预约，取消时间和说英文医疗机构联系。
							</h4>
							<div onClick={this.divClicked30}>Q29. 我们去哪里验血，去哪里做X光和照片子的地方？</div>
							<h4 style={{ display: this.state.showAnswer30 }}>
								<img src={Arrow} alt="arrow" />
								<section>
									Burnaby 验血请去这里 Address: 4250 Kingsway Suite 210, Burnaby, BC V5H 4T7 Phone: (604)
									431-7206
								</section>
								<section>
									作X 光片请去这里 Brooke Radiology X-ray lab 4980 Kingsway Phone: (604) 434-1345
								</section>
								其他城市的居民，你们可以搜索你们城市的 验血 lifelab X 光片 radiology 就可以找到
							</h4>
							<div onClick={this.divClicked31}>Q30.我如何通过网络视频拿到处方药</div>
							<h4 style={{ display: this.state.showAnswer31 }}>
								<img src={Arrow} alt="arrow" />
								第一您需要知道自己去哪一个药方，把药房的传真给我们 第二你需要给与我们48小时的准备和处理时间，第三，这些都完成之后您需要和药局联系去拿处方药
								请先致电给他们因为他们有时候也会很忙碌 会受到很多的传真，请给他们一些时间
							</h4>
							<div onClick={this.divClicked32}>Q31.我如何在网络远程看诊?</div>
							<h4 style={{ display: this.state.showAnswer32 }}>
								<img src={Arrow} alt="arrow" />
								请去我们的网站:
								<a href="www.jvonmedicals.ca" target="_blank" rel="noopener noreferrer">
									www.jvonmedicals.ca
								</a>,选择看诊步骤 或者点击这个连接
							</h4>
							<div onClick={this.divClicked33}>Q32. 我如何在网上预约</div>
							<h4 style={{ display: this.state.showAnswer33 }}>
								<img src={Arrow} alt="arrow" />
								请去我们的网站:
								<a href="www.jvonmedicals.ca" target="_blank" rel="noopener noreferrer">
									www.jvonmedicals.ca
								</a>,选择看诊步骤 或者点击这个连接
							</h4>
						</div>
					</div>
				</div>
				// </div>
			);
		}
	}
}
