import React, { Component } from 'react';
import FirstSection from './MainBodyFirst';
import SecondSection from './MainBodySecond';
import FirstSectionChinese from './MainBodyFirstChinese';
import SecondSectionChinese from './MainBodySecondChinese';

const properties = {
	duration: 9000,
	transitionDuration: 1500,
	infinite: true,
	indicators: true,
	arrows: true,
	pauseOnHover: false
	// onChange: (oldIndex, newIndex) => {
	// 	console.log(`slide transition from ${oldIndex} to ${newIndex}`);
	// }
};

export default class MainBody extends Component {
	constructor(props) {
		super(props);
		this.state = {
			wholeHover: 'flex',
			// firstPop: 'none',
			secondPop: 'flex',
			letterEN: 'inline',
			letterZH: 'none'
		};
	}

	iAgree = () => {
		this.setState({
			firstPop: 'none',
			secondPop: 'flex',
			letterEN: 'inline',
		});
	};

	iClose = () => {
		this.setState({
			wholeHover: 'none'
		});
	};

	changLang = () => {
		if (this.state.letterEN === 'inline') {
			this.setState({
				letterEN: 'none',
				letterZH: 'inline'
			});
		} else {
			this.setState({
				letterEN: 'inline',
				letterZH: 'none'
			});
		}
	}

	//schedule visual update, news qr code,  .
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		let restElements;
		if (this.props.state.currentLanguage === 'english') {
			restElements = [<FirstSection props={this.props.state} />,
			<SecondSection props={this.props.state} />]
		} else {
			restElements = [<FirstSection props={this.props.state} />,
			<SecondSection props={this.props.state} />]
		}
		let agreementDiv;
		agreementDiv =
			<div className="agreementDiv" style={{ display: this.state.wholeHover }}>
				<div className="letterDiv__textDiv" style={{ display: this.state.secondPop }}>
					<div style={{ display: this.state.letterEN }}>
						<p>Dear patients, <span class="changeLang" onClick={this.changLang}>中文</span></p>
						<p>As you may be aware, since February 2023, the Ministry of Health has launched a program to rebuild primary health-care services for patients. All of our doctors have enrolled in this program. However, due to the high volume of patients that each doctor is currently attending to, they have reached their capacity and are unable to accept any additional patients. Consequently, <span class="warning">the clinic will no longer be able to provide services to unattached (walk-in) patients.</span></p>
						<p>For clarification, <span class="danger">unattached patients</span> are individuals who have registered with our clinic, but at the time of registration, our doctors had already reached full capacity or were not accepting new patients. This also includes individuals who visited our office for temporary medical attention on few occasions. <span class="warning">We use the term "unattached patients" to specifically refer to these individuals.</span></p>
						<p>If you are one of these unattached patients and have any questions or concerns regarding your status, we encourage you to reach out to the doctors for confirmation. However, please be aware that there is a strong possibility that the doctors did not accept you as their patient previously due to their capacity limitations. Additionally, due to their current workload, they are unable to take on more patients.</p>
						<p><span class="danger">This change applies to both our existing unattached patients and new walk-in patients.</span> <span class="warning">Effective December 31st, 2023, our internal walk-in medical services will be permanently terminated.</span> </p>
						<p>We want to provide a buffer period to assist patients. During the next five months, until December 31st, 2023, we will continue to provide assistance to the best of our ability. However, after this date, J-Von Medical will no longer offer any internal or external walk-in services. <span class="warning">Your files will be permanently closed at our clinic.</span> If you require your chart notes to be sent to a new clinic, you can request for them to be transferred. However, please be aware that charges may apply due to the mailing and administration work involved. It is important to note that sending chart notes is not mandatory, and the decision is entirely up to you.</p>
						<p>It's important to note that our clinic is not the only one making these changes. Another clinic in our neighborhood will also no longer accept walk-in patients</p>
						<p>If you require immediate medical attention, we recommend searching for <span class="warning">URGENT CARE</span> centers on Google near your home. For those in need of a new family doctor, we suggest using the services of <span class="warning">FIND DOCTORS BC</span>. Many patients have successfully found doctors through Find Doctors BC.  It's important to note that there are other walk-in clinics available on Google that may be able to accommodate your needs.</p>
						<p>We are aware that our website and phone system will need to be updated, and we will update them after these changes have been applied.</p>
						<p>We appreciate your understanding and cooperation during this transition period.</p>
						<p>Best regards</p>
						<p>All the doctors at J-Von Clinic</p>
						<p>July /2023</p>
						<div class="agreementDiv__buttonDiv">
							<button onClick={this.iClose}>Okay</button>
						</div>
					</div>
					<div style={{ display: this.state.letterZH }}>
						<p>亲爱的患者们，<span class="changeLang" onClick={this.changLang}>English</span></p>
						<p>正如您所知，自从2023年2月起，卫生部推出了一个重建患者基础医疗服务 也就是说卫生部希望更多家庭医生参与并且医生专注看自己的病人。我们所有的医生都已经加入了政府这项措施。然而，由于我们诊所的每位医生目前接待的患者数量过多，他们已经达到了承载能力的上限，无法再接待除了自己病人额外的患者。 <span class="warning">因此，诊所将不再为“”无指派医生（即时就诊）的患者提供服务。</span></p>
						<p><span class="danger">"无指派医生"的患者</span>是指在注册时已经登记在我们诊所，但在那个时候医生已经达到了承载能力或不接收新患者。这也包括那些曾在我们诊所进行临时就医的群体。我们使用“无指派医生的患者”这个术语特指这些个体。</p>
						<p>如果您是其中的“”无指派医生”患者，并对自己的状态有任何问题或疑虑，我们鼓励您与医生联系以进行确认。然而，请注意，医生以前很可能因为承载能力限制而没有接收您作为他们的患者，但是您可以去和医生确认，如果接收, 医生会有病例记录, 反之就是那时候医生已满 已无法再接收。此外，由于医生目前的工作负荷，他们无法接收更多患者。</p>
						<p><span class="danger">这项变更适用于我们现有的无指派医生患者和新的即时就诊患者。自2023年12月31日起，我们的即时就诊医疗服务将永久终止。</span></p>
						<p>我们希望为患者提供一个过渡期来提供帮助。在接下来的五个月内，直到2023年12月31日，我们将尽力提供援助。然而，在此日期之后，J-Von医疗将不再提供任何即时就诊服务。<span class="warning">您的档案将在我们的诊所永久关闭</span>。如果您需要将病历记录转至新的诊所，您可以申请进行转移。然而，请注意，由于邮寄和行政工作的参与，可能会产生费用。发送病历记录并非强制性，这完全取决于您自己的决定。</p>
						<p>需要注意的是，我们的诊所并不是唯一一个进行这些变更的机构。我们附近的几家诊所也将不再接收即时就诊的患者。</p>
						<p>如果您需要紧急就医，请在Google上搜索紧急护理中<span class="warning">Urgent Care</span>照样可以看病 并且在Metrotown 附近也有一家 请搜索Urgent Care 这几个字 就可以找到马上看诊的地方。对于需要寻找新家庭医生的人，我们建议使用<span class="warning">FIND DOCTORS BC.</span> 许多患者已经通过Find Doctors BC成功找到了医生。需要注意的是，Google上还有其他临时看病诊所可以满足您的需求。</p>
						<p>我们诊所的网站和电话系统将在此项目完成后进行更新。</p>
						<p>我们非常感谢您在这个过渡期间的理解和合作。</p>
						<p>最诚挚的问候，</p>
						<p>J-Von诊所的所有医生</p>
						<p>07/2023</p>
						<div class="agreementDiv__buttonDiv">
							<button onClick={this.iClose}>好的</button>
						</div>
					</div>
				</div>
			</div>
		if (this.props.state.currentLanguage === 'english') {
			return (
				<div className="something">
					{agreementDiv}
					<div className="emptyHeaderDiv" />
					<div className="slideShow__slides">
						<div className="slideShow__slides-4">
							<div className='banner-annoucement' />
						</div>
					</div>
					{restElements}
				</div>
			);
		} else {
			return (
				<div className="something">
					{agreementDiv}
					<div className="emptyHeaderDiv" />
					<div className="slideShow__slides">
						<div className="slideShow__slides-4">
							<div className='banner-annoucement' />
						</div>
					</div>
					<FirstSectionChinese props={this.props.state} />
					<SecondSectionChinese props={this.props.state} />
				</div>
			);
		}
	}
}
