import React, { Component } from 'react';
import logo from '../assets/NewsBanner/jvonLogo.png';
import LocationIcon from '../assets/icons/icon-ping-new.svg';
import Female1 from '../assets/docImage/female1.jpeg';
import Male1 from '../assets/docImage/rsz_male_doctor.jpg';
import LocationScreenShot from '../assets/locationScreenShot.png';
export default class MainBodySecond extends Component {
	render() {
		return (
			<div>
				<div className="clinicLocation">
					<div className="clinicLocation__firstDiv">
						<div className='contactPage__location1-img-container'>
							<h2 className="clinicLocation-header">Clinic Location</h2>
							<img src={LocationScreenShot} alt="location-img" className="contactPage__location1-img" />
						</div>

						<div className="clinicLocation__firstDiv__location">
							<div className='clinicLocation-inner-div'>
								<p className='clinicLocation__firstDiv__location-header'>Address</p>
								<p>J-Von Medical Center</p>
								<a href='https://www.google.com/maps/place/4468+Beresford+St.,+Burnaby,+BC+V5H+2Y8/@49.2252215,-123.0038555,17z/data=!3m1!4b1!4m6!3m5!1s0x5486765e9b5df06d:0xa2651bd0273119b8!8m2!3d49.2252215!4d-123.0038555!16s%2Fg%2F11c257byjl?entry=ttu' target="_blank"
									rel="noopener noreferrer">
									4468 Beresford St, Burnaby, V5H0B8</a>
							</div>

							<div className='clinicLocation-inner-div'>
								<p className='clinicLocation__firstDiv__location-header'>Hours</p>
								<p>Monday - Saturday</p>
								<p>09: 00 - 16: 00</p>
								<p>Sunday closed</p>
							</div>

							<div className='clinicLocation-inner-div'>
								<p className='clinicLocation__firstDiv__location-header'>Contact</p>
								<a href="tel:+17783798299">Tel: (778)-379-8299</a>
								<p>Fax: (778)-379-8399</p>
							</div>
						</div>
					</div>
				</div>

				<div className="clinicLocation__firstDiv__doctorDiv">
					<section className="clinicLocation__firstDiv__doctorDiv-section">
						<img src={Female1} alt="female" />
						<h4>Dr.Zhai</h4>
					</section>
					<section className="clinicLocation__firstDiv__doctorDiv-section">
						<img src={Male1} alt="male" />
						<h4>Dr.Lau</h4>
					</section>
					<section className="clinicLocation__firstDiv__doctorDiv-section">
						<img src={Male1} alt="male" />
						<h4>Dr.Zong</h4>
					</section>
					<section className="clinicLocation__firstDiv__doctorDiv-section">
						<img src={Male1} alt="male" />
						<h4>Dr.Gill</h4>
					</section>
					<section className="clinicLocation__firstDiv__doctorDiv-section">
						<img src={Female1} alt="female" />
						<h4>Dr.Chen</h4>
					</section>
					<section className="clinicLocation__firstDiv__doctorDiv-section">
						<img src={Female1} alt="female" />
						<h4>Dr.Liu</h4>
					</section>
					<section className="clinicLocation__firstDiv__doctorDiv-section">
						<img src={Male1} alt="male" />
						<h4>Dr.Wong</h4>
					</section>
					<section className="clinicLocation__firstDiv__doctorDiv-section">
						<img src={Male1} alt="male" />
						<h4>Dr.Wu</h4>
					</section>
				</div>

				<div className="footer-container">
					<div className="footer-inner-container">
						<div className="footer-left">
							<img src={logo} alt="logo" className='jvonLogo' />
							<div className="">
								<h5>J-Von Medical Center</h5>
							</div>
						</div>

						<div className='footer-right'>
							<img src={LocationIcon} alt="localtion icon" className='locIcon' />
							<div>
								<p>J-Von Medical Centre</p>
								<a href='https://www.google.com/maps/place/4468+Beresford+St.,+Burnaby,+BC+V5H+2Y8/@49.2252215,-123.0038555,17z/data=!3m1!4b1!4m6!3m5!1s0x5486765e9b5df06d:0xa2651bd0273119b8!8m2!3d49.2252215!4d-123.0038555!16s%2Fg%2F11c257byjl?entry=ttu' target="_blank"
									rel="noopener noreferrer">
									4468 Beresford St, Burnaby, V5H0B8</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
