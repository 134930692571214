import React, { Component } from 'react';

export default class MainBodyFirst extends Component {
	render() {
		return (
			<div className="firstSection">
				<section className='news-section'>
					<p>由于每位医生目前正在接诊的患者数量过多，他们已达到了承载能力的上限，并且无法接受任何额外的患者。因此，诊所将不再能够为未挂号（即时就诊）的患者提供服务。</p>
				</section>

				<section className='testimonials-section'>
					<div className='testimonials-section-inner-wrapper'>
						<div className='slide-button-container'>
							<a className='slide-booking-button' href='https://jvonebooking.mpeer.net:8881/appointment/' target="_blank"
								rel="noopener noreferrer">预定远程看病</a>
							<a className='slide-booking-button' href='https://jvonebooking.mpeer.net:8881/appointment/' target="_blank"
								rel="noopener noreferrer">预定家庭医生</a>
						</div>
					</div>
				</section>
			</div>
		);
	}
}
