import React, { Component } from 'react';

import Poster from '../../assets/downloadFile/poster1-1.png';

export default class AcceptingNewPatient extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		if (this.props.state.currentLanguage === 'english') {
			return (
				<div
					style={{
						paddingTop: 140,
						width: '100vw',
						display: 'flex',
						alignItems: 'center',
						flexDirection: 'column'
					}}
				>
					<h2 className="boldFont" style={{ marginBottom: '30px' }}>
						Accepting News Patients
					</h2>
					<img style={{ width: '80vw' }} src={Poster} alt="poster" />
				</div>
			);
		} else {
			return (
				<div
					style={{
						paddingTop: 140,
						width: '100vw',
						display: 'flex',
						alignItems: 'center',
						flexDirection: 'column'
					}}
				>
					<h2 className="boldFont" style={{ marginBottom: '30px' }}>
						接受新病人
					</h2>
					<img style={{ width: '80vw' }} src={Poster} alt="poster" />
				</div>
			);
		}
	}
}
