import React, { Component } from 'react';

//component
import { Form, Button, Row, Col, Spinner } from 'react-bootstrap';
import Modalup from './Modalup';

//style
import 'bootstrap/dist/css/bootstrap.min.css';

//api
import { smsSignup, queryDoctor } from '../../api/sms';

export default class PatientSignup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isModalup: false,
			isLoading: false,
			title: '',
			body: '',
			button: '',
			doctors: []
		};

		this.signup = this.signup.bind(this);
		this.queryWokringDoctor = this.queryWokringDoctor.bind(this);
	}

	async componentDidMount() {
		this.setState({ ...this.state, isLoading: true });
    const res = await this.queryWokringDoctor();
    if(res.data){
      const doctors = res.data;
      document.body.style.backgroundColor = '#0abab5';
      return await this.setState({ ...this.state, doctors, isLoading: false });
    } else {
      return await this.setState({ ...this.state, isLoading: false });
    }
  }

	async signup(event) {
		event.preventDefault();
		this.setState({ ...this.state, isLoading: true });
		const { lastName, firstName, phone, doctor } = event.target.elements;
		const doctors = doctor.options;
		const phoneNumber = phone.value;
		const name = lastName.value.concat(' ' + firstName.value);
		const selectedDoctor = doctors[doctors.selectedIndex].value;
		const selectedDoctorObject = this.state.doctors.filter((doctor) => doctor.name === selectedDoctor);
		const res = await smsSignup(selectedDoctorObject[0], name, phoneNumber);
		if (this.props.state.currentLanguage === 'english') {
			if (res.status === 200) {
				if (!res.data.status) {
          if (res.data.failed){
            return this.setState({
              ...this.state,
              isModalup: true,
              title: 'Notification',
              body: `You already signed up the Queue, please don't sign up multiple times`,
              button: 'ok',
              isLoading: false
            });
          } else {
            return this.setState({
              ...this.state,
              isModalup: true,
              title: 'Notification',
              body: 'You successfully signed up the Queue, please wait for your text message',
              button: 'ok',
              isLoading: false
            });
          }
				} else {
					return this.setState({
						...this.state,
						isModalup: true,
						title: 'Notification',
						body: 'Your Phone Number is not valid, please type in a valid Canadian phone Number ',
						button: 'ok',
						isLoading: false
					});
				}
			} else {
				return this.setState({
					...this.state,
					isModalup: true,
					title: 'Notification',
					body: 'The registration is failed ',
					button: 'ok',
					isLoading: false
				});
			}
		} else {
			if (res.status === 200) {
				if (!res.data.status) {
          if (res.data.failed){
            return this.setState({
              ...this.state,
              isModalup: true,
              title: '通知',
              body: `该号码已注册过，请勿多次注册`,
              button: '确认',
              isLoading: false
            });
          } else {
            return this.setState({
              ...this.state,
              isModalup: true,
              title: '通知',
              body: '排队成功，请耐心等候我们的短信提醒',
              button: '确认',
              isLoading: false
            });
          }
				} else {
					return this.setState({
						...this.state,
						isModalup: true,
						title: '通知',
						body: '对不起，您的电话号码无效，电话号码必须是加拿大本地电话',
						button: '确认',
						isLoading: false
					});
				}
			} else {
				return this.setState({
					...this.state,
					isModalup: true,
					title: '通知',
					body: '注册失败，请再试一次',
					button: '确认',
					isLoading: false
				});
			}
		}
	}

	async queryWokringDoctor() {
		return await queryDoctor(true);
	}

	render() {
		const { state, props } = this;

		let doctorDOM = state.doctors.map((doctor, index) => {
			return <option key={index}>{doctor.name}</option>;
		});

		return (
			<div style={{ paddingTop: '200px' }}>
				{state.isLoading ? (
					<div className="d-flex align-items-center" style={{ position: 'fixed', top: '50%', left: '50%' }}>
						<Spinner size="lg" animation="border" role="status" variant="success" />
						{/* <span style={{paddingLeft:'5px'}} >Loading...</span> */}
					</div>
				) : (
					<center style={{ margin: '0px 20px 0px 20px' }}>
						{props.state.currentLanguage === 'english' ? (
							<div>
              


								<h1>Please fill up the following form to line up.</h1>
								<h3>
									{' '}
									A text message reminder will be sent to you when it's close to your turn. Thank You!{' '}
								</h3>
                <h3>
									{' '}
									Please do not make multiple sign up, otherwise you will get a warning.{' '}
								</h3>
								<Form onSubmit={this.signup}>
									<Form.Group controlId="doctor">
										<Form.Label>Select Doctor</Form.Label>
										<Form.Control as="select" size="lg" custom required>
											{doctorDOM}
										</Form.Control>
									</Form.Group>
                  <h3><span style={{color:'red'}}>*  </span>Please type in your legal name</h3>
									<Row>
										<Col>
											<Form.Group controlId="lastName">
												<Form.Label>Last Name</Form.Label>
												<Form.Control size="lg" type="text" placeholder="Last Name" required />
											</Form.Group>
										</Col>
										<Col>
											<Form.Group controlId="firstName">
												<Form.Label>First Name</Form.Label>
												<Form.Control size="lg" type="text" placeholder="First Name" required />
											</Form.Group>
										</Col>
									</Row>
									<Form.Group controlId="phone">
										<Form.Label>Phone Number</Form.Label>
										<Form.Control
											size="lg"
											type="text"
											placeholder="eg:604888789"
											pattern="\d{10}"
											required
										/>
									</Form.Group>

									<Button variant="primary" type="submit">
										Submit
									</Button>
								</Form>
							</div>
						) : (
							<div>
								<h1>排队请填写以下表格，我们将会尽快发送手机短信通知您</h1>
                <h3>
									{' '}
									请不要多次注册否则将被警告.{' '}
								</h3>
								<Form onSubmit={this.signup}>
									<Form.Group controlId="doctor">
										<Form.Label>选择您要预约的医生</Form.Label>
										<Form.Control as="select" size="lg" custom>
											{doctorDOM}
										</Form.Control>
									</Form.Group>
                  <h3><span style={{color:'red'}}>*  </span>必须填写医疗卡上的名字,若名字不相符，系统将无法识别</h3>
									<Row>
										<Col>
											<Form.Group controlId="lastName">
												<Form.Label>姓</Form.Label>
												<Form.Control size="lg" type="text" placeholder="" required />
											</Form.Group>
										</Col>
										<Col>
											<Form.Group controlId="firstName">
												<Form.Label>名</Form.Label>
												<Form.Control
													size="lg"
													type="text"
													placeholder=""
													required
												/>
											</Form.Group>
										</Col>
									</Row>
									<Form.Group controlId="phone">
										<Form.Label>手机号</Form.Label>
										<Form.Control
											size="lg"
											type="text"
											placeholder="eg:604888789"
											pattern="\d{10}"
											required
										/>
									</Form.Group>

									<Button size="lg" variant="primary" type="submit">
										上传
									</Button>
								</Form>
							</div>
						)}
					</center>
				)}
				<Modalup
					show={state.isModalup}
					onHide={() => {
						this.setState({ ...state, isModalup: false });
						return window.location.reload(false);
					}}
					title={state.title}
					body={state.body}
					button={state.button}
				/>

				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
			</div>
		);
	}
}
