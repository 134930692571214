import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import BackButton from '../../assets/icons/arrow-left-thick.svg';

export default class Etransfer extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		if (this.props.state.currentLanguage === 'english') {
			return (
				<div className="epay">
					<div className="epay__center">
						<h3 className="epay__center-header">payment options</h3>
						<div className="epay__textDiv">
							<h4>Our clinic takes Cash and Debit card for any payments not covered by msp.</h4>

							<h4>
								Due to Covid-19, if you do need to pay when you are not in the clinic, we also accept
								e-transfers
							</h4>

							<h4 style={{ fontWeight: 700 }}>
								Please contact the clinic at: (778)-379-8299, for E-transfer Email address
							</h4>

							<h4>Thank You</h4>
						</div>
					</div>
					<Link className="backButton" to="/">
						<img src={BackButton} alt="backButton" /> Go Back
					</Link>
				</div>
			);
		} else {
			return (
				<div className="epay">
					<div className="epay__center">
						<h3 className="epay__center-header">缴费方法</h3>
						<div className="epay__textDiv">
							<h4>医疗卡不包括所有的其它费用，有些人医疗卡失效，或者是旅游过来需要自费</h4>

							<h4>自费项目我们诊所接受 现金和debit 储蓄卡</h4>

							<h4>因为受到新冠疫情的影响，我们可以接受E-transfer 网络支付</h4>

							<h4 style={{ fontSize: '30px' }}>如果需要了解更多关于网络支付，请致电(778)-379-8299到诊所了解更多。</h4>
							<h4>谢谢</h4>
						</div>
					</div>
					<Link className="backButton" to="/">
						<img src={BackButton} alt="backButton" /> 返回
					</Link>
				</div>
			);
		}
	}
}
