import React, { Component } from "react";

export default class ClinicQuestionair extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    if (this.props.props.currentLanguage === "english") {
      return (
        <div className="virtual">
          <div className="virtual__bigTextDiv">
            <h3 className="virtual__bigTextDiv-header">
              Patient’s questionnaire{" "}
            </h3>
            <div className="virtual__bigTextDiv-firstDiv">
              Please turn your phone volume on 30minutes before and after your
              scheduled time to<b> pick up no caller ID phone calls</b>, doctor
              will try to call <b>2 times</b> Please have these information{" "}
              <b>BEFORE</b> your appointment, Dr. Miao may ask for it if
              necessary.
              <ol>
                <li>
                  Blood pressure
                  <ul>
                    <li>write down the numbers</li>
                    <li>
                      check <b>before</b> appointment
                    </li>
                    <li>
                      check <b>when having symptoms</b>
                    </li>
                    <li>
                      once per month, more frequent if you have many health
                      issues or are elderly
                    </li>
                  </ul>{" "}
                </li>
                <li>
                  Weight
                  <ul>
                    <li>
                      check <b>before</b> appointment
                    </li>
                    <li>
                      check <b>when having symptoms</b>
                    </li>
                    <li>
                      once per month, more frequent if you have health issues
                    </li>
                  </ul>{" "}
                </li>
                <li>
                  For infection, lung or heart issues,
                  <b> strongly recommend checking before appointment</b>:
                  Temperature and oxygen monitor (can purchase from amazon/drug
                  store $20-40) For medications .{" "}
                  <p>
                    <b>For medications </b>
                    <ul>
                      <li>
                        Have all your medications with you for the appointment{" "}
                      </li>
                      <li>
                        Know the name, dose and how often you take the
                        medication{" "}
                      </li>
                    </ul>
                  </p>
                </li>
                <p>
                  <b>For any medical concerns, answer these questions. </b>
                  <ul>
                    <li>
                      When did it start? Eg. 3days, 1week, 3months or 2years ago{" "}
                    </li>
                    <li>
                      Where is the problem? Eg. Pain in left chest, rash to
                      back, right thumb joint pain{" "}
                    </li>
                    <li>
                      Why do you think it happened? Eg. Recent injury/ lifting,
                      diet issues, previously happened?{" "}
                    </li>
                    <li>
                      4. If pain or discomfort:
                      <ul>
                        <li>
                          rate: 0 is no pain, 10 is maximum pain/ discomfort{" "}
                        </li>
                        <li>
                          duration: lasting seconds/minutes/hours,
                          intermittently or continuous{" "}
                        </li>
                        <li>
                          describe- sharp, dull, pressure, burning, cramping,
                          shooting etc{" "}
                        </li>
                      </ul>
                    </li>
                    <li>
                      What triggers the symptom? Eg. evening only, any
                      activities, position changes etc{" "}
                    </li>
                    <li>
                      What relieves the symptoms? Eg. Medications, rest,
                      position change etc{" "}
                    </li>
                    <li>
                      Any other symptoms? Eg. weight loss, fever, sweat at
                      night, diarrhea, constipation, vision change, ring in
                      ears(tinnitus), cough, any organ discharge, chest pain,
                      shortness of breath, feeling of fainting
                    </li>
                  </ul>
                </p>
              </ol>
              <h4>Thank you so much！We are in this together!</h4>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="virtual">
          <div className="virtual__bigTextDiv">
            <h3 className="virtual__bigTextDiv-header">看病之前准备问题 </h3>
            <div className="virtual__bigTextDiv-firstDiv">
              请在预约前后30分钟手机别静音, 注意接<b>没有来电显示</b>的电话,
              医生会尝试打<b>2次</b>
              请在您的<b>看病预约前</b>准备好以下信息，有需要情况下, 医生会问
              <ol>
                <li>
                  血压
                  <ul>
                    <li>预约前检查</li>
                    <li>有症状时检查</li>
                    <li>一个月测一次, 有健康问题或者老年人建议检查更频繁</li>
                  </ul>{" "}
                </li>
                <li>
                  体重
                  <ul>
                    <li>预约前检查</li>
                    <li>有症状时检查</li>
                    <li>一个月测一次, 有健康问题或者老年人建议检查更频繁</li>
                  </ul>{" "}
                </li>
                <li>
                  怀疑感染, 肺部或者心脏问题- 强烈建议预约前检查: 体温与氧气状况
                  (amazon或者药房可以买到氧气仪器oxygen monitor $20-40)
                  <p>
                    <b>关于药物</b>
                    <ul>
                      <li>请在预约时准备好您所有的药物 </li>
                      <li>请记清药物名字(英文字母), 剂量和多久吃一次 </li>
                    </ul>
                  </p>
                </li>
                <p>
                  <b>关于任何健康问题, 请准备好以下信息 </b>
                  <ul>
                    <li>什么时候开始的? 例如: 3天, 1周, 3个月或者2年前 </li>
                    <li>
                      症状具体部位? 例如: 左胸口疼, 后背起疹子, 右拇指关节疼{" "}
                    </li>
                    <li>
                      为什么发生症状? 例如: 近期受伤/拿重物, 饮食问题,
                      以前发生过 (多久之前){" "}
                    </li>
                    <li>
                      假如有疼痛或者不舒服:
                      <ul>
                        <li>评分: 0是完全不痛 10是极限疼痛/不舒服 </li>
                        <li>
                          持续时长: 秒, 分钟, 小时, 断断续续或者持续不舒服{" "}
                        </li>
                        <li>
                          形容: 尖锐, 钝痛, 有压力感的疼, 灼烧感, 一阵一阵的疼,
                          放射性的疼等{" "}
                        </li>
                      </ul>
                    </li>
                    <li>
                      什么让症状出现? 例如: 晚上时间, 任何活动, 位置/姿势变化{" "}
                    </li>
                    <li>什么让症状退去? 例如: 药物, 休息, 位置/姿势变化 </li>
                    <li>
                      其他任何症状? 例如: 体重降低, 发烧, 夜汗, 腹泻, 便秘,
                      视力变化, 耳鸣, 咳嗽, 任何器官分泌物, 胸闷, 呼吸不过来,
                      要晕倒的感觉
                    </li>
                  </ul>
                </p>
              </ol>
              <h4>
                在这个特殊时期，我们正在竭尽全力满足您的要求并感谢您的耐心配合！
              </h4>
            </div>
          </div>
        </div>
      );
    }
  }
}
