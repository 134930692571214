import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import BackButton from '../../assets/icons/arrow-left-thick.svg';

import Check from '../../assets/check.png';
import Cross from '../../assets/wrong.png';

export default class GuardMe extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		if (this.props.state.currentLanguage === 'english') {
			return (
				<div className="guardme">
					<div className="guardme__large">
						<h2 className="guardme-header">GUARD.ME INTERNATIONAL INSURANCE</h2>
						<h3 className="guardme-header">Coverage</h3>
						<div className="guardme__center">
							<div className="guardme__center__leftRightDiv">
								<img className="guardme__center-img" src={Check} alt="coveredPic" />
								<h3>Covered by Guard.me</h3>
								<div className="guardme__center__leftRightDiv-missingDiv">
									***PLEASE REMEMBER TO BRING YOUR INSURANCE CARD, PICTURE ID, AND A PRINTED INSURANCE
									CLAIM FORM
								</div>
								<h4>The following services are covered by guard.me</h4>
								<h5>
									For acute conditions that began no less than 5 days after the policy start date and
									before the policy end date, including:{' '}
								</h5>
								<ul>
									<li>Bladder / Kidney Infection</li>
									<li>New & Acute Fractures</li>
									<li>Cold / Flu</li>
									<li>
										Acute Exacerbation & Complications of Chronic Conditions (ex. asthma attacks,
										insulin shock)
									</li>
									<li>Conjunctivitis / Pink Eye</li>
									<li>Gastrointestinal: Nausea / Vomiting / Upset Stomach / Food Poisoning</li>
									<li>Headaches / Migraines</li>
									<li>Hives / Rash</li>
									<li>Lacerations / Contusions </li>
									<li>
										Acute Respiratory Issues: Cough / Bronchitis / Sinus Infection / Pharyngitis /
										Strep Throat
									</li>
									<li>Sprains / Strains</li>
									<li>Symptomatic STD (NOT for screening, counselling, or routine testing)</li>
									<li>Warts (new)</li>
									<li>Acute Pain (NOT related to a chronic illness or injury)</li>
									<li>
										Prescription Medication up to 30-day supply for new and urgent conditions (NOT
										cover over-the-counter, preventive, vitamins or ethical drugs)
									</li>
								</ul>
							</div>
							<div className="guardme__center__leftRightDiv">
								<img className="guardme__center-img" src={Cross} alt="unCoveredPic" />
								<h3>Not covered by Guard.me</h3>
								<div className="guardme__center__leftRightDiv-missingDiv" />
								<h4>The following services are not covered by guard.me</h4>
								<h5>
									Patients pay $130 for a consultation regarding any condition that began outside of
									policy coverage dates, including:
								</h5>
								<ul>
									<li>Acne</li>
									<li>Annual / General Physical</li>
									<li>Blood Pressure Check</li>
									<li>
										Chronic Conditions (ex. Asthma, Hypertension, Cholesterol, Diabetes, Mental
										Health, ADHD, Eczema. Thyroidism, Triglycerides)
									</li>
									<li>
										Counselling (ex. Weight Control, Baldness, Dietary, Smoking Cessations, Sexual
										Health)
									</li>
									<li>Diagnostic Tests - MRI and CT Scans require prior approval</li>
									<li>Immunizations / Vaccinations (ex. HPV) / Injections / Immunity Screening </li>
									<li>TB Surveillance</li>
									<li>New Patient Exams</li>
									<li>
										Preventative, elective, or non-emergency treatment (ex. monitoring of chronic
										conditions)
									</li>
									<li>Congenital or Genetic Disorders</li>
									<li>Prescription Refills and Visits for Rx Refills</li>
									<li>Psychotherapy Counselling</li>
									<li>
										Sexual Health (ex. Birth Control / Contraception, STI Counselling, Infertility /
										Erectile Dysfunction)
									</li>
									<li>Form Completion</li>
									<li>Sick Notes</li>
									<li>Substance Use (ex. alcohol, drug)</li>
									<li>Seasonal Allergies</li>
									<li>
										Pregnancy-Related Visits (Call 1-888-756-8428 to confirm pregnancy coverage)
									</li>
								</ul>
							</div>
						</div>
						<a
							href="https://www.guard.me/"
							target="_blank"
							rel="noopener noreferrer"
							className="guardme-footer"
						>
							Learn More
						</a>
					</div>
					<Link className="backButton" to="/">
						<img src={BackButton} alt="backButton" style={{ marginLeft: '70%' }} /> Go Back
					</Link>
				</div>
			);
		} else {
			return (
				<div className="guardme">
					<div className="guardme__large">
						<h2 className="guardme-header">GUARD.ME INTERNATIONAL INSURANCE</h2>
						<h3 className="guardme-header">Coverage</h3>
						<h4 className="guardme-header1">
							如果你们有些人看不懂里面的英文<br />可以用google自己把自己的病情翻译一下然后核查是否属于被包括的类型.
						</h4>
						<div className="guardme__center">
							<div className="guardme__center__leftRightDiv">
								<img className="guardme__center-img" src={Check} alt="coveredPic" />
								<h3>Guard.me包括项目</h3>
								<div className="guardme__center__leftRightDiv-missingDiv">
									***请记住带上您的保险卡，照片ID和打印的保险 索赔表
								</div>
								<h4>下面的项目是guardme保险包括的</h4>
								<h5>对于在保单开始日期之后至保单结束日期之前不少于5天开始的急性疾病，包括：</h5>
								<ul>
									<li>Bladder / Kidney Infection</li>
									<li>New & Acute Fractures</li>
									<li>Cold / Flu</li>
									<li>
										Acute Exacerbation & Complications of Chronic Conditions (ex. asthma attacks,
										insulin shock)
									</li>
									<li>Conjunctivitis / Pink Eye</li>
									<li>Gastrointestinal: Nausea / Vomiting / Upset Stomach / Food Poisoning</li>
									<li>Headaches / Migraines</li>
									<li>Hives / Rash</li>
									<li>Lacerations / Contusions </li>
									<li>
										Acute Respiratory Issues: Cough / Bronchitis / Sinus Infection / Pharyngitis /
										Strep Throat
									</li>
									<li>Sprains / Strains</li>
									<li>Symptomatic STD (NOT for screening, counselling, or routine testing)</li>
									<li>Warts (new)</li>
									<li>Acute Pain (NOT related to a chronic illness or injury)</li>
									<li>
										Prescription Medication up to 30-day supply for new and urgent conditions (NOT
										cover over-the-counter, preventive, vitamins or ethical drugs)
									</li>
								</ul>
							</div>
							<div className="guardme__center__leftRightDiv">
								<img className="guardme__center-img" src={Cross} alt="unCoveredPic" />
								<h3>Guard.me 不包括项目</h3>
								<div className="guardme__center__leftRightDiv-missingDiv" />
								<h4>下面的项目是 guard me保险不包括的.</h4>
								<h5>对于任何在保单覆盖日期之外开始的疾病的咨询，患者需要支付$ 130，包括：</h5>
								<ul>
									<li>Acne</li>
									<li>Annual / General Physical</li>
									<li>Blood Pressure Check</li>
									<li>
										Chronic Conditions (ex. Asthma, Hypertension, Cholesterol, Diabetes, Mental
										Health, ADHD, Eczema. Thyroidism, Triglycerides)
									</li>
									<li>
										Counselling (ex. Weight Control, Baldness, Dietary, Smoking Cessations, Sexual
										Health)
									</li>
									<li>Diagnostic Tests - MRI and CT Scans require prior approval</li>
									<li>Immunizations / Vaccinations (ex. HPV) / Injections / Immunity Screening </li>
									<li>TB Surveillance</li>
									<li>New Patient Exams</li>
									<li>
										Preventative, elective, or non-emergency treatment (ex. monitoring of chronic
										conditions)
									</li>
									<li>Congenital or Genetic Disorders</li>
									<li>Prescription Refills and Visits for Rx Refills</li>
									<li>Psychotherapy Counselling</li>
									<li>
										Sexual Health (ex. Birth Control / Contraception, STI Counselling, Infertility /
										Erectile Dysfunction)
									</li>
									<li>Form Completion</li>
									<li>Sick Notes</li>
									<li>Substance Use (ex. alcohol, drug)</li>
									<li>Seasonal Allergies</li>
									<li>
										Pregnancy-Related Visits (Call 1-888-756-8428 to confirm pregnancy coverage)
									</li>
								</ul>
							</div>
						</div>
						<a
							href="https://www.guard.me/"
							target="_blank"
							rel="noopener noreferrer"
							className="guardme-footer"
						>
							Learn More
						</a>
					</div>
					<Link className="backButton" to="/">
						<img src={BackButton} alt="backButton" /> 返回
					</Link>
				</div>
			);
		}
	}
}
