import React, { Component } from 'react';

export default class Faq extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        if (this.props.props.currentLanguage === 'english') {
            return (
                <div className="clinicWalkInDiv">
                    <h2 className="clinicWalkInDiv-policyHeader faq-header">FAQ</h2>
                    <div className="clinicWalkInDiv-policyDiv">
                        <h3>Q1 .If I am doctor L’s patient, can I switch to Dr C?</h3>
                        <h5>Unfortunately, no. Our policy is that our doctors do not accept patients currently under the care of another doctor within the same clinic. For example, some patients initially under the care of one of our male doctors expressed a desire to switch to a female doctor who later joined our clinic. However, such requests cannot be accommodated. While our female doctors are available to assist with specific services like Pap smears and other female-related examinations, they cannot replace your primary care physician at our clinic.</h5>
                        <h3>Q2. As Dr. L's patient, am I allowed to see Dr. C?</h3>
                        <h5>Ideally, you should consult your assigned family doctor for any medical concerns. If your family doctor is away, you may temporarily see another doctor within our clinic. However, once your family doctor returns, you should resume your consultations with him/her for continuity of care.</h5>
                        <h3>Q3.Can I have 2-3 medical doctors at the same time?</h3>
                        <h5>Unfortunately, no. You are entitled to only one family doctor at a time. Having a single doctor responsible for your care ensures consistency and clarity in your medical treatment. If multiple doctors are involved, it can lead to scattered medical reports and confusion about who is overseeing your care, both at the clinic and in hospital settings. Therefore, it's important to stick with one family doctor for effective and organized healthcare management.</h5>
                        <h3>Q4. Do I need to book an appointment to see the doctor?</h3>
                        <h5>Yes, it is. Since January 1st, 2024, we permanently terminated the walk-in services and fully implemented our online booking system. To see a doctor, you must book an appointment in advance. Without a prior appointment, securing a same-day slot is highly impossible, as appointments are usually booked several days in advance and same-day slots tend to be fully occupied. Therefore, it's essential to schedule your visit ahead of time to ensure you get a timely consultation. All the appointments, including in-person consultations, can be made online 24/7. Patients already registered with our doctors are encouraged to utilize our website for all their booking needs.</h5>

                        <h3>Q5. Is it necessary for my child to be present during discussions about their care?</h3>
                        <h5>Yes, it is essential for the child to be present for the doctor to provide care. Parents cannot substitute for their child's visit. The doctor needs to personally assess the child to offer accurate medical advice and treatment.</h5>

                        <h3>Q6. What services are not covered by MSP?</h3>
                        <div>
                            <h5>The following services are not covered under MSP:</h5>

                            <h5>• Driver’s license form</h5>
                            <h5>• Sick Notes</h5>
                            <h5>• Travel advice and medicine</h5>
                            <h5>• School or work form</h5>
                            <h5>• Government form</h5>
                            <h5>• MSP number: 604-683-7151</h5>

                            <h5>For more information, please click <a href="https://bcfamilydocs.ca/wp-content/uploads/2023/03/Uninsured-Services-Invoice-April-1-2023-for-LFP-Physicians.pdf" target="_blank"
                                rel="noopener noreferrer">Here</a>.(fee sheet)</h5>
                        </div>

                        <h3>Q7.What if I do not have valid medical coverage?</h3>
                        <h5>In cases where your medical coverage cannot be validated or if you do not possess medical coverage, please be advised that there will be a fee for the visit. The standard fee for a private consultation is $150. For other types of private visits, please refer to our fee sheet for detailed pricing. Click <a href="https://bcfamilydocs.ca/wp-content/uploads/2023/03/Uninsured-Services-Invoice-April-1-2023-for-LFP-Physicians.pdf" target="_blank"
                            rel="noopener noreferrer">Here</a>.(fee sheet)</h5>

                        <h3>Q8. What should I do if I recently moved to BC from another province or country?</h3>
                        <h5>If you've moved from another province, please contact the MSP service of your previous province to confirm the validity of your care card. If your card is invalid, you will need to pay for our services privately. We require verbal confirmation from them to acknowledge your valid health coverage. For those who have moved from another country and do not have MSP coverage, a charge will be applied for our services. However, if you have private insurance, we can issue a receipt for you to claim from your insurance provider. Please be aware, if you are from Quebec, your health card will not be valid in BC, and you will need to cover the costs privately. And you can only make an appointment during Monday-Friday 9 am-2 pm.</h5>

                        <h3>Q9. Is it possible to receive Hepatitis A and B vaccinations for travel purposes at the clinic?</h3>
                        <h5>Yes, you can obtain Hepatitis A and B vaccinations at our clinic. However, please note that these services, including travel advice and medication, are not covered by MSP and require private payment. For detailed pricing, please refer to our fee sheet. Click <a href="https://bcfamilydocs.ca/wp-content/uploads/2023/03/Uninsured-Services-Invoice-April-1-2023-for-LFP-Physicians.pdf" target="_blank"
                            rel="noopener noreferrer">Here</a>.(fee sheet)</h5>

                        <h3>Q10. Will I be contacted by the clinic regarding my test results?</h3>
                        <h5>We will make every effort to contact you if your test results are abnormal. However, we encourage you to proactively follow up with your doctor through making new appointments, especially if symptoms persist or worsen. It is the responsibility of patients to ensure they follow up for all test results.</h5>

                        <h3>Q11. Can my baby get routine immunizations at the clinic?</h3>
                        <h5>Unfortunately, our clinic does not provide routine vaccinations for babies. However, you can contact the public health department for these services. Their phone number is (604) 918-7605.</h5>

                        <h3>Q12. Can I get HPV shot in the clinic.</h3>
                        <h5>Yes, our doctors can prescribe the HPV vaccine for you. After receiving the prescription, you will need to purchase the vaccine from a pharmacy. It's important to keep a record of the vaccination dates for your reference.</h5>

                        <h3>Q13.Can I email you for any questions?</h3>
                        <h5>Unfortunately, we are unable to respond to inquiries via email. To ensure privacy and protection of your personal information, and because our front desk does not monitor emails, we ask that you call us directly for any questions or concerns that not covered by our website.</h5>

                        <h3>Q14. How do I apply for BC MSP card?</h3>
                        <h5>To apply for a BC MSP card, visit the Medical Service Plan website. If you need assistance or have questions, you can call the MSP at 604-683-7151.</h5>

                        <h3>Q15. Can I obtain a medical report without seeing the doctor?</h3>
                        <h5>Unfortunately, no. Your report must be reviewed by a doctor first before we can provide you with a copy. There is a charge of $1 per copy. If payment is made via E-transfer, an additional bank fee will be charged.</h5>

                        <h3>Q16. Is it possible to discuss my medical report over the phone with the reception staff?</h3>
                        <h5>Unfortunately, we are unable to discuss or provide your medical report over the phone through our reception staff. This policy is in place to maintain confidentiality and accuracy in the handling of your medical information.</h5>

                        <h3>Q17. Is it possible for the clinic to send my referral to two specialists so I can compare who responds faster?</h3>
                        <h5>Unfortunately, we cannot send referrals to multiple specialists for the purpose of comparing response times. If you wish to change your specialist referral, we can assist you with that, but we are not able to send out dual referrals for comparison.</h5>

                        <h3>Q18. Can the clinic staff assist me in calling a specialist or hospital to reschedule my appointment to a different date?</h3>
                        <h5>Unfortunately, our staff and doctors are not responsible for changing or rescheduling appointments with specialists or hospitals. You will need to contact the specialist’s office directly to arrange a date and time that better suits your schedule.</h5>

                        <h3>Q19. If I don't speak English, can the clinic provide translation services or assist me in making calls?</h3>
                        <h5>Unfortunately, our clinic does not offer translation services. Like many other medical offices, we primarily communicate in English. We recommend seeking assistance from a family member or relative who can help with translation.</h5>

                        <h3>Q20. How can I receive my prescription following a virtual visit?</h3>
                        <div>
                            <h5>To receive your prescription after a virtual visit, please follow these steps:</h5>

                            <h5>• Provide us with the fax number of your preferred pharmacy. </h5>
                            <h5>• Allow us 24 hours to process and send the prescription to the pharmacy. </h5>
                            <h5>• After one day, contact your pharmacy to confirm if your prescription is ready. </h5>

                            <h5>Please note that pharmacists can also be busy, so it's important to double-check with them.</h5>
                        </div>

                        <h3>Q21. How can I arrange a virtual appointment with a doctor?</h3>
                        <h5>Click <a href="https://jvonebooking.mpeer.net:8881/appointment/" target="_blank"
                            rel="noopener noreferrer">here</a>.</h5>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="clinicWalkInDiv">
                    <div className="clinicWalkInDiv-policyDiv">
                        <h3>Q1 .如果我是李医生的病人，我能换成陈医生吗？</h3>
                        <h5>很抱歉的通知您，这么做是不可以的。我们的政策是，医生不接收目前在同一诊所已有其他医生作为家医的病人。例如，一些最初由我们的一位男医生作为家医的患者表示希望转到后来加入我们诊所的一位女医生那里。然而，这类请求是不能被满足的。尽管我们的女医生可以做特定的检查，如子宫颈涂片检查和其他女性相关检查，但她们不能取代您在我们诊所的主治医生。</h5>
                        <h3>Q2. 作为李医生的病人，我可以看陈医生吗？</h3>
                        <h5>理想情况下，您应该咨询您指定的家庭医生以解决所有的医疗问题。如果您的家庭医生放假不在诊所一段时间，您可以临时看我们诊所的另一位医生。然而，一旦您的家庭医生返回，您应该继续与他/她咨询，以保持护理的连续性。</h5>
                        <h3>Q3.我可以同时有2-3位家庭医生吗</h3>
                        <h5>很抱歉，您只能有一位家庭医生。让一位医生负责您的护理可以确保您的治疗的一致性和清晰性。如果涉及多个医生，您的医疗报告会被分散；而且您去医院的时候，医院会对谁在监管您的护理产生混淆。因此，坚持一个家庭医生是有效、有序的健康管理的重要部分。</h5>
                        <h3>Q4. 我需要预约才能看医生吗？</h3>
                        <h5>是的。自2024年1月1日起，我们永久终止了非预约（walk-in）服务，并全面实施了我们的在线预约系统。要看医生，您必须提前预约。没有提前预约，想要当天到诊所直接预约几乎不可能做到，因为预约通常需要提前几天，而当天的名额往往已满。因此，提前安排您的预约是确保您及时咨询的关键。所有的预约，包括到诊所的面对面咨询，都可以在网上24/7预定。我们鼓励所有已经在我们诊所注册家医的病人使用我们的网站进行所有预约。</h5>

                        <h3>Q5. 我孩子需要看病，但是他/她在学校，我可以替她/他来吗？</h3>
                        <h5>孩子的医疗护理需要孩子本人必须在场，医生才能提供护理。父母不能代替孩子的就诊。医生需要亲自评估孩子，以提供准确的医疗建议和治疗。</h5>

                        <h3>Q6.哪些服务不在MSP范围内？</h3>
                        <div>
                            <h5>以下服务不在MSP覆盖范围内：</h5>

                            <h5>• 驾驶填写</h5>
                            <h5>• 病假条</h5>
                            <h5>• 旅行建议</h5>
                            <h5>• 学校或工作表格</h5>
                            <h5>• 政府表格</h5>
                            <h5>• MSP电话号码：604-683-7151</h5>

                            <h5>欲了解更多信息，请点击 <a href="https://bcfamilydocs.ca/wp-content/uploads/2023/03/Uninsured-Services-Invoice-April-1-2023-for-LFP-Physicians.pdf" target="_blank"
                                rel="noopener noreferrer">此处</a>.（收费表）</h5>
                        </div>

                        <h3>Q7.如果我没有有效的医疗保险怎么办？</h3>
                        <h5>如果您的医疗保险无法核实或者您没有医疗保险，请注意，您的预约将会收取就诊费用。私人咨询的标准费用为150加元。</h5>

                        <h3>Q8.我最近从另一个省份或国家搬到BC，我该怎么做？</h3>
                        <h5>如果您从另一个省份搬来，请您在就诊之前联系您之前省份的MSP服务以确认您的医疗卡的有效性。如果您的卡无效，您将需要按照私人咨询的标准支付服务费用。如果有效，诊所前台需要联系他们得到核实。对于那些从其他国家搬来且没有MSP覆盖的人，您的预约将需要支付服务费。然而，如果您有私人保险，我们可以为您开具收据，以便您向您的保险公司索赔。请注意，如果您来自魁北克，您的健康卡在BC无效，您将需要私人支付费用。并且您只能在周一至周五上午9点至下午2点预约。</h5>

                        <h3>Q9. 我能在诊所接种旅行用的甲肝和乙肝疫苗吗？</h3>
                        <h5>是的，您可以在我们诊所接种甲肝和乙肝疫苗。然而，请注意，这些服务，包括旅行建议和药物，不在MSP覆盖范围内，需要私人支付。有关详细定价，请参阅我们的费用表。点击<a href="https://bcfamilydocs.ca/wp-content/uploads/2023/03/Uninsured-Services-Invoice-April-1-2023-for-LFP-Physicians.pdf" target="_blank"
                            rel="noopener noreferrer">这里 </a>.（费用表）</h5>

                        <h3>Q10. 诊所会就我的检测结果与我联系吗？</h3>
                        <h5>如果您的检测结果异常，我们将尽一切努力与您联系让您来复诊。但是，无论您的报告有问题还是没问题，患者有责任跟进自己所有的检测结果。比如验血最晚一个星期到结果，您可以约一个星期之后的复诊。无论报告结果如何，都可以和医生讨论一下需不要注意一些什么？</h5>

                        <h3>Q11. 我的宝宝能在诊所接种常规免疫疫苗吗？</h3>
                        <h5>抱歉的通知您，我们的诊所不提供婴儿的常规疫苗接种。但是，您可以联系公共卫生部门以获取这些服务。他们的电话号码是 (604) 918-7605。</h5>

                        <h3>Q12. 我能在诊所接种HPV疫苗吗？</h3>
                        <h5>是的，我们的医生可以为您开具9价的HPV疫苗处方。如果您是这边长大的，通常在学校就会统一安排注射。收到处方后，您需要从药房购买疫苗，在药房或者我们诊所接种都可以。请保留接种日期的记录以供参考。</h5>

                        <h3>Q13.我能通过电子邮件向您提问吗？</h3>
                        <h5>抱歉的通知您，我们无法通过电子邮件回应咨询。为了确保您的个人信息的隐私和保护，同时因为我们的前台不监控电子邮件，我们请您直接致电我们，以解决网站上未涵盖的任何问题或疑虑。</h5>

                        <h3>Q14. 我如何申请BC MSP卡？</h3>
                        <h5>要申请BC MSP卡，请访问医疗服务计划网站。如果您需要帮助或有问题，可以致电MSP：604-683-7151。</h5>

                        <h3>Q15. 我能在不见医生的情况下获得医疗报告吗？</h3>
                        <h5>抱歉的通知您，不可以。在我们提供报告的电子版或复印件之前，您的报告必须先由医生审查。如果您和医生讨论后需要报告的复印件，每张复印件的费用是1加元。如果通过电子转账支付，将收取额外的银行费用。</h5>

                        <h3>Q16. 我能通过电话与前台人员讨论我的医疗报告吗？</h3>
                        <h5>抱歉的通知您，我们的前台无法通过电话讨论或提供您的医疗报告。这项政策旨在维护您的医疗信息的保密性和准确性。</h5>

                        <h3>Q17. 我能让诊所把我的转诊单发送给两位专科医生，以便我比较谁回应得更快吗？</h3>
                        <h5>抱歉的通知您，不可以。如果您希望更改您的专科医生转诊，我们可以协助您，但我们不可以同时发送给两位专科医生。</h5>

                        <h3>Q18. 抱歉的通知您，不可以。如果您希望更改您的专科医生转诊，我们可以协助您，但我们不可以同时发送给两位专科医生。</h3>
                        <h5>抱歉的通知您，我们的员工和医生不负责更改或重新安排与专科诊所或医院的预约。您需要直接联系专科诊所的办公室，安排更适合您日程的日期和时间。</h5>

                        <h3>Q19. 如果我不会说英语，诊所能提供翻译服务或帮我打电话吗？</h3>
                        <h5>
                            抱歉的通知您，我们的诊所不提供翻译服务。像许多其他医疗机构一样，我们主要用英语沟通。我们诊所前台有中文便捷服务，并不代表我们提供治疗相关的翻译给患者。我们建议您寻求家庭成员、亲戚或中侨Success 机构的帮助进行翻译。
                        </h5>

                        <h3>Q20. 如何在虚拟访问后接收我的处方？</h3>
                        <div>
                            <h5>在虚拟访问后接收您的处方，请按照以下步骤操作：</h5>

                            <h5>• 向我们提供您首选药房的传真号码。 </h5>
                            <h5>• 允许我们24小时处理并将处方发送到药房。 </h5>
                            <h5>• 允许我们24小时处理并将处方发送到药房。 </h5>

                            <h5>请注意，药剂师也可能很忙，所以提前核实很重要。</h5>
                        </div>

                        <h3>Q21. 我如何安排与医生的网络远程看病？</h3>
                        <h5>要安排网络远程看病，即虚拟预约，请访问我们的网站 www.jvonmedicals.ca。在那里，选择“预约指南”选项，它将指导您如何与医生进行虚拟连接。您还可以直接点击我们网站上提供的 ‘https://www.jvonmedicals.ca/onlinebookinginstruction’ 链接以获得进一步的帮助。</h5>
                    </div>
                </div>
            )
        }
    }
}
