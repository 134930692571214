import React, { Component } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import './styles/main.css';

import MainBody from './components/MainBody';
import BookingPage from './components/BookingPage';
import TheClinicPage from './components/TheClinicPage';
import SchedulePage from './components/SchedulePage';
import ContactPage from './components/ContactPage';
import PaymentPage from './components/PaymentPage';

import NewFamily from './components/patientNewOld/NewPatientFamily';
import OldFamily from './components/patientNewOld/OldPatientFamily';
import NewWalkIn from './components/patientNewOld/NewPatientWalkin';
import OldWalkIn from './components/patientNewOld/OldPatientWalkin';
import OldVirtualPatient from './components/patientNewOld/VirtualPatient';
import NewVirtualPatient from './components/patientNewOld/NewVirutalPatient';

//patient tool
import OnlineBookingInstruction from './components/patientsTool/BookingIns';
import BookingInstruction from './components/patientsTool/BookingInstruction';
import FormPage from './components/patientsTool/Forms';
import FAQPage from './components/patientsTool/FAQ';
import EPayment from './components/patientsTool/Etransfer';
import GuardMe from './components/patientsTool/GuardMe';
import PrivatePatient from './components/patientsTool/PrivatePatient';
import PatientSignup from './components/smsService/PatientSignup';
import SmsManage from './components/smsService/SmsManage';

//resources information
import FeeUninsured from './components/resourcesInformation/Uninsured';
import TestResult from './components/resourcesInformation/TestResult';
import Referal from './components/resourcesInformation/Refferal';
import Renewal from './components/resourcesInformation/Renewal';
import Insured from './components/resourcesInformation/Insured';
import ChartTransfer from './components/resourcesInformation/ChartTransfer';

import Admin from './components/Admin';
import Control from './components/AdminControlPage';

import AllNews from './components/news/AllNews';
import IdNews from './components/news/IdNewsChinese';
import AcceptingNewsPatientNews from './components/news/AcceptingNewPatient';
import LotusSmileNews from './components/news/LotusSmileNews';
import QRcodeNews from './components/news/QRcodeNews';

import logo from './assets/NewsBanner/jvonLogo.png';
import phoneLogo from './assets/icons/phone.svg';
import MenuIcon from './assets/icons/menuSmall.svg';
// import FlexApi from 'twilio/lib/rest/FlexApi';

export default class App extends Component {
	constructor() {
		super();
		this.state = {
			currentLanguage: 'english',
			currentLocation: window.location.href,
			result: [],
			isLoading: true,
			showMenu: 'none'
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	changeLanguage = () => {
		if (this.state.currentLanguage === 'english') {
			this.setState({ currentLanguage: 'chinese' });
		} else {
			this.setState({ currentLanguage: 'english' });
		}
	};

	showMenu = () => {
		if (this.state.showMenu === 'none') {
			this.setState({
				showMenu: 'flex'
			});
		} else {
			this.setState({
				showMenu: 'none'
			});
		}
	};

	render() {
		if (this.state.showMenu === 'flex' && this.state.currentLanguage === 'english') {
			return (
				<div className="navDiv__mobilePopOut">
					<button onClick={this.changeLanguage} className="navDiv__mobilePopOut-button">
						中文
					</button>
					<div className="navDiv__mobilePopOut__links">
						<Link className="navDiv__mobilePopOut__links-link" to="/" onClick={this.showMenu}>
							Home
						</Link>
						<Link className="navDiv__mobilePopOut__links-link" to="/theclinic" onClick={this.showMenu}>
							About
						</Link>
						<Link className="navDiv__mobilePopOut__links-link" to="/schedule" onClick={this.showMenu}>
							Schedule
						</Link>
						<Link className="navDiv__mobilePopOut__links-link" to="/contact" onClick={this.showMenu}>
							Resource
						</Link>
						<Link className="navDiv__mobilePopOut__links-link" to="/booking_instruction" onClick={this.showMenu}>
							Booking Instruction
						</Link>
					</div>
				</div>
			);
		} else if (this.state.showMenu === 'flex' && this.state.currentLanguage !== 'english') {
			return (
				<div className="navDiv__mobilePopOut">
					<button onClick={this.changeLanguage} className="navDiv__mobilePopOut-button">
						English
					</button>
					<div className="navDiv__mobilePopOut__links">
						<Link className="navDiv__mobilePopOut__links-link" to="/" onClick={this.showMenu}>
							主页
						</Link>
						<Link className="navDiv__mobilePopOut__links-link" to="/theclinic" onClick={this.showMenu}>
							关于诊所
						</Link>
						<Link className="navDiv__mobilePopOut__links-link" to="/schedule" onClick={this.showMenu}>
							医生工作时间
						</Link>
						<Link className="navDiv__mobilePopOut__links-link" to="/contact" onClick={this.showMenu}>
							资源
						</Link>
						<Link className="navDiv__mobilePopOut__links-link" to="/booking_instruction" onClick={this.showMenu}>
							预约说明
						</Link>
					</div>
				</div>
			);
		} else if (this.state.currentLanguage === 'english') {
			return (
				<div className="App">
					<div className="navDiv">
						<div className="navDiv__mobile">
							<Link to='/'>
								<img className="navDiv__mobile-logo" src={logo} alt="logo" />{' '}
							</Link>
							<div>
								<h4>J-Von Medical Center</h4>
								<section className='nav-mobile-section'>
									{' '}
									<img src={phoneLogo} alt="phone" /> {'	'}
									<a href="tel:+17783798299" className='nav-mobile-section-atag'>(778)-379-8299</a>
								</section>
							</div>
							<img className="navDiv__mobile-menu" src={MenuIcon} alt="menu" onClick={this.showMenu} />
						</div>
						<div className="navDiv__navBar">
							<div className="navLeft">
								<Link to='/'>
									<img src={logo} alt="logo" className='jvonLogo' />
								</Link>
								<div className="navDiv__navBar-title">
									<h5>J-Von Medical Center</h5>
									<section>
										{' '}
										<img src={phoneLogo} alt="phone" /> {'	'}
										<a href="tel:+17783798299">(778)-379-8299</a>
									</section>
								</div>
							</div>

							<div className='navRight'>
								<div className="navDiv__navBar__links">
									<Link className="navDiv__navBar__links-link" to="/">
										Home
									</Link>
									<Link className="navDiv__navBar__links-link" to="/theclinic">
										About
									</Link>
									<Link className="navDiv__navBar__links-link" to="/schedule">
										Schedule
									</Link>
									<Link className="navDiv__navBar__links-link" to="/contact">
										Resource
									</Link>
									<Link className="navDiv__navBar__links-link" to="/booking_instruction">
										Booking Instruction
									</Link>
								</div>
								<button onClick={this.changeLanguage} className="navDiv__navBar-button">
									中文
								</button>
							</div>
						</div>
					</div>
					<Switch>
						<Route path="/" exact render={(props) => <MainBody {...props} state={this.state} />} />
						<Route path="/theclinic" render={(props) => <TheClinicPage {...props} state={this.state} />} />
						<Route path="/schedule" render={(props) => <SchedulePage {...props} state={this.state} />} />
						<Route path="/contact" render={(props) => <ContactPage {...props} state={this.state} />} />
						<Route path="/payment" render={(props) => <PaymentPage {...props} state={this.state} />} />
						<Route
							path="/bookfamilydoctor"
							render={(props) => <BookingPage {...props} state={this.state} />}
						/>

						<Route
							path="/newbookinpatient"
							render={(props) => <NewFamily {...props} state={this.state} />}
						/>
						<Route
							path="/newwalkinpatient"
							render={(props) => <NewWalkIn {...props} state={this.state} />}
						/>
						<Route
							path="/oldbookinpatient"
							render={(props) => <OldFamily {...props} state={this.state} />}
						/>
						<Route
							path="/oldwalkinpatient"
							render={(props) => <OldWalkIn {...props} state={this.state} />}
						/>
						<Route
							path="/newvirtualpatient"
							render={(props) => <NewVirtualPatient {...props} state={this.state} />}
						/>
						<Route
							path="/oldvirtualpatient"
							render={(props) => <OldVirtualPatient {...props} state={this.state} />}
						/>

						<Route path="/bookvirtual" render={(props) => <BookingPage {...props} state={this.state} />} />

						<Route
							path="/onlinebookinginstruction"
							render={(props) => <OnlineBookingInstruction {...props} state={this.state} />}
						/>
						<Route path="/epayment" render={(props) => <EPayment {...props} state={this.state} />} />
						<Route path="/forms" render={(props) => <FormPage {...props} state={this.state} />} />
						<Route path="/faq" render={(props) => <FAQPage {...props} state={this.state} />} />
						<Route path="/guardme" render={(props) => <GuardMe {...props} state={this.state} />} />
						<Route
							path="/privatepatient"
							render={(props) => <PrivatePatient {...props} state={this.state} />}
						/>
						<Route
							path="/uninsuredservice"
							render={(props) => <FeeUninsured {...props} state={this.state} />}
						/>
						<Route path="/testresult" render={(props) => <TestResult {...props} state={this.state} />} />
						<Route path="/referral" render={(props) => <Referal {...props} state={this.state} />} />
						<Route
							path="/presecriptionrenewal"
							render={(props) => <Renewal {...props} state={this.state} />}
						/>
						<Route
							path="/charttransfer"
							render={(props) => <ChartTransfer {...props} state={this.state} />}
						/>
						<Route path="/icbcinsurnace" render={(props) => <Insured {...props} state={this.state} />} />

						<Route path="/admin" render={(props) => <Admin {...props} state={this.state} />} />
						<Route path="/control" render={(props) => <Control {...props} state={this.state} />} />

						<Route path="/news" exact render={(props) => <AllNews {...props} state={this.state} />} />

						<Route path="/smssignup" render={(props) => <PatientSignup {...props} state={this.state} />} />
						<Route path="/smsmanage" component={SmsManage} />
						<Route
							path="/acceptingnewpatientnews"
							render={(props) => <AcceptingNewsPatientNews {...props} state={this.state} />}
						/>
						<Route
							path="/lotussmilenews"
							render={(props) => <LotusSmileNews {...props} state={this.state} />}
						/>
						<Route path="/qrcodenews" render={(props) => <QRcodeNews {...props} state={this.state} />} />
						<Route
							path="/news/:newsId"
							exact
							render={(props) => <IdNews {...props} state={this.state} />}
						/>
						<Route
							path="/booking_instruction"
							render={(props) => <BookingInstruction {...props} state={this.state} />}
						/>
					</Switch>
				</div>
			);
		} else {
			return (
				<div className="App">
					<div className="navDiv" style={{ backgroundColor: this.state.backgroundColor }}>
						<div className="navDiv__mobile">
							<Link to='/'>
								<img className="navDiv__mobile-logo" src={logo} alt="logo" />{' '}
							</Link>
							<div>
								<h4>J-Von Medical Center</h4>
								<section className='nav-mobile-section'>
									{' '}
									<img src={phoneLogo} alt="phone" /> {'	'}
									<a href="tel:+17783798299" className='nav-mobile-section-atag'>(778)-379-8299</a>
								</section>
							</div>
							<img className="navDiv__mobile-menu" src={MenuIcon} alt="menu" onClick={this.showMenu} />
						</div>
						<div className="navDiv__navBar">
							<div className="navLeft">
								<Link to='/'>
									<img src={logo} alt="logo" className='jvonLogo' />
								</Link>
								<div className="navDiv__navBar-title">
									<h5>J-Von Medical Center</h5>
									<section>
										{' '}
										<img src={phoneLogo} alt="phone" /> {'	'}
										<a href="tel:+17783798299">(778)-379-8299</a>
									</section>
								</div>
							</div>

							<div className='navRight'>
								<div className="navDiv__navBar__links">
									<Link className="navDiv__navBar__links-link" to="/">
										主页
									</Link>
									<Link className="navDiv__navBar__links-link" to="/theclinic">
										关于诊所{' '}
									</Link>
									<Link className="navDiv__navBar__links-link" to="/schedule">
										医生工作时间
									</Link>
									<Link className="navDiv__navBar__links-link" to="/contact">
										资源
									</Link>
									<Link className="navDiv__navBar__links-link" to="/booking_instruction">
										预约说明
									</Link>
								</div>
								<button onClick={this.changeLanguage} className="navDiv__navBar-button">
									English
								</button>
							</div>
						</div>
					</div>
					<Switch>
						<Route path="/" exact render={(props) => <MainBody {...props} state={this.state} />} />
						<Route path="/theclinic" render={(props) => <TheClinicPage {...props} state={this.state} />} />
						<Route path="/schedule" render={(props) => <SchedulePage {...props} state={this.state} />} />
						<Route path="/contact" render={(props) => <ContactPage {...props} state={this.state} />} />
						<Route path="/payment" render={(props) => <PaymentPage {...props} state={this.state} />} />
						<Route
							path="/bookfamilydoctor"
							render={(props) => <BookingPage {...props} state={this.state} />}
						/>

						<Route
							path="/newbookinpatient"
							render={(props) => <NewFamily {...props} state={this.state} />}
						/>
						<Route
							path="/newwalkinpatient"
							render={(props) => <NewWalkIn {...props} state={this.state} />}
						/>
						<Route
							path="/oldbookinpatient"
							render={(props) => <OldFamily {...props} state={this.state} />}
						/>
						<Route
							path="/oldwalkinpatient"
							render={(props) => <OldWalkIn {...props} state={this.state} />}
						/>
						<Route
							path="/newvirtualpatient"
							render={(props) => <NewVirtualPatient {...props} state={this.state} />}
						/>
						<Route
							path="/oldvirtualpatient"
							render={(props) => <OldVirtualPatient {...props} state={this.state} />}
						/>

						<Route path="/bookvirtual" render={(props) => <BookingPage {...props} state={this.state} />} />

						<Route
							path="/onlinebookinginstruction"
							render={(props) => <OnlineBookingInstruction {...props} state={this.state} />}
						/>
						<Route
							path="/booking_instruction"
							render={(props) => <BookingInstruction {...props} state={this.state} />}
						/>
						<Route path="/epayment" render={(props) => <EPayment {...props} state={this.state} />} />
						<Route path="/forms" render={(props) => <FormPage {...props} state={this.state} />} />
						<Route path="/faq" render={(props) => <FAQPage {...props} state={this.state} />} />
						<Route path="/guardme" render={(props) => <GuardMe {...props} state={this.state} />} />
						<Route
							path="/privatepatient"
							render={(props) => <PrivatePatient {...props} state={this.state} />}
						/>
						<Route
							path="/uninsuredservice"
							render={(props) => <FeeUninsured {...props} state={this.state} />}
						/>
						<Route path="/testresult" render={(props) => <TestResult {...props} state={this.state} />} />
						<Route path="/referral" render={(props) => <Referal {...props} state={this.state} />} />
						<Route
							path="/presecriptionrenewal"
							render={(props) => <Renewal {...props} state={this.state} />}
						/>
						<Route
							path="/charttransfer"
							render={(props) => <ChartTransfer {...props} state={this.state} />}
						/>
						<Route path="/icbcinsurnace" render={(props) => <Insured {...props} state={this.state} />} />

						<Route path="/admin" render={(props) => <Admin {...props} state={this.state} />} />
						<Route path="/control" render={(props) => <Control {...props} state={this.state} />} />

						<Route path="/news" exact render={(props) => <AllNews {...props} state={this.state} />} />

						<Route path="/smssignup" render={(props) => <PatientSignup {...props} state={this.state} />} />
						<Route path="/smsmanage" component={SmsManage} />

						<Route
							path="/acceptingnewpatientnews"
							render={(props) => <AcceptingNewsPatientNews {...props} state={this.state} />}
						/>
						<Route
							path="/lotussmilenews"
							render={(props) => <LotusSmileNews {...props} state={this.state} />}
						/>
						<Route path="/qrcodenews" render={(props) => <QRcodeNews {...props} state={this.state} />} />
						<Route
							path="/news/:newsId"
							exact
							render={(props) => <IdNews {...props} state={this.state} />}
						/>
					</Switch>
				</div>
			);
		}
	}
}
