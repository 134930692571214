import React, { Component } from 'react';
// import { Link } from 'react-router-dom';

// import BackButton from '../../assets/icons/arrow-left-circle.svg';
import Dr_Miao_form from '../../assets/downloadFile/New Patients Form Dr.Miao.pdf';

export default class Forms extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		console.log(this.props);
		if (this.props.state.currentLanguage === 'english') {
			return (
				<div className="formsDiv">
					<h1 className="formsDiv-header">Forms</h1>
					<div className="formsDiv__linkDiv">
						<a
							className="formsDiv__linkDiv-link"
							href="https://form.jotform.com/LotusJvon/jm-pa-npr"
							target="_blank"
							rel="noopener noreferrer"
						>
							<h4> New Patient Registration & Form Clinic Policy Agreement</h4>
						</a>
						{/* <a
							className="formsDiv__linkDiv-link"
							href="https://form.jotform.com/LotusJvon/jm-wirf"
							target="_blank"
							rel="noopener noreferrer"
						>
							<h4> Walk in Registration Form</h4>
						</a> */}
						<a
							className="formsDiv__linkDiv-link"
							href="https://form.jotform.com/LotusJvon/glajafpipp"
							target="_blank"
							rel="noopener noreferrer"
						>
							<h4>Goverment Law and Jurisdiction Agreement for Physician in Private Practice</h4>
						</a>
						{/* <a
							className="formsDiv__linkDiv-link"
							href={Dr_Miao_form}
							target="_blank"
							rel="noopener noreferrer"
						>
							<h4>Dr. Miao New Patients Form</h4>
						</a> */}
						{/* <a
							className="formsDiv__linkDiv-link"
							href="https://form.jotform.com/LotusJvon/romr"
							target="_blank"
							rel="noopener noreferrer"
						>
							<h4>Release of Medical Records from Medical Orgnization</h4>
						</a>
						<a
							className="formsDiv__linkDiv-link"
							href="https://form.jotform.com/LotusJvon/tomr"
							target="_blank"
							rel="noopener noreferrer"
						>
							<h4>Transfer of medical Records from Another Clinic</h4>
						</a> */}
					</div>
				</div>
			);
		} else {
			return (
				<div className="formsDiv">
					<h1 className="formsDiv-header">表格</h1>
					<div className="formsDiv__linkDiv">
						<a
							className="formsDiv__linkDiv-link"
							href="https://form.jotform.com/LotusJvon/jm-pa-npr"
							target="_blank"
							rel="noopener noreferrer"
						>
							<h4>新病人注册 & 诊所条款同意书 </h4>
						</a>
						{/* <a
							className="formsDiv__linkDiv-link"
							href="https://form.jotform.com/LotusJvon/jm-wirf"
							target="_blank"
							rel="noopener noreferrer"
						>
							<h4> 临时预约/无家庭医生看病表格</h4>
						</a> */}
						<a
							className="formsDiv__linkDiv-link"
							href="https://form.jotform.com/LotusJvon/glajafpipp"
							target="_blank"
							rel="noopener noreferrer"
						>
							<h4>海外病人/自费病人填写表格</h4>
						</a>
						{/* <a
							className="formsDiv__linkDiv-link"
							href={Dr_Miao_form}
							target="_blank"
							rel="noopener noreferrer"
						>
							<h4>缪医生新病人表格</h4>
						</a> */}
						{/* <a
							className="formsDiv__linkDiv-link"
							href="https://form.jotform.com/LotusJvon/romr"
							target="_blank"
							rel="noopener noreferrer"
						>
							<h4>医院/专科报告单申请</h4>
						</a>
						<a
							className="formsDiv__linkDiv-link"
							href="https://form.jotform.com/LotusJvon/tomr"
							target="_blank"
							rel="noopener noreferrer"
						>
							<h4>转诊表格/转入我们诊所</h4>
						</a> */}
					</div>
				</div>
			);
		}
	}
}
