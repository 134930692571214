import React, { Component } from 'react';
import axios from 'axios';

export default class AdminControlPage extends Component {
	submitEnglish = () => {
		axios
			.post('/englishNews', {
				title: this.englishTitle.value,
				description: this.englishDescription.value,
				chineseTitle: this.chineseTitle,
				chineseDescription: this.chineseDescription
			})
			.then((res) => {
				console.log('posted');
				this.englishTitle.value = '';
				this.englisheDscription.value = '';
				this.chineseTitle.value = '';
				this.chineseDescription.value = '';
			})
			.catch((err) => {
				console.log(err);
			});
	};
	submitChinese = () => {
		axios
			.post('http://localhost:5000/chineseNews', {
				title: this.chineseTitle.value,
				description: this.chineseDescription.value
			})
			.then((res) => {
				console.log('posted');
				this.chineseTitle.value = '';
				this.chineseDescription.value = '';
			});
	};

	render() {
		let checkToken = JSON.parse(localStorage.getItem('token'));
		if (checkToken === null || checkToken === undefined) {
			window.location.href = 'http://localhost:3000/admin';
		} else {
			return (
				<div className="adminControl">
					<div className="adminControl__englishDiv">
						<h3 className="adminControl__englishDiv-header">posting a english news</h3>
						<div className="adminControl__englishDiv-Inner">
							<input type="text" placeholder="Title" ref={(ref) => (this.englishTitle = ref)} />
							{/* <input type="text" placeholder="description" ref={(ref) => (this.description = ref)} /> */}
							<textarea
								placeholder="Description"
								ref={(ref) => (this.englishDescription = ref)}
								rows="4"
								cols="50"
							/>
						</div>
					</div>
					<div className="adminControl__chineseDiv">
						<h3 className="adminControl__chineseDiv-header">posting a chinese news</h3>
						<div className="adminControl__chineseDiv-Inner">
							<input type="text" placeholder="Title" ref={(ref) => (this.chineseTitle = ref)} />
							<textarea
								placeholder="Description"
								ref={(ref) => (this.chineseDescription = ref)}
								rows="4"
								cols="50"
							/>
							<button className="login-btn" type="button" onClick={this.submitEnglish}>
								Submit
							</button>
						</div>
					</div>
				</div>
			);
		}
	}
}
