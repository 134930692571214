import React, { Component } from "react";

import ClinicServiceSection from "./clinicComponents/ClinicService";
import ClinicDoctorSection from "./clinicComponents/ClinicDoctor";
import ClinicPolicySection from "./clinicComponents/ClinicPolicy";
import ClinicWalkInPolicySection from "./clinicComponents/ClinicWalkInPolicy";
import ClinicVirtualSection from "./clinicComponents/ClinicVirutual";
import ClinicFeeSection from "./clinicComponents/ClinicFee";
import ClinicCovid19 from "./clinicComponents/ClinicCovid19";
import ClinicQuestionair from "./clinicComponents/ClinicQuestionair";
import FaqSection from "./clinicComponents/Faq";
import menuButton from "../assets/icons/menu.svg";
import closeButton from "../assets/icons/x-square.svg";

export default class TheClinicPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayService: "none",
      displayDoctor: "none",
      displayJoin: "block",
      displayFaq: "none",
      displayPolicy: "none",
      displayVirtual: "none",
      displayFee: "none",
      displayCovid: "none",
      displayQuestionair: "none",
      displayMobile: "none",
      serviceClass: "clinicPage__Left-highlightButton",
      doctorClass: "clinicPage__Left-normalButton",
      joinClass: "clinicPage__Left-normalButton",
      faqClass: "clinicPage__Left-normalButton",
      policyClass: "clinicPage__Left-normalButton",
      virtualClass: "clinicPage__Left-normalButton",
      feeClass: "clinicPage__Left-normalButton",
      covidClass: "clinicPage__Left-normalButton",
      questionairClass: "clinicPage__Left-normalButton"
    };
  }

  serviceClicked = () => {
    this.setState({
      displayService: "block",
      displayDoctor: "none",
      displayJoin: "none",
      displayFaq: "none",
      displayPolicy: "none",
      displayVirtual: "none",
      displayFee: "none",
      displayCovid: "none",
      displayQuestionair: "none",
      displayMobile: "none",
      serviceClass: "clinicPage__Left-highlightButton",
      doctorClass: "clinicPage__Left-normalButton",
      joinClass: "clinicPage__Left-normalButton",
      faqClass: "clinicPage__Left-normalButton",
      policyClass: "clinicPage__Left-normalButton",
      virtualClass: "clinicPage__Left-normalButton",
      feeClass: "clinicPage__Left-normalButton",
      covidClass: "clinicPage__Left-normalButton",
      questionairClass: "clinicPage__Left-normalButton"
    });
  };

  doctorClicked = () => {
    this.setState({
      displayService: "none",
      displayDoctor: "block",
      displayJoin: "none",
      displayFaq: "none",
      displayPolicy: "none",
      displayVirtual: "none",
      displayFee: "none",
      displayCovid: "none",
      displayQuestionair: "none",
      displayMobile: "none",
      serviceClass: "clinicPage__Left-normalButton",
      doctorClass: "clinicPage__Left-highlightButton",
      joinClass: "clinicPage__Left-normalButton",
      faqClass: "clinicPage__Left-normalButton",
      policyClass: "clinicPage__Left-normalButton",
      virtualClass: "clinicPage__Left-normalButton",
      feeClass: "clinicPage__Left-normalButton",
      covidClass: "clinicPage__Left-normalButton",
      questionairClass: "clinicPage__Left-normalButton"
    });
  };
  joinClicked = () => {
    this.setState({
      displayService: "none",
      displayDoctor: "none",
      displayJoin: "block",
      displayFaq: "none",
      displayPolicy: "none",
      displayVirtual: "none",
      displayFee: "none",
      displayCovid: "none",
      displayQuestionair: "none",
      displayMobile: "none",
      serviceClass: "clinicPage__Left-normalButton",
      doctorClass: "clinicPage__Left-normalButton",
      joinClass: "clinicPage__Left-highlightButton",
      faqClass: "clinicPage__Left-normalButton",
      policyClass: "clinicPage__Left-normalButton",
      virtualClass: "clinicPage__Left-normalButton",
      feeClass: "clinicPage__Left-normalButton",
      covidClass: "clinicPage__Left-normalButton",
      questionairClass: "clinicPage__Left-normalButton"
    });
  };
  faqClicked = () => {
    this.setState({
      displayService: "none",
      displayDoctor: "none",
      displayJoin: "none",
      displayFaq: "block",
      displayPolicy: "none",
      displayVirtual: "none",
      displayFee: "none",
      displayCovid: "none",
      displayQuestionair: "none",
      displayMobile: "none",
      serviceClass: "clinicPage__Left-normalButton",
      doctorClass: "clinicPage__Left-normalButton",
      joinClass: "clinicPage__Left-normalButton",
      faqClass: "clinicPage__Left-highlightButton",
      policyClass: "clinicPage__Left-normalButton",
      virtualClass: "clinicPage__Left-normalButton",
      feeClass: "clinicPage__Left-normalButton",
      covidClass: "clinicPage__Left-normalButton",
      questionairClass: "clinicPage__Left-normalButton"
    });
  };
  policyClicked = () => {
    this.setState({
      displayService: "none",
      displayDoctor: "none",
      displayJoin: "none",
      displayFaq: "none",
      displayPolicy: "block",
      displayVirtual: "none",
      displayFee: "none",
      displayCovid: "none",
      displayQuestionair: "none",
      displayMobile: "none",
      serviceClass: "clinicPage__Left-normalButton",
      policyClass: "clinicPage__Left-highlightButton",
      joinClass: "clinicPage__Left-normalButton",
      faqClass: "clinicPage__Left-normalButton",
      doctorClass: "clinicPage__Left-normalButton",
      virtualClass: "clinicPage__Left-normalButton",
      feeClass: "clinicPage__Left-normalButton",
      covidClass: "clinicPage__Left-normalButton",
      questionairClass: "clinicPage__Left-normalButton"
    });
  };
  virtualClicked = () => {
    this.setState({
      displayService: "none",
      displayDoctor: "none",
      displayJoin: "none",
      displayFaq: "none",
      displayPolicy: "none",
      displayVirtual: "block",
      displayFee: "none",
      displayCovid: "none",
      displayQuestionair: "none",
      displayMobile: "none",
      serviceClass: "clinicPage__Left-normalButton",
      virtualClass: "clinicPage__Left-highlightButton",
      joinClass: "clinicPage__Left-normalButton",
      faqClass: "clinicPage__Left-normalButton",
      policyClass: "clinicPage__Left-normalButton",
      doctorlClass: "clinicPage__Left-normalButton",
      feeClass: "clinicPage__Left-normalButton",
      covidClass: "clinicPage__Left-normalButton",
      questionairClass: "clinicPage__Left-normalButton"
    });
  };
  feeClicked = () => {
    this.setState({
      displayService: "none",
      displayDoctor: "none",
      displayJoin: "none",
      displayFaq: "none",
      displayPolicy: "none",
      displayVirtual: "none",
      displayFee: "block",
      displayCovid: "none",
      displayQuestionair: "none",
      displayMobile: "none",
      serviceClass: "clinicPage__Left-normalButton",
      feeClass: "clinicPage__Left-highlightButton",
      joinClass: "clinicPage__Left-normalButton",
      faqClass: "clinicPage__Left-normalButton",
      policyClass: "clinicPage__Left-normalButton",
      virtualClass: "clinicPage__Left-normalButton",
      doctorClass: "clinicPage__Left-normalButton",
      covidClass: "clinicPage__Left-normalButton",
      questionairClass: "clinicPage__Left-normalButton"
    });
  };
  covidClicked = () => {
    this.setState({
      displayService: "none",
      displayDoctor: "none",
      displayJoin: "none",
      displayFaq: "none",
      displayPolicy: "none",
      displayVirtual: "none",
      displayFee: "none",
      displayCovid: "block",
      displayQuestionair: "none",
      displayMobile: "none",
      serviceClass: "clinicPage__Left-normalButton",
      covidClass: "clinicPage__Left-highlightButton",
      joinClass: "clinicPage__Left-normalButton",
      faqClass: "clinicPage__Left-normalButton",
      policyClass: "clinicPage__Left-normalButton",
      virtualClass: "clinicPage__Left-normalButton",
      doctorClass: "clinicPage__Left-normalButton",
      feeClass: "clinicPage__Left-normalButton",
      questionairClass: "clinicPage__Left-normalButton"
    });
  };

  questionairClicked = () => {
    this.setState({
      displayService: "none",
      displayDoctor: "none",
      displayJoin: "none",
      displayFaq: "none",
      displayPolicy: "none",
      displayVirtual: "none",
      displayFee: "none",
      displayCovid: "none",
      displayQuestionair: "block",
      displayMobile: "none",
      serviceClass: "clinicPage__Left-normalButton",
      covidClass: "clinicPage__Left-normalButton",
      joinClass: "clinicPage__Left-normalButton",
      faqClass: "clinicPage__Left-normalButton",
      policyClass: "clinicPage__Left-normalButton",
      virtualClass: "clinicPage__Left-normalButton",
      doctorClass: "clinicPage__Left-normalButton",
      feeClass: "clinicPage__Left-normalButton",
      questionairClass: "clinicPage__Left-highlightButton"
    });
  };

  menuClicked = () => {
    if (this.state.displayMobile === "none") {
      this.setState({
        displayMobile: "flex",
      });
    } else {
      this.setState({
        displayMobile: "none",
      });
    }
  };

  render() {
    if (this.props.state.currentLanguage === "english") {
      return (
        <div className="clinicPage">
          <div className="clinicPage__Center">
            <div className="clinicPage__Left">
              <button
                onClick={this.joinClicked}
                className={this.state.joinClass}
              >
                Clinic Policy
              </button>
              <button
                onClick={this.faqClicked}
                className={this.state.faqClass}
              >
                FAQ
              </button>
              <button
                onClick={this.virtualClicked}
                className={this.state.virtualClass}
              >
                Virtual Visit Policy
              </button>
              <button onClick={this.feeClicked} className={this.state.feeClass}>
                Uninsured Services
              </button>
              <button
                onClick={this.covidClicked}
                className={this.state.covidClass}
              >
                Resources
              </button>
            </div>
            <div className="clinicPage__mobile">
              <img src={menuButton} alt="menu" onClick={this.menuClicked} />
              <div
                style={{ display: this.state.displayMobile }}
                className="clinicPage__mobile__shadow"
              >
                <div className="clinicPage__mobile__shadow-inner">
                  <img
                    src={closeButton}
                    alt="close"
                    onClick={this.menuClicked}
                  />

                  <button onClick={this.serviceClicked}>Our Service</button>
                  <button onClick={this.joinClicked}>Clinic Policy</button>
                  <button onClick={this.faqClicked}>FAQ</button>
                  <button onClick={this.virtualClicked}>
                    Virtual Visit Policy
                  </button>
                  <button onClick={this.feeClicked}>Uninsured Services</button>
                  <button onClick={this.covidClicked}>Resources</button>
                </div>
              </div>
            </div>

            <div
              className="clinicPage__Right"
              style={{ display: this.state.displayService }}
            >
              <ClinicServiceSection props={this.props.state} />
            </div>
            <div
              className="clinicPage__Right"
              style={{ display: this.state.displayDoctor }}
            >
              <ClinicDoctorSection props={this.props.state} />
            </div>
            <div
              className="clinicPage__Right"
              style={{ display: this.state.displayJoin }}
            >
              <ClinicPolicySection props={this.props.state} />
            </div>
            <div
              className="clinicPage__Right"
              style={{ display: this.state.displayFaq }}
            >
              <FaqSection props={this.props.state} />
            </div>
            <div
              className="clinicPage__Right"
              style={{ display: this.state.displayPolicy }}
            >
              <ClinicWalkInPolicySection props={this.props.state} />
            </div>
            <div
              className="clinicPage__Right"
              style={{ display: this.state.displayVirtual }}
            >
              <ClinicVirtualSection props={this.props.state} />
            </div>
            <div
              className="clinicPage__Right"
              style={{ display: this.state.displayFee }}
            >
              <ClinicFeeSection props={this.props.state} />
            </div>
            <div
              className="clinicPage__Right"
              style={{ display: this.state.displayCovid }}
            >
              <ClinicCovid19 props={this.props.state} />
            </div>
            <div
              className="clinicPage__Right"
              style={{ display: this.state.displayQuestionair }}
            >
              <ClinicQuestionair props={this.props.state} />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="clinicPage">
          <div className="clinicPage__Center">
            <div className="clinicPage__Left">
              <button
                onClick={this.joinClicked}
                className={this.state.joinClass}
              >
                诊所政策
              </button>
              <button
                onClick={this.faqClicked}
                className={this.state.faqClass}
              >
                常见问题答案
              </button>
              <button
                onClick={this.virtualClicked}
                className={this.state.virtualClass}
              >
                网络远程看病规定
              </button>
              <button onClick={this.feeClicked} className={this.state.feeClass}>
                MSP不包括费用
              </button>
              <button
                onClick={this.covidClicked}
                className={this.state.covidClass}
              >
                资源
              </button>
            </div>

            <div className="clinicPage__mobile">
              <img src={menuButton} alt="menu" onClick={this.menuClicked} />
              <div
                style={{ display: this.state.displayMobile }}
                className="clinicPage__mobile__shadow"
              >
                <div className="clinicPage__mobile__shadow-inner">
                  <img
                    src={closeButton}
                    alt="close"
                    onClick={this.menuClicked}
                  />

                  {/* <button onClick={this.serviceClicked}>Our Service</button> */}
                  <button onClick={this.joinClicked}>诊所政策</button>
                  <button onClick={this.faqClicked}>   常见问题答案</button>
                  <button onClick={this.virtualClicked}>
                    网络远程看病规定
                  </button>
                  <button onClick={this.feeClicked}>MSP不包括费用</button>
                  <button onClick={this.covidClicked}>资源</button>
                </div>
              </div>
            </div>

            <div
              className="clinicPage__Right"
              style={{ display: this.state.displayService }}
            >
              <ClinicServiceSection props={this.props.state} />
            </div>
            <div
              className="clinicPage__Right"
              style={{ display: this.state.displayDoctor }}
            >
              <ClinicDoctorSection props={this.props.state} />
            </div>
            <div
              className="clinicPage__Right"
              style={{ display: this.state.displayJoin }}
            >
              <ClinicPolicySection props={this.props.state} />
            </div>
            <div
              className="clinicPage__Right"
              style={{ display: this.state.displayFaq }}
            >
              <FaqSection props={this.props.state} />
            </div>
            <div
              className="clinicPage__Right"
              style={{ display: this.state.displayPolicy }}
            >
              <ClinicWalkInPolicySection props={this.props.state} />
            </div>
            <div
              className="clinicPage__Right"
              style={{ display: this.state.displayVirtual }}
            >
              <ClinicVirtualSection props={this.props.state} />
            </div>
            <div
              className="clinicPage__Right"
              style={{ display: this.state.displayFee }}
            >
              <ClinicFeeSection props={this.props.state} />
            </div>
            <div
              className="clinicPage__Right"
              style={{ display: this.state.displayCovid }}
            >
              <ClinicCovid19 props={this.props.state} />
            </div>
            <div
              className="clinicPage__Right"
              style={{ display: this.state.displayQuestionair }}
            >
              <ClinicQuestionair props={this.props.state} />
            </div>
          </div>
        </div>
      );
    }
  }
}
