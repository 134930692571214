import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import BackButton from '../../assets/icons/arrow-left-thick.svg';

export default class VirtualPatient extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		if (this.props.state.currentLanguage === 'english') {
			return (
				<div className="virtualBooking">
					<div className="virtualBooking-center">
						<h2>Thank you for considering virtual appointments</h2>

						<h3>
							During this COVID-19 pandemic, we encourge virtual appointments, for the safety of you and
							us.
						</h3>
						<h3>
							Please click {' '}
							<a
								// className="formsDiv__linkDiv-link"
								href="https://jvonebooking.mpeer.net:8881/appointment/"
								target="_blank"
								rel="noopener noreferrer"
							>
								{' '}
								Here{' '}
							</a>{' '}
							to make an appointment virtually.
						</h3>
					</div>
					<Link className="backButton" to="/bookvirtual">
						<img src={BackButton} alt="backButton" />Go Back
					</Link>
				</div>
			);
		} else {
			return (
				<div className="virtualBooking">
					<Link className="backButton" to="/bookvirtual">
						<img src={BackButton} alt="backButton" />
					</Link>
					<div className="virtualBooking-center">
						<h2>感谢您考虑预约网络远程看病</h2>

						<h3>在这次COVID-19期间，我们鼓励网上远程看病，以确保您和我们的安全。</h3>
						<h3>
							请点击 {' '}
							<a
								// className="formsDiv__linkDiv-link"
								href="https://jvonebooking.mpeer.net:8881/appointment/"
								target="_blank"
								rel="noopener noreferrer"
							>
								{' '}
								这里{' '}
							</a>{' '}
							去预约网络远程看病
						</h3>
					</div>
					<Link className="backButton" to="/bookvirtual">
						<img src={BackButton} alt="backButton" /> 返回
					</Link>
				</div>
			);
		}
	}
}
