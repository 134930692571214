import React, { Component } from 'react';

export default class ContactPage extends Component {
	static defaultProps = {
		center: {
			lat: 49.22522,
			lng: -123.0035
		},
		zoom: 11
	};
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		if (this.props.state.currentLanguage === 'english') {
			return (
				<div className="resourcePage">
					<div className='resourcePage-container'>
						<h2>
							Xray and Ultrasound
						</h2>

						<a href="https://www.brookeimaging.ca/about.php" target="_blank"
							rel="noopener noreferrer">Brooke</a> <br />


						<a href="https://www.westcoastmedicalimaging.com/location-list" target="_blank"
							rel="noopener noreferrer">West Coast Medical Imaging</a><br /><br />
					</div>

					<div className='resourcePage-container'>
						<h2>Lab Tests and Results</h2>

						<a href="https://www.lifelabs.com/" target="_blank"
							rel="noopener noreferrer">LifeLabs</a><br /><br />
					</div>

					<div className='resourcePage-container'>
						<h2>
							Emergency
						</h2>

						<a href="https://www.fraserhealth.ca/Service-Directory/Service-At-Location/D/7/metrotown-urgent-and-primary-care-centre" target="_blank"
							rel="noopener noreferrer">Metrotown Urgent and Primary Care Centre</a><br />

						<a href="https://www.fraserhealth.ca/Service-Directory/Locations/Burnaby/burnaby-hospital#.X85wSchKhaQ" target="_blank"
							rel="noopener noreferrer">
							Burnaby Hospital
						</a>
					</div>
				</div>
			);
		} else {
			return (
				<div className="resourcePage">
					<div className='resourcePage-container'>
						<h2>
							X光片和超声波
						</h2>

						<a href="https://www.brookeimaging.ca/about.php" target="_blank"
							rel="noopener noreferrer">Brooke</a> <br />


						<a href="https://www.westcoastmedicalimaging.com/location-list" target="_blank"
							rel="noopener noreferrer">West Coast Medical Imaging</a><br /><br />
					</div>

					<div className='resourcePage-container'>
						<h2>实验室测试和结果</h2>

						<a href="https://www.lifelabs.com/" target="_blank"
							rel="noopener noreferrer">LifeLabs</a><br /><br />
					</div>

					<div className='resourcePage-container'>
						<h2>
							急救中心
						</h2>

						<a href="https://www.fraserhealth.ca/Service-Directory/Service-At-Location/D/7/metrotown-urgent-and-primary-care-centre" target="_blank"
							rel="noopener noreferrer">Metrotown Urgent and Primary Care Centre</a><br />

						<a href="https://www.fraserhealth.ca/Service-Directory/Locations/Burnaby/burnaby-hospital#.X85wSchKhaQ" target="_blank"
							rel="noopener noreferrer">
							Burnaby Hospital
						</a>
					</div>
				</div>
			);
		}
	}
}
