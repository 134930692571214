import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import BackButton from '../../assets/icons/arrow-left-circle.svg';

export default class Uninsured extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		if (this.props.state.currentLanguage === 'english') {
			return (
				<div className="uninsured">
					<Link className="backButton" to="/">
						<img src={BackButton} alt="backButton" />
					</Link>
					<h3 className="uninsured-header">Uninsured Services Below:</h3>
					<h4 className="uninsured-basedOn">
						This table is based on Doctors of BC fee guide, our clinic's charge on most of the fees are
						lower than what is shown, only a few fee charge is slightly higher.
					</h4>
					<table className="uninsured__table">
						<thead className="uninsured__table-head">
							<tr className="uninsured__table-row">
								<th className="uninsured__table-name">Forms</th>
								<th className="uninsured__table-price">Fees</th>
							</tr>
						</thead>
						<tbody>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									Insurance Company (disability/travel/time off) - short form (A00069)
								</td>
								<td className="uninsured__table-price">$146.00</td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									Insurance Company (disability/travel/time off) - long form (A00059){' '}
								</td>
								<td className="uninsured__table-price">$192.00</td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">Income Tax Disability - short form (A00069) </td>
								<td className="uninsured__table-price">$146.00</td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name"> Income Tax Disability - long form (A00059) </td>
								<td className="uninsured__table-price">$192.00 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									Special Authority Form Completion - billable only when requested by 3rd party
									insurer (A94523)
								</td>
								<td className="uninsured__table-price"> $74.30</td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name"> School/Work time off (A00060)</td>
								<td className="uninsured__table-price">$43.05 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">Government UIC form (A00060)</td>
								<td className="uninsured__table-price">$43.05 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">Parking form (A00060)</td>
								<td className="uninsured__table-price">$43.05 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">Handi-dart Application (A00060) </td>
								<td className="uninsured__table-price">$43.05 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									Employer's Occupational Fitness Assessment form – extra to examination (A94529){' '}
								</td>
								<td className="uninsured__table-price">$167.00 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									Nursing Home Registration / Administration (A00063)
								</td>
								<td className="uninsured__table-price">$150.00 </td>
							</tr>
						</tbody>
						<thead className="uninsured__table-head">
							<tr className="uninsured__table-row">
								<th className="uninsured__table-name">Reports / Letters</th>
								<th className="uninsured__table-price" />
							</tr>
						</thead>
						<tbody>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									Insurance Company typed letter - short (½ page), Typed off work letter (A00070){' '}
								</td>
								<td className="uninsured__table-price">$167.00</td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									Insurance Company typed letter - long (1 - 2 page) (A00071){' '}
								</td>
								<td className="uninsured__table-price">$352.00</td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name"> Handwritten note / letter (A00060) </td>
								<td className="uninsured__table-price">$43.00</td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name"> Medical Advice by letter (A00061) </td>
								<td className="uninsured__table-price">$146.00 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">Medical - legal letter (A00071)</td>
								<td className="uninsured__table-price"> $352.00 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name"> □ Medical - legal report (A00072)</td>
								<td className="uninsured__table-price">$1053.00 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">Medical - legal opinion (A00073)</td>
								<td className="uninsured__table-price">$1761.00 </td>
							</tr>
						</tbody>
						<thead className="uninsured__table-head">
							<tr className="uninsured__table-row">
								<th className="uninsured__table-name">Examinations</th>
								<th className="uninsured__table-price" />
							</tr>
						</thead>
						<tbody>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									Complete examination (not covered by MSP for healthy persons)(00101)
								</td>
								<td className="uninsured__table-price">$163.00</td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">Driver's License - limited exam (A00056) </td>
								<td className="uninsured__table-price">$90.40</td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name"> Driver's License - full exam (A00055) </td>
								<td className="uninsured__table-price">$201.00</td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									{' '}
									Driver’s Medical Examination Report – DMER (96220){' '}
								</td>
								<td className="uninsured__table-price">$201.00 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									Diabetic Driver Report – stand-alone – No DMER (96221)
								</td>
								<td className="uninsured__table-price"> $201.00 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									{' '}
									□ Diabetic Driver Report in addition to DMER – combined fee (96222+96220){' '}
								</td>
								<td className="uninsured__table-price">$251.40 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									Participatory Fitness for Sports Team / Camp (A00068)
								</td>
								<td className="uninsured__table-price">$72.60 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name"> Industrial First Aid - limited exam (A00002)</td>
								<td className="uninsured__table-price">$146.00 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">CPP / MOT / Insurance Co. (A00001)</td>
								<td className="uninsured__table-price">$207.00 </td>
							</tr>
						</tbody>
						<thead className="uninsured__table-head">
							<tr className="uninsured__table-row">
								<th className="uninsured__table-name">Treatments / Surgery (Includes Tray fee)</th>
								<th className="uninsured__table-price" />
							</tr>
						</thead>
						<tbody>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">Liquid Nitrogen (00190) + (00044)</td>
								<td className="uninsured__table-price">$89.75</td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									Excision Mole (cosmetic) - facial (06069 + 00090){' '}
								</td>
								<td className="uninsured__table-price">$399.40 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									{' '}
									Excision Mole (cosmetic) - non facial (13620 + 00090){' '}
								</td>
								<td className="uninsured__table-price">$212.40 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name"> Excision Mole / Lesion - no sutures </td>
								<td className="uninsured__table-price">$149.30 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									Influenza vaccine for individuals not covered by MSP)
								</td>
								<td className="uninsured__table-price"> $33.31 </td>
							</tr>
						</tbody>
						<thead className="uninsured__table-head">
							<tr className="uninsured__table-row">
								<th className="uninsured__table-name">Other</th>
								<th className="uninsured__table-price" />
							</tr>
						</thead>
						<tbody>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">Telephone prescription renewal (A00047)</td>
								<td className="uninsured__table-price">$30.80</td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									Advice by Telephone on the establishment of a tentative diagnosis and prescribing
									treatment (when not related to previous visit) - per 15 minutes (A00049){' '}
								</td>
								<td className="uninsured__table-price">$85.90 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">Transfer of Records - basic fee (A00093) </td>
								<td className="uninsured__table-price">$35.25 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									Physician Review of Records – for transfer of Medical/Legal (per 15 min)(A00095){' '}
								</td>
								<td className="uninsured__table-price">$98.40 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									Transfer of Records - photocopying - per page (first 10 / subsequent pages) (A00096)
								</td>
								<td className="uninsured__table-price"> $1.70 / .32 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									Immunizations under 19 yrs of age – per injection (10010-10030) [office visit extra]
								</td>
								<td className="uninsured__table-price"> $12.85 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">Missed Appointment Charge</td>
								<td className="uninsured__table-price"> $78.15 </td>
							</tr>
						</tbody>
					</table>
				</div>
			);
		} else {
			return (
				<div className="uninsured">
					<Link className="backButton" to="/">
						<img src={BackButton} alt="backButton" />
					</Link>
					<h3 className="uninsured-header">此类服务项目不包括在msp service 里面，如果您看不懂可以找翻译软件翻译一下</h3>
					<h4 style={{ width: '50vw', marginBottom: 30 }}>
						该表是根据BC省医生收费指南得出的，我们诊所对大多数费用的收费低于所显示的收费，只有少数收费稍高。
					</h4>

					<table className="uninsured__table">
						<thead className="uninsured__table-head">
							<tr className="uninsured__table-row">
								<th className="uninsured__table-name">Forms</th>
								<th className="uninsured__table-price">Fees</th>
							</tr>
						</thead>
						<tbody>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									Insurance Company (disability/travel/time off) - short form (A00069)
								</td>
								<td className="uninsured__table-price">$146.00</td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									Insurance Company (disability/travel/time off) - long form (A00059){' '}
								</td>
								<td className="uninsured__table-price">$192.00</td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">Income Tax Disability - short form (A00069) </td>
								<td className="uninsured__table-price">$146.00</td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name"> Income Tax Disability - long form (A00059) </td>
								<td className="uninsured__table-price">$192.00 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									Special Authority Form Completion - billable only when requested by 3rd party
									insurer (A94523)
								</td>
								<td className="uninsured__table-price"> $74.30</td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name"> School/Work time off (A00060)</td>
								<td className="uninsured__table-price">$43.05 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">Government UIC form (A00060)</td>
								<td className="uninsured__table-price">$43.05 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">Parking form (A00060)</td>
								<td className="uninsured__table-price">$43.05 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">Handi-dart Application (A00060) </td>
								<td className="uninsured__table-price">$43.05 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									Employer's Occupational Fitness Assessment form – extra to examination (A94529){' '}
								</td>
								<td className="uninsured__table-price">$167.00 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									Nursing Home Registration / Administration (A00063)
								</td>
								<td className="uninsured__table-price">$150.00 </td>
							</tr>
						</tbody>
						<thead className="uninsured__table-head">
							<tr className="uninsured__table-row">
								<th className="uninsured__table-name">Reports / Letters</th>
								<th className="uninsured__table-price" />
							</tr>
						</thead>
						<tbody>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									Insurance Company typed letter - short (½ page), Typed off work letter (A00070){' '}
								</td>
								<td className="uninsured__table-price">$167.00</td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									Insurance Company typed letter - long (1 - 2 page) (A00071){' '}
								</td>
								<td className="uninsured__table-price">$352.00</td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name"> Handwritten note / letter (A00060) </td>
								<td className="uninsured__table-price">$43.00</td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name"> Medical Advice by letter (A00061) </td>
								<td className="uninsured__table-price">$146.00 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">Medical - legal letter (A00071)</td>
								<td className="uninsured__table-price"> $352.00 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name"> □ Medical - legal report (A00072)</td>
								<td className="uninsured__table-price">$1053.00 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">Medical - legal opinion (A00073)</td>
								<td className="uninsured__table-price">$1761.00 </td>
							</tr>
						</tbody>
						<thead className="uninsured__table-head">
							<tr className="uninsured__table-row">
								<th className="uninsured__table-name">Examinations</th>
								<th className="uninsured__table-price" />
							</tr>
						</thead>
						<tbody>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									Complete examination (not covered by MSP for healthy persons)(00101)
								</td>
								<td className="uninsured__table-price">$163.00</td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">Driver's License - limited exam (A00056) </td>
								<td className="uninsured__table-price">$90.40</td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name"> Driver's License - full exam (A00055) </td>
								<td className="uninsured__table-price">$201.00</td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									{' '}
									Driver’s Medical Examination Report – DMER (96220){' '}
								</td>
								<td className="uninsured__table-price">$201.00 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									Diabetic Driver Report – stand-alone – No DMER (96221)
								</td>
								<td className="uninsured__table-price"> $201.00 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									{' '}
									□ Diabetic Driver Report in addition to DMER – combined fee (96222+96220){' '}
								</td>
								<td className="uninsured__table-price">$251.40 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									Participatory Fitness for Sports Team / Camp (A00068)
								</td>
								<td className="uninsured__table-price">$72.60 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name"> Industrial First Aid - limited exam (A00002)</td>
								<td className="uninsured__table-price">$146.00 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">CPP / MOT / Insurance Co. (A00001)</td>
								<td className="uninsured__table-price">$207.00 </td>
							</tr>
						</tbody>
						<thead className="uninsured__table-head">
							<tr className="uninsured__table-row">
								<th className="uninsured__table-name">Treatments / Surgery (Includes Tray fee)</th>
								<th className="uninsured__table-price" />
							</tr>
						</thead>
						<tbody>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">Liquid Nitrogen (00190) + (00044)</td>
								<td className="uninsured__table-price">$89.75</td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									Excision Mole (cosmetic) - facial (06069 + 00090){' '}
								</td>
								<td className="uninsured__table-price">$399.40 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									{' '}
									Excision Mole (cosmetic) - non facial (13620 + 00090){' '}
								</td>
								<td className="uninsured__table-price">$212.40 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name"> Excision Mole / Lesion - no sutures </td>
								<td className="uninsured__table-price">$149.30 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									Influenza vaccine for individuals not covered by MSP)
								</td>
								<td className="uninsured__table-price"> $33.31 </td>
							</tr>
						</tbody>
						<thead className="uninsured__table-head">
							<tr className="uninsured__table-row">
								<th className="uninsured__table-name">Other</th>
								<th className="uninsured__table-price" />
							</tr>
						</thead>
						<tbody>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">Telephone prescription renewal (A00047)</td>
								<td className="uninsured__table-price">$30.80</td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									Advice by Telephone on the establishment of a tentative diagnosis and prescribing
									treatment (when not related to previous visit) - per 15 minutes (A00049){' '}
								</td>
								<td className="uninsured__table-price">$85.90 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">Transfer of Records - basic fee (A00093) </td>
								<td className="uninsured__table-price">$35.25 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									Physician Review of Records – for transfer of Medical/Legal (per 15 min)(A00095){' '}
								</td>
								<td className="uninsured__table-price">$98.40 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									Transfer of Records - photocopying - per page (first 10 / subsequent pages) (A00096)
								</td>
								<td className="uninsured__table-price"> $1.70 / .32 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">
									Immunizations under 19 yrs of age – per injection (10010-10030) [office visit extra]
								</td>
								<td className="uninsured__table-price"> $12.85 </td>
							</tr>
							<tr className="uninsured__table-row">
								<td className="uninsured__table-name">Missed Appointment Charge</td>
								<td className="uninsured__table-price"> $78.15 </td>
							</tr>
						</tbody>
					</table>
				</div>
			);
		}
	}
}
