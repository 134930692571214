import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Video1 from '../../assets/video/virtual.mp4';
import Video2 from '../../assets/video/virtualcn.mp4';

import BackButton from '../../assets/icons/arrow-left-thick.svg';
import ExistingPatient from '../../assets/downloadFile/Existing_Patient_How_to_Book_Online.pdf';
import NewPatient from '../../assets/downloadFile/New_Patient_Waiting_List.pdf';
import bookingInstructionDoc from '../../assets/downloadFile/bookingInstruction2024.pdf';

export default class BookingInstruction extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		if (this.props.state.currentLanguage === 'english') {
			return (
				<div className="BookingInstruction">
					<div className="BookingInstruction-virtualDiv">
						<div>
							Click <a href={bookingInstructionDoc} target="_blank" rel="noreferrer">here</a> to book online

							{/* <ol style={{ fontSize: 22, textAlign: 'left'}}>
								<li>
									<a href="/newbookinpatient" target="_blank" rel="noopener noreferrer">
									New patients-( New to the clinic) (Wanting to book telephone video walk in appointments)
									</a>	
								</li>
								<li>
									<a href={ExistingPatient} target="_blank" rel="noopener noreferrer">
									Existing patients （How to book online）
									</a>
								</li>
								<li>
									<a href={NewPatient} target="_blank" rel="noopener noreferrer">
									Waiting to become our clinic’s Patients
									</a>
								</li>
							</ol> */}
						</div>
					</div>
					{/* <Link className="backButton" to="/">
						<img src={BackButton} alt="backButton" /> Go Back
					</Link> */}
				</div>
			);
		} else {
			return (
				<div className="BookingInstruction">
					<div className="BookingInstruction-virtualDiv">
						点击 <a href={bookingInstructionDoc} target="_blank" rel="noreferrer">这里</a> 在线预约
						{/* <div>
							<h2>在线预约说明</h2>
							<ol style={{ fontSize: 22, textAlign: 'left' }}>
								<li>
									<a href="/newbookinpatient" target="_blank" rel="noopener noreferrer">
										新病人如何预约(从未来过我们诊所)
									</a>
								</li>
								<li>
									<a href={ExistingPatient} target="_blank" rel="noopener noreferrer">
										注册过的/来过我们诊所的/我们诊所的 病人如何预约
									</a>
								</li>
								<li>
									<a href={NewPatient} target="_blank" rel="noopener noreferrer">
										排队等候（想要成为我们诊所的病人）
									</a>
								</li>
							</ol>
						</div> */}
					</div>
					{/* <Link className="backButton" to="/">
						<img src={BackButton} alt="backButton" />返回
					</Link> */}
				</div>
			);
		}
	}
}
