import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import BackButton from '../../assets/icons/arrow-left-thick.svg';

export default class Refferal extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		if (this.props.state.currentLanguage === 'english') {
			return (
				<div className="referral">
					<div className="referral__center">
						<h2 className="referral__center-header">waiting for a referral</h2>
						<div>
							<ol>
								<li>How long the specialist will give me a time?</li>
								<div>
									Referrals usually doctor will help you to create on the same day. But, the respond
									from the specialist will take long time. Some of the specialist may take few months
									to get back to us with providing a time.
								</div>
								<li>When I will receive a notice from the clinic?</li>
								<div>
									Once we received a referral, we will contact you right away. So make sure your phone
									number or your email is updated. Also have a voicemail function.
								</div>
								<li>
									If I wait for already over 10 months, and specialist has not given me a time, what
									should I do?
								</li>
								<div>
									You can call specialist’s office and ask them if they can place you on the waiting
									list if anyone cancelled from their clinic. Please phone specialist office directly.
								</div>
								<li>Can I change to another specialist?</li>
								<div>You can, But you need to discuss with doctors to make this arrangement.</div>
								<li>Can doctors send me to two specialist and compare who is fast</li>
								<div>Sorry, we are unable to do it. It was not allowed.</div>
								<li>
									Any physiotherapy, massage therapy, Sleeping study, Orthopedic, Referrals, patients
									are able to contact them directly
								</li>

								<li>urgent referrals, semi-urgent referral.</li>
								<div>
									If it is emergency and we will try our best to contact the specialist office. If
									they could not accommodate, doctors will send you to the hospital emergency right
									away.
								</div>
							</ol>
						</div>
						<Link className="backButton" to="/">
							<img src={BackButton} alt="backButton" />Go Back
						</Link>
					</div>
				</div>
			);
		} else {
			return (
				<div className="referral">
					<div className="referral__center">
						<h2 className="referral__center-header">等待转介</h2>
						<div>
							<ol>
								<li>转给专家看诊会让我等候多长时间？</li>
								<div>转诊医生通常会在同一天帮助您创建。但是，专家的回应将需要很长时间。一些专家可能需要几个月的时间才能与我们联系，并提供一些时间 我们才能通知您。</div>
								<li>我何时会收到诊所的通知？ </li>
								<div>收到专科医生给我们的时间后，我们会立即与您联系。因此，请确保您的电话号码或电子邮件已更新。还具有语音信箱功能。</div>
								<li>如果我已经等待了10个月以上，而专家却没有给我时间，我该怎么办？</li>
								<div>您可以致电专科医生的办公室，询问他们是否可以将您加入等候名单中（如果他们诊所有人取消了，他们可以尽快跟您联系，请直接致电专家办公室查询。</div>
								<li>我可以换另一位专家吗？</li>
								<div>您可以，但是您需要与医生讨论以做出此安排</div>
								<li>医生能把我送到两个专科医生那里比较谁快吗</li>
								<div>抱歉，我们无法做到。这是不允许的。</div>
								<li>任何理疗，按摩推荐，睡眠研究推荐，骨科推荐转诊，患者可以直接与他们联系</li>
								<li>紧急转诊，半紧急转诊。</li>
								<div>如果紧急情况，我们将尽力与专家办公室联系。如果他们无法尽快接受，您可以和医生商量是否可以去急诊</div>
							</ol>
						</div>
						<Link className="backButton" to="/">
							<img src={BackButton} alt="backButton" />返回
						</Link>
					</div>
				</div>
			);
		}
	}
}
