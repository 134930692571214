import React, { Component } from 'react';

export default class ClinicPolicy extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		if (this.props.props.currentLanguage === 'english') {
			return (
				<div className="clinicWalkInDiv">
					<h2 className="clinicWalkInDiv-policyHeader">Clinic Policy</h2>
					<div className="clinicWalkInDiv-policyDiv">
						<h3>I/WE have read, and agreed to the following:</h3>
						<h4>Online Booking:</h4>
						<h5>
							J-Von Medical Centre offers online booking for phone calls, virtual appointments, and in-person visits through our website. We exclusively assist senior patients in booking appointments online.
						</h5>

						<h4>Appointment Guidelines:</h4>
						<h5>
							Due to the high volume of appointments, our office recommends that patients limit discussions to 1-2 medical issues per visit. In case of emergencies, our doctors will allocate additional time for discussion. However, for non-emergency matters, doctors will prioritize and address them within the allotted time. If necessary, a follow-up visit may be suggested to ensure thorough attention to each concern.
							In our commitment to maintaining a timely schedule, we kindly request your understanding. If any delays occur, we appreciate your patience and request that you refrain from pressuring our front staff for timing adjustments. We acknowledge and value your time.

						</h5>

						<h4>You should See Your Own Doctors:</h4>
						<h5>
							Starting from 2024, our clinic will be transitioning to a system where patients will only be able to see their own family doctor. This change is reflective of the province's new medical model, which focuses on strengthening the patient-doctor relationship and improving the quality of care. As part of this commitment, please consult with your assigned family doctor for your care. Changing your family doctor within this clinic is not permitted.
						</h5>

						<h4>Cancelation Policy:</h4>
						<h5>
							No Show Charge is $60; please provide a minimum 24-hour notice. No show charge appointment needs to be paid prior to the next appointment.
						</h5>

						<h4>Services Not Covered By MSP</h4>
						<h5>
							Please note that some services offered at our clinic are not covered by the MSP and will require direct payment. For detailed information on fees for these services, please refer to our <a href="https://bcfamilydocs.ca/wp-content/uploads/2023/03/Uninsured-Services-Invoice-April-1-2023-for-LFP-Physicians.pdf" target="_blank"
								rel="noopener noreferrer">fee list</a>.
						</h5>

						<h4>Other rules:</h4>

						<h5>
							1. Translation Services:
							Our clinic does not offer translation services and cannot accept medical reports that are not in English. Patients are responsible for bringing their own translator if needed and for ensuring any non-English documents are translated into English before submission.
						</h5>
						<h5>
							2. Urgent Appointment Policy:
							Please note that our clinic does not provide urgent care appointments. If you require immediate medical attention, we advise visiting an Urgent Primary Care Centre or an emergency room. For non-urgent matters, please schedule a regular appointment with us.

						</h5>
						<h5>
							3. Reports and Prescriptions:
							Please note we do not discuss lab reports or prescribe medication without an appointment. To discuss these matters or receive your lab reports, an appointment with your doctor is required. The clinic is not authorized to release lab reports without a prior discussion with your doctor.
							(We do not prescribe narcotics or benzodiazepines.)

						</h5>

						<h5>
							4. Contact Information:
							Ensure your phone has voicemail. If not, provide a secondary contact number and email. This is crucial for communication about your care and for specialist offices or other medical facilities to contact you directly. Update us with any changes in your contact details.
						</h5>

						<h5>
							5. Child Supervision:
							While in the waiting and patient rooms, please supervise your children. Their safety is your responsibility.

						</h5>

						<h5>
							6. Cell Phone Use:
							Please refrain from using your cell phone in the reception area and patient rooms.

						</h5>
						<h5>
							7. Emergency Hospital Visits:
							If visiting a hospital for an emergency, provide the doctor's name instead of the clinic's. This ensures your files are accurately linked in our system.
						</h5>

						<h5>
							8. Doctor Transfers:
							If a doctor leaves our practice, please follow them to their new office for continuity of care.
						</h5>

						<h5>
							9. Respectful Conduct:
							Any form of rude language or behavior (verbal abuse, violence, threats, harassment, property damage) is not tolerated. Offenders may be asked to leave.
						</h5>


						<h3>
							Thank you for choosing our clinic. We look forward to providing you with quality care!
						</h3>
					</div>
				</div>
			);
		} else {
			return (
				<div className="clinicWalkInDiv">
					{/* <h2 className="clinicWalkInDiv-header">家庭病人预约规定</h2> */}
					<div className="clinicWalkInDiv__textDiv">
						{/* <h3>如果需要成为J-Von Medical诊所病人，请遵守诊所如下规定:</h3>
						<h4>在COVID-19期间，一些医生能够在诊所和远程医疗中接受新患者。</h4>
						<h4>如果您想成为我们的患者，请按照以下步骤进行注册：</h4>
						<ol>
							<li>
								请填写
								<a
									href="https://form.jotform.com/LotusJvon/jm-pa-npr"
									target="_blank"
									rel="noopener noreferrer"
								>
									诊所条款同意书&新病人注册
								</a>
							</li>
							<li>
								请填写
								<a
									href="https://form.jotform.com/LotusJvon/tomr"
									target="_blank"
									rel="noopener noreferrer"
								>
									从别的诊所转入我们诊所请填写此表格
								</a>
							</li>
						</ol> */}

						<div className="clinicWalkInDiv-policyDiv">
							<h2 className="clinicWalkInDiv-policyHeader">诊所政策</h2>
							<h5>我/我们已阅读并同意以下内容：</h5>
							<h2>在线预约:</h2>
							<h5>
								J-Von 医疗中心通过我们的网站提供电话问诊、虚拟预约和到诊所的面诊的在线预约服务。我们可以专门协助老年患者在线预约。
							</h5>
							<h2>预约指南：</h2>
							<h5>由于预约量大，我们的办公室建议患者每次就诊时仅讨论1-2个医疗问题。在紧急情况下，我们的医生会分配额外时间进行讨论。然而，对于非紧急事项，医生将在分配的时间内优先处理并解决。如有必要，可能会建议进行后续访问，以确保每个问题都得到彻底关注。
								我们致力于维持日程的准时，我们恳请您的理解。如果出现任何延误，我们感谢您的耐心，并请求您不要向我们的前台员工施压要求调整时间。我们认可并重视您的时间。
							</h5>

							<h2>您应该看您自己的医生：</h2>
							<h5>从2024年开始，我们的诊所将过渡到一个新的医疗模式，即患者只能看自己的家庭医生。这一变化反映了BC省的新医疗模式，该模式旨在加强医患关系并提高护理质量。在新的模式下，请咨询您指定的家庭医生进行护理。在本诊所内更换家庭医生是不允许的。</h5>

							<h2>取消政策：</h2>
							<h5>预约后未到场收费为60加元；如您想取消预约，请提前至少24小时通知。未到场的费用需要在下次预约之前支付。</h5>

							<h2>MSP未覆盖的服务</h2>
							<h5>请注意，我们诊所提供的某些服务不在MSP覆盖范围内，将需要直接支付。有关这些服务的费用详细信息，请参阅我们的收费清单。</h5>


							<h2>其他条款:</h2>
							<ol className="noMarginBot">
								<li>翻译服务：
									我们的诊所不提供翻译服务，也不能接受非英语的医疗报告。患者需自行带来翻译，并确保在提交之前将任何非英语文件翻译成英语。
								</li>
								<li>
									紧急预约政策：
									请注意，我们的诊所不提供紧急护理预约。如果您需要立即的医疗护理，我们建议访问紧急初级护理中心（UPCC）或急诊室（ER）。对于非紧急事项，请于我们的网站按照常规预约。

								</li>
								<li>报告和处方：
									请注意，我们不在没有预约的情况下讨论实验室报告或开具药物处方。讨论这些问题或接收您的实验室报告，需要与您的医生预约。诊所未经医生事先讨论，不得发布实验室报告。（我们不开具麻醉药或苯二氮类药物。）
								</li>
								<li>联系信息：
									确保您的电话具有语音信箱功能。如果没有，请提供第二联系电话和电子邮箱。这对于您的护理沟通以及专科医生办公室或其他医疗机构直接与您联系至关重要。如有任何联系信息变更，请及时联系我们更新。
								</li>
								<li>儿童监管：
									在等候室和病房内，请监管好您的孩子。他们的安全是您的责任。
								</li>
								<li>如果您去医院急诊，请告诉医院医生 名称而不是诊所名称，因为我们的系统无法识别诊所名称 但医生的名字，以便您的文件可以链接在一起。</li>
								<li>手机使用：
									请勿在接待区和病房内使用手机
								</li>
								<li>再次感谢您，我们期待您的回音。</li>
							</ol>
						</div>
					</div>
				</div>
			);
		}
	}
}
