import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BackButton from '../../assets/icons/arrow-left-thick.svg';

export default class OldPatientFamily extends Component {
	constructor(props) {
		super(props);
		this.state = {
			agreedWalkIn: 'flex',
			agreedFamilyDoctor: 'flex',
			agreedVirtual: 'flex',
			minutes: 0,
			seconds: 3
			// language: this.props.state.
		};
		this.iAgreeOldFamilyDoctor = this.iAgreeOldFamilyDoctor.bind(this);
	}

	componentDidMount() {
		// window.scrollTo(0, 0);
		let checkFamilyDoctorToken = JSON.parse(localStorage.getItem('agreeOldFamilyDoctor'));
		if (checkFamilyDoctorToken === null || checkFamilyDoctorToken === undefined) {
			this.myInterval = setInterval(() => {
				const { seconds, minutes } = this.state;
				if (seconds > 0) {
					this.setState(({ seconds }) => ({
						seconds: seconds - 1
					}));
				}
				if (seconds === 0) {
					if (minutes === 0) {
						clearInterval(this.myInterval);
					} else {
						this.setState(({ minutes }) => ({
							minutes: minutes - 1,
							seconds: 59
						}));
					}
				}
			}, 1000);
		}
	}

	iAgreeOldFamilyDoctor = () => {
		// console.log('clicked');
		if (this.state.minutes === 0 && this.state.seconds === 0) {
			localStorage.setItem('agreeOldFamilyDoctor', JSON.stringify('agreed'));
			this.setState({
				agreedFamilyDoctor: 'none'
			});
		}
		this.setState({ agreedFamilyDoctor: 'none' });
	};

	render() {
		if (this.props.state.currentLanguage === 'english') {
			return (
				<div className="generalCenter">
					<div className="generalCenter-center">
						<p>Thank You</p>
						<h3>We are currently available for phone or video visits as well as in-clinic visits</h3>
						<section className="generalCenter-highlight">
							For video or phone visits with a doctor, click on{' '}
							<a
								href="https://jvonebooking.mpeer.net:8881/appointment/"
								target="_blank"
								rel="noopener noreferrer"
							>
								Book Virtually
							</a>
						</section>
						<h3>We are also available for same day walk in appointments</h3>
						<h3>
							But we DO NOT see these symptoms physically in the clinic but doctors can see you virtually
							(online)
						</h3>
						<ol>
							<li>Any cold symptoms (fever, cough, sore throat)</li>
							<li>Any Allergy symptoms (coughing, sneezing)</li>
							<li>
								Any covid-19 related similar symptoms (short of breath, conjunctivitis, bronchitis, loss
								of taste or smell, or the virus related to children (Kawasaki disease)
							</li>
						</ol>

						<h3>
							If you booked, we have to change to Virtual medical visit. Any patients not following our
							rules, and we have to ask you to leave the clinic because we have to protect our patients.
						</h3>
						<section className="generalCenter-highlight">
							If you would like to book for an appointment virtually with your family doctor, please
							click:{' '}
							<a
								href="https://jvonebooking.mpeer.net:8881/appointment/"
								target="_blank"
								rel="noopener noreferrer"
							>
								Book Virtually
							</a>
						</section>
						<h3 style={{ textAlign: 'center' }}>Covid-19 J-Von Clinic’s New Protocol</h3>
						<h3>Start from: Oct 1, 2020.</h3>
						<h3>
							Due to the rise of Covid-19 cases, we have come out with new protocols for our clinic. Please respect
							our policy, our main purpose is to protect everybody, if any patients don&#39;t follow or break our rules we
							will give a warning and serious level we may ask that patient to leave because we have to protect our
							patients.
						</h3>
						<h3>Our new rules are here:</h3>
						<h3>Our office door will be locked.</h3>
						<ol style={{ marginTop: 20 }}>
							<li>Walk in pts needs to call us to book appointments before coming inside of the clinic.</li>
							<li>
								Patients that have appointments will need to wait from their cars or outside(a text message will
								be sent to them once they have checked-in using the QR code).Please wear enough clothes
								since the weather is getting colder.
									</li>
							<li>
								We will call individual patients when it is their turn and please come in one by one. If you wish
								to stay in your car, we can text you and once it is your turn please knock the door.
									</li>
							<li>
								At this time we are only seeing (babies, pregnant women, back pain, ICBC, WCB,) other issues
								will be by phone or virtual calls.
									</li>
							<li>
								For any patients who need assistance only 1 person is allowed to accompany the patient to
								come in.
									</li>
							<li>
								Doctors required patients to come inside the clinic, that individual can only be booked by the
								doctor who requested them to come.
									</li>
							<li>
								Due to Covid-19 Virus number keeps rising up, we cannot accommodate so many walk in pts.
								We can only offer registered patients for pap test at this time.
									</li>
							<li>
								When flu shots season coming, and we only have limited space for flu shots. You can also reach
								to public health or pharmacy to get the flu shots.
									</li>
						</ol>
						<h3>Thank you. Let us help each other. We are all in this together!</h3>
					</div>
					<Link className="backButton" to="/bookfamilydoctor">
						<img src={BackButton} alt="backButton" /> Go Back
					</Link>
				</div>
			);
		} else {
			return (
				<div className="generalCenter">
					<div className="generalCenter-center">
						<p>感谢您的到来</p>
						<h3>我们目前接受电话看诊已经网络远程看诊以及进入诊所面对面门诊</h3>
						<section className="generalCenter-highlight1">
							如果需要和医生网络远程看诊或者电话看诊，请点击{' '}
							<a
								href="https://jvonebooking.mpeer.net:8881/appointment/"
								target="_blank"
								rel="noopener noreferrer"
							>
								网上门诊
							</a>
							。
						</section>
						<h3>我们也接受进入诊所面对面看诊</h3>
						<h3>但是我们在诊所不看以下这些症状，但医生可以通过视频或者电话和您沟通 （线上）</h3>
						<ol>
							<li>任何感冒症状（例如发烧，咳嗽，嗓子发炎）</li>
							<li>任何过敏症状，例如打喷嚏，咳嗽</li>
							<li>任何和新冠症状类似的情况（呼吸困难，红眼病，肺炎，失去味觉和嗅觉 儿童川崎病）</li>
						</ol>
						<h3>我们都无法让您进入诊所看病，但是医生愿意在远程网络给你看病，帮你开单子去做检查。</h3>
						<section className="generalCenter-highlight1">
							如果您想和您的家庭医生预约网上门诊，请 点击{' '}
							<a
								href="https://jvonebooking.mpeer.net:8881/appointment/"
								target="_blank"
								rel="noopener noreferrer"
							>
								网上门诊
							</a>
						</section>
						<h4>谢谢</h4>
						<h3 style={{ textAlign: 'center' }}>Covid-19 J-Von Clinic’s New Protocol</h3>
						<h3>
							因为疫情的数字比以前提升，我们诊所最新出台了一些新的诊所规定。为了大家好，请
							遵守我们的规章，也麻烦请大家配合我们的工作。 如果任何违法我们的规章，我们就无法帮助您看病
							了。我们主要是需要保护每一个病患每一个人的安全。
								</h3>
						<h3>我们的规定如下 10月1日开始， 诊所的门会锁上，只有预约的人我们核对名字门才会开。</h3>
						<ol style={{ marginTop: 20 }}>
							<li>
								没有预约的病人麻烦请不要直接进入诊所 我们只接受预约的病人进入。但是
								大家放心，可以在网络上预约电话问诊 都可以看病。只是方式变化了。 或者你们电话咨询我们 我
								们帮你们安排。 为了保证其他病人的安全，只有符合必要看病条件才能进入诊所看病.何为符合条件
								请参考第四条
									</li>
							<li>
								已经预约并且需要进入诊所的病人：为了保证其他病人安全，需要在外面等
								的记得请多穿衣服 秋季已经转凉。也可以在车里面等，我们可以发短信给你。没有被点名叫进来的病
								人不能进入诊所，请配合我们工作，以及体谅其他病人安全。
									</li>
							<li>
								到了您的时间，您如果在门口我们就喊您的名字。尽量开车，在车里我们用
								短信通知您。 麻烦被点名的病人进来
									</li>
							<li>
								我们目前进入诊所看诊只有此类看诊项目(婴儿常规检查，孕妇常规检查，背
								痛，车祸和工伤）其他的都需要电话预约来安排医生电话问诊
									</li>
							<li>
								为了规避一定人数请麻烦不要带家属进来，我们会在门口喊您的名字，预约
								的名字是谁那个人才能进入诊所，小宝宝和行动不方便的长者我们理解，允许一位家人陪伴。不是给
								大家添麻烦，是保护每一个人。
									</li>
							<li>
								医生要求进入诊所复诊的只能看那位医生。因为现在医生的看诊位置很紧
								张，其他的医生病人也需要看自己病人病。
									</li>
							<li>
								我们最近发现，一些其他诊所的病人 他们诊所不开，他们不给自己病人看妇科之后把这批病人转
								接到我们这里。而我们帮了好久的忙 现在无法负荷这些不断累积人数 疫情期间我们进来的人数是需
								要合理控制的 而且我们的病人 我们需要优先照顾目前没有来过我们诊所请和你的家庭医生商讨做妇
								科检查
									</li>
							<li>
								流感疫苗季节大约在11月份，由于控制人数，进入诊所打疫苗也是有限的。
								但是大家不用担心 可以去这个机构(public health)打流感疫苗还有药房都可以打疫苗。
									</li>
						</ol>
						<h3>
							困难时期 我们一起渡过，请大家保重身体，也感谢配合我们的工作。希望我们诊所进来每一个病
							人都安全.
						</h3>
					</div>
					<Link className="backButton" to="/bookfamilydoctor">
						<img src={BackButton} alt="backButton" />返回
					</Link>
				</div>
			);
		}
		// }
	}
}
