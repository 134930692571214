import React, { Component } from 'react';

export default class ClinicWalkInPolicy extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		if (this.props.props.currentLanguage === 'english') {
			return (
				<div className="clinicWalkInDiv2">
					<h2 className="clinicWalkInDiv-walkInHeader"> WalkIn Policy</h2>
					<div className="clinicWalkInDiv__textDiv addedPadding">
						<h4>
							Please read the following Clinic Policy carefully If you have any questions, please let us
							know before signing.
						</h4>
						<h3>
							Please first fill the walk in registration form:
							<a
								style={{ paddingLeft: 10 }}
								href="https://form.jotform.com/LotusJvon/jm-wirf"
								target="_blank"
								rel="noopener noreferrer"
							>
								WALK IN REGISTRATION FORM
							</a>
						</h3>
						<h4>1.Opioids, Sedatives and other controlled substances:</h4>
						<h5>Please note that we do not prescribe narcotics or benzodiazepines.</h5>
						<h4>2. Appointment Time:</h4>
						<h5>
							a) Due to the high volume, our office encourages patients to discuss 1-2 medical issues per
							visit. If it is an emergency, the doctors with consider extra time discussing the issue. But
							if it is not an emergency, doctors will prioritize them in the allotted time and may ask you
							to schedule a follow up visit to go through each issue thoroughly.
						</h5>
						<h5>
							b) It is our best interest to keep our appointments on schedule, if we do run into delays,
							please kindly wait and do not push the front staff for timing. We understand your time is
							valuable.
						</h5>
						<h4>3. Pharmanet:</h4>
						<h5>
							By signing, you are giving us consent to access your recent prescription records on
							Pharmanet.
						</h5>
						<h4>4. Canclelation Fee:</h4>
						<h5>
							We have a 24-hour cancellation policy for booked appointments. Missed appointments and late
							cancellations are subjected to a $60 fee.
						</h5>
						<h4>5. Immunization for Children:</h4>
						<h5>We do not book routine immunizations for children.</h5>
						<h4>6. Uninsured Services:</h4>
						<h5>
							I understand that some services are not covered by the provincial Medical Services Plan.
							Please ask staff and doctors for cost before you book the appointment. For example: transfer
							to other clinic, sick notes, printing cost, forms, etc.
						</h5>
						<h4>7. We DONOT Accept Rude Behavior:</h4>
						<h5>
							Our doctor and staff will not tolerate any rude or aggressive behavior. Including but not
							limited to verbal abuse, violence, threats, harassment and damaging the property. You will
							be asked to leave the property and may not be able to return.
						</h5>
						<h4>8. Other Rules:</h4>
						<ol>
							<li>We cannot provide prescription renewals over the phone or via fax.</li>
							<li>
								Some services are not covered by the provincial Medical Services Plan. Please ask the
								staff or doctors for the cost. Services include sick notes, forms, transfer of records,
								photocopying, shots and etc.
							</li>
							<li>
								Please ensure that your file is kept up to date with a current phone number, email,
								address, and emergency contact information.
							</li>
							<li>Tests ordered will be copied to your family doctor (when applicable).</li>
							<li>We do not give out test results over the phone.</li>
							<li>
								I consent to receiving clinic notices by email, Like bring you back to see doctors for
								reports if we cannot reach you on the phone multiple times. Emails from J-Von Medical
								Clinic will not contain any medical information such as test results or specialist
								reports. I understand and accept that there is a small inherent risk to email
								communication such as unauthorized access.
							</li>
						</ol>
						<h5>THANK YOU SO MUCH FOR YOUR COOPERATION!</h5>
					</div>
				</div>
			);
		} else {
			return (
				<div className="clinicWalkInDiv2">
					<h2 className="clinicWalkInDiv-walkInHeader"> 临时当天预约规定</h2>
					<div className="clinicWalkInDiv__textDiv addedPadding">
						<h4>请仔细阅读以下诊所政策如有任何疑问，请告知我们 在签名之前。</h4>
						<h3>
							请首先以注册表格的形式填写：
							<a
								style={{ paddingLeft: 10 }}
								href="https://form.jotform.com/LotusJvon/jm-wirf"
								target="_blank"
								rel="noopener noreferrer"
							>
								临时看诊/无家庭医生登记表
							</a>
						</h3>
						<h4>1.镇静剂和毒品有关的药物:</h4>
						<h5>我们诊所不开任何麻醉剂和镇定剂的药物（No Narcotics and Benzodiazepines).</h5>
						<h4>2. 预约时间:</h4>
						<h5>
							a) 因为家庭医生短缺，诊所每天会有很多病人希望就诊。请病人每次看诊尽量问1-2 个医疗问题. 如果您是遇到医疗紧急(emergency) 情况: 医生会给您作详细的检查
							,会询问很多的问题 不会当做单一问题处理。但是如果您不是性命攸关的紧急情况，医生会在规定的时间内优先考虑病情，并可能会要求您安排后续访问以彻底解决每个问题,请您跟前台再约一个时间
						</h5>
						<h5>b) 医生看病的时间很难能够准确维持，所以难免需要您等待。请您耐心等待，只要您已经通报名字给给前台工作人。到时间前台工作人员一定会通知您进入房间就诊。请不要不断询问前台时间.</h5>
						<h4>3. Pharmanet 处方药查询:</h4>
						<h5>医生需要查看病人往过吃过的处方药以便医生能够开出适合病人的药品。病人需要同意医生查看Pharmanet，</h5>
						<h4>4. 取消预约规定:</h4>
						<h5>诊所需要您提供给我们24个小时通知取消约诊时间，如果没有及时通知我们，诊所收取60元的不通知费用或者太晚通知费用。</h5>
						<h4>5. 给儿童打疫苗:</h4>
						<h5>
							我们诊所不提供任何给儿童注射疫苗，政府提供免费疫苗的电话在这里: <br />Burnaby Public Health Unit: (604) 918-7605
							其他城市请搜索本地城市的public health unit
						</h5>
						<h4>6. 医疗保险不包括的费用:</h4>
						<h5>有些看诊费用政府医疗保险不包括：例如病假条，转诊材料邮寄费用，个别打针费用，各种表格费用等等，请您咨询前台之后，再约诊。</h5>
						<h4>7. 诊所零容忍不礼貌行为:</h4>
						<h5>我们诊所不接收任何不礼貌的语言，例如语言暴力，恐吓，不断骚扰打断前台的工作，损坏诊所物品。一旦这些行为发生，您会被通知离开诊所.</h5>
						<h4>8. 其他的规定:</h4>
						<ol>
							<li>我们不在电话里给病人开处方药，请您到诊所看病并告知医生您需要什么药.</li>
							<li>签了这份文件，我们医生可以查找你的过去开药清单记录。</li>
							<li>请告诉我们前台工作人员您最近的住址，电话，邮件，其它联系方式 ，以便诊所和您联系</li>
							<li>您有家庭医生的情况下，请提供今天临时给您看病医生您的家庭医生名字，这样您的家庭医生那边也有一份检查报告结果.</li>
							<li>我们不会在电话中讨论您的检验报告，请您约时间复诊回来看报告。</li>
							<li>
								如果您转到别家诊所，我们这边会将材料整编发到新的诊所，我们会收取$50手续费用. 每次在我们这边打印报告我们是收$1一张。如果是验血报告 您可以跟lab
								设立在网上看报告，请咨询lab 这样就不必在诊所打印支付费用。其他有些表格政府需要医生帮忙填写 不包括在看诊里面，需要单独收费，请咨询前台和医生之后再约诊
							</li>
						</ol>
						<h5>非常感谢您的合作和理解!</h5>
					</div>
				</div>
			);
		}
	}
}
