import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Col, Card, Spinner, Form } from 'react-bootstrap';
//api
import { queryDoctor, deleteDoctor, addDoctor, nextPatient, updateDoctorStatus, deletePatient } from '../../api/sms';

export default class DoctorEdit extends React.Component  {
  constructor(props){
    super(props);
    this.state = { 
      doctors: [],
      isLoading: false,
     }
     this.refreshState = this.refreshState.bind(this);
     this.addNewDoctor = this.addNewDoctor.bind(this);
  }

  componentDidMount() {
    this.refreshState();
  }

  async refreshState() {
    this.setState({ isLoading: true });
    const resDoctors = await queryDoctor();
    const doctors = resDoctors.data;
    return await this.setState({ ...this.state, doctors, isLoading: false });
  }

  async addNewDoctor(event) {
		event.preventDefault();
    const { doctorName } = event.target.elements;
    this.setState({ isLoading: true });
    await addDoctor(doctorName.value);
    await this.refreshState();
    return this.setState({ isLoading: false });
	}
  
  render() {
    const {state, props} = this;
    let doctorDOM = state.doctors.map((doctor,index) => {
			return (
      <Card key={index} body>{doctor.name} 
        <Button 
          style={{ float: 'right' }}
          variant="danger"
          onClick={async ()=>{ 
            this.setState({ isLoading: true });
            await deleteDoctor(doctor); 
            await this.refreshState();
            this.setState({ isLoading: false });
          }}>
          Delete
        </Button>
      </Card>
      )
    });
    

    return (
      <React.Fragment>
        <Modal 
          show={props.show} 
          onHide={props.onHide} 
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{props.title}</Modal.Title>
          </Modal.Header>
          { state.isLoading ?
           <div className="d-flex align-items-center" style={{ position: 'fixed', top: '50%', left: '50%' }}>
              <Spinner size="lg" animation="border" role="status" variant="success" />
              <span style={{paddingLeft:'5px'}} >Loading Doctors...</span>
            </div>
            :
            <div style={{marginTop:'10px'}}>
              <Form onSubmit={this.addNewDoctor}>
                <Row className="justify-content-md-center">
                <Col xs={1} md={1} lg={1}></Col>
                  <Col>
                    <Form.Group controlId="doctorName">
                      <Form.Control placeholder="Doctor Name" required />
                    </Form.Group>
                  </Col>
                  <Col>
                      <Button 
                        style={{ float: 'right' }}
                        variant="primary"
                        type="submit"
                      >
                        + Add New Doctor
                      </Button>
                  </Col>
                  <Col xs={1} md={1} lg={1}></Col>
                </Row>
              </Form>
            <Modal.Body>{doctorDOM}</Modal.Body>
            </div>
          }
          <Modal.Footer>
              <center>
                <Button size="lg" variant="secondary" onClick={props.onHide}>
                  Finish Editting
                </Button>
              </center>
          </Modal.Footer>
        </Modal>
       
      </React.Fragment>
    );
  }

}