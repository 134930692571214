import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class ClinicVirutual extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		if (this.props.props.currentLanguage === 'english') {
			return (
				<div className="virtual">
					<div className="virtual__bigTextDiv">
						<h3 className="virtual__bigTextDiv-header">Virtual Medical visit policy </h3>
						<div className="virtual__bigTextDiv-firstDiv">
							<h4>
								During the COVID-19 outbreak, we are implementing virtual services at J-Von Medical
								Clinic: a telemedicine video clinic. Virtual Health is a patient-centered care model
								focused on connecting patients and providers, and many patients have requested Virtual
								Health options.
							</h4>
							<h4>If you choose to participate:  </h4>
							<ol>
								<li>
									We will require your phone number or email address to send you the visit invitations{' '}
								</li>
								<li>
									You will be asked to follow the instructions we provide to set up your device prior
									to the appointment. Instruction video is here:{' '}
									<Link to="onlinebookinginstruction">Instruction Page</Link>{' '}
								</li>
								<li>
									Doctors may call you so please donot mute your phone. Our doctors phone may have no
									Caller ID showing to your cell phone, please still take the call around your
									appointment time.{' '}
								</li>
								<li>
									You are required to have a compatible device if you want to use email invication.
									Android, iOS, Mac, and Windows devices newer than 3.2012 are recommended. Also a
									camea need to be installed in your computer{' '}
								</li>
								<li>
									Our care team is unable to provide you with trouble shooting or technical support.{' '}
								</li>
								<li>
									A Virtual Health visit requires the same privacy considerations as an in-person
									visit and you are responsible to find a private space to participate in your Virtual
									Health visit. {' '}
								</li>
								<li>Recording is not permitted.   </li>
							</ol>
							<h4>
								I acknowledge and understand that despite recommendations that encryption software be
								used as a security mechanism for electronic communications, it is possible that
								communications with the Physician or the Physician’s staff using the Services may not be
								encrypted. Despite this, I agree to communicate with the Physician or the Physician’s
								staff using these Services with a full understanding of the risk.{' '}
							</h4>
							<h4>
								I acknowledge that either I or the Physician may, at any time, withdraw the option of
								communicating electronically through the Services upon providing written notice. Any
								questions I had have been answered{' '}
							</h4>
						</div>
						<div className="virtual__bigTextDiv-firstDiv">
							<h3>Appendix </h3>
							<section>
								Potential Risks: As with any medical procedure, there are potential risks associated
								with the use of telemedicine. These risks include, but may not be limited to: <br />Information
								transmitted may not be sufficient (e.g., poor resolution of images) to allow for
								appropriate medical decision making by the physician and consultant(s). <br />The
								consulting physician(s) are not able to provide medical treatment to the patient through
								the use of telemedicine equipment nor provide for or arrange for any emergency care that
								I may require. <br />Delays in medical evaluation and treatment could occur due to
								deficiencies or failures of the equipment. <br />Security protocols could fail, causing
								a breach of privacy of personal medical information. <br />A lack of access to complete
								medical records may result in adverse drug interactions or allergic reactions or other
								medical judgment errors. Informed Consent for Telemedicine Page 2 By signing this form,
								I understand and agree to the following: 1. The laws that protect the privacy and
								confidentiality of medical information also apply to telemedicine. <br />No information
								obtained during a telemedicine encounter which identifies me will be disclosed to
								researchers or other entities without my consent. <br />I have the right to withhold or
								withdraw my consent to the use of telemedicine during the course of my care at any time.{' '}
								<br /> I understand that my withdrawal of consent will not affect any future care or
								treatment, nor will it subject me to the risk of loss or withdrawal of any health
								benefits to which I am otherwise entitled. 3. I have the right to inspect all
								information obtained and recorded during the course of a telemedicine interaction, and
								may receive copies of this information for a reasonable fee. <br /> A variety of
								alternative methods of medical care may be available to me, and I may choose one or more
								of these at any time. My physician has explained the alternative care methods to my
								satisfaction. <br />Telemedicine may involve electronic communication of my personal
								medical information to other. <br />
							</section>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div className="virtual">
					<div className="virtual__bigTextDiv">
						<h3 className="virtual__bigTextDiv-header">网络远程看病规定 </h3>
						<div className="virtual__bigTextDiv-firstDiv">
							<h4>
								在COVID-19爆发期间，我们正在J-Von医疗诊所实施虚拟服务： 远程医疗视频诊所。虚拟健康是一个以患者为中心的护理模型，专注于
								连接患者和提供者，许多患者已请求虚拟健康选项。
							</h4>
							<h4>如果您选择参加：  </h4>
							<ol>
								<li>我们将需要您的电话号码或电子邮件地址来向您发送访问邀请</li>
								<li>
									在开始之前，系统会要求您按照我们提供的说明设置设备。 指导视频在这里: <Link to="onlinebookinginstruction">指导视频</Link>{' '}
								</li>
								<li>医生可能会给您打电话，所以请不要使您的手机静音。我们的医生电话可能没有 来电显示在您的手机上，请在预约时继续接听电话 时间。</li>
								<li>
									如果要使用电子邮件收据，则必须具有兼容的设备。 建议使用低于3.2012的Android，iOS，Mac和Windows设备。也是一只茶 需要在您的计算机上安装
								</li>
								<li>我们的护理团队无法为您提供故障排除或技术支持。 </li>
								<li>网络远程访问需要与面对面访问相同的隐私注意事项 并且您有责任找到一个私人空间来参与您的远程网络 访问。  </li>
								<li>不允许录音。   </li>
							</ol>
							<h4>
								我承认并理解，尽管建议使用加密软件 作为电子通讯的安全机制， 与医师或医师的人员一起使用服务可能不会被加密。尽管 我同意使用这些内容与医师或医师人员进行沟通
								对风险有充分了解的服务。
							</h4>
						</div>
						<div className="virtual__bigTextDiv-firstDiv">
							<h3>Appendix </h3>
							<section>
								Potential Risks: As with any medical procedure, there are potential risks associated
								with the use of telemedicine. These risks include, but may not be limited to: <br />Information
								transmitted may not be sufficient (e.g., poor resolution of images) to allow for
								appropriate medical decision making by the physician and consultant(s). <br />The
								consulting physician(s) are not able to provide medical treatment to the patient through
								the use of telemedicine equipment nor provide for or arrange for any emergency care that
								I may require. <br />Delays in medical evaluation and treatment could occur due to
								deficiencies or failures of the equipment. <br />Security protocols could fail, causing
								a breach of privacy of personal medical information. <br />A lack of access to complete
								medical records may result in adverse drug interactions or allergic reactions or other
								medical judgment errors. Informed Consent for Telemedicine Page 2 By signing this form,
								I understand and agree to the following: 1. The laws that protect the privacy and
								confidentiality of medical information also apply to telemedicine. <br />No information
								obtained during a telemedicine encounter which identifies me will be disclosed to
								researchers or other entities without my consent. <br />I have the right to withhold or
								withdraw my consent to the use of telemedicine during the course of my care at any time.{' '}
								<br /> I understand that my withdrawal of consent will not affect any future care or
								treatment, nor will it subject me to the risk of loss or withdrawal of any health
								benefits to which I am otherwise entitled. 3. I have the right to inspect all
								information obtained and recorded during the course of a telemedicine interaction, and
								may receive copies of this information for a reasonable fee. <br /> A variety of
								alternative methods of medical care may be available to me, and I may choose one or more
								of these at any time. My physician has explained the alternative care methods to my
								satisfaction. <br />Telemedicine may involve electronic communication of my personal
								medical information to other. <br />
							</section>
						</div>
					</div>
				</div>
			);
		}
	}
}
