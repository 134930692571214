import React, { Component } from 'react';
export default class MainBodyFirst extends Component {
	render() {
		return (
			<div className="firstSection">
				<section className='news-section'>
					<p>Due to the high volume of patients that each doctor is currently attending to, they have reached their capacity and are unable to accept any additional patients. Consequently, the clinic will <span className='news-important-part'>no longer be able to provide services to unattached (walk-in) patients.</span></p>
				</section>

				<section className='testimonials-section'>
					<div className='testimonials-section-inner-wrapper'>
						<div className='slide-button-container'>
							<a className='slide-booking-button' href='https://jvonebooking.mpeer.net:8881/appointment/' target="_blank"
								rel="noopener noreferrer">Book Virtual</a>
							<a className='slide-booking-button' href='https://jvonebooking.mpeer.net:8881/appointment/' target="_blank"
								rel="noopener noreferrer">Book In-Clinic</a>
						</div>
					</div>
				</section>
			</div>
		);
	}
}
