import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Video1 from '../../assets/video/virtual.mp4';
import Video2 from '../../assets/video/virtualcn.mp4';

import BackButton from '../../assets/icons/arrow-left-thick.svg';

export default class BookingIns extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		if (this.props.state.currentLanguage === 'english') {
			return (
				<div className="bookingIns">
					<div className="bookingIns-virtualDiv">
						<div>
							<h3>How to see doctors virtually</h3>
							<video className="bookingIns-video" controls>
								<source src={Video1} type="video/mp4" />
							</video>
						</div>
					</div>
					<Link className="backButton" to="/">
						<img src={BackButton} alt="backButton" /> Go Back
					</Link>
				</div>
			);
		} else {
			return (
				<div className="bookingIns">
					<div className="bookingIns-virtualDiv">
						<div>
							<h3>怎么和医生网络远程看病</h3>
							<video className="bookingIns-video" controls>
								<source src={Video2} type="video/mp4" />
							</video>
						</div>
					</div>
					<Link className="backButton" to="/">
						<img src={BackButton} alt="backButton" />返回
					</Link>
				</div>
			);
		}
	}
}
