import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

export default class AllNews extends Component {
	constructor() {
		super();
		this.state = {
			reverseResult: [],
			isLoading: true
		};
	}

	async componentDidMount() {
		axios.get('/englishNews').then((res) => {
			console.log(res);
			console.log(Object.values(res.data[0]));
			const reverseArr = [];
			for (let i = res.data.length - 1; i > -1; i--) {
				reverseArr.push(Object.values(res.data[i]));
			}
			console.log(reverseArr);
			this.setState({ reverseResult: reverseArr, isLoading: false });
		});
	}

	render() {
		const newNewsArr =
			this.state.reverseResult &&
			this.state.reverseResult.map((data, index) => {
				const newsId = `/news/${data[0]}`;
				if (data[1] !== 'Accepting Patients') {
					return (
						<Link to={newsId} key={data[0]} style={{ textDecoration: 'none', color: 'black' }}>
							<div className="allNewsWork__Div">
								<h3>{data[1]}</h3>
								<h4>{data[2]}</h4>
							</div>
						</Link>
					);
				}
			});
		const isLoading = this.state.isLoading;
		return isLoading ? (
			<div className="allNewsWork">
				<div className="allNews__emptyDiv" />

				<div className="allNewsWork-bannerDiv" />
			</div>
		) : (
			<div className="allNewsWork">
				{/* <Slide {...properties} className="allNews__banner">
					<div style={{ height: '140px' }} />
					<div className="allNews__banner__content">
						<div className="allNews__banner__content-1" />
					</div>
				</Slide> */}
				<div className="allNews__emptyDiv" />
				<div className="allNewsWork-bannerDiv" />
				<div className="allNewsWork-outsideDiv">{newNewsArr}</div>
			</div>
		);
	}
}
