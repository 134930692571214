import React, { Component } from 'react';
// const AnyReactComponent = ({ text }) => <div>{text}</div>;

export default class paymentPage extends Component {
	static defaultProps = {
	};
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		if (this.props.state.currentLanguage === 'english') {
			return (
				<div className="paymentPage">
					<div className="paymentPage__location1">
						<div className="paymentPage__location1__infoDiv">
							<h2>Medical Private Pay Charges</h2>
							<div className="paymentPage__form">
								<form className="p-3 my-3">
									<div className="paymentPage__price">
										<label className="radio"><input name="plan" type="radio" value="" />SICK NOTES $50 </label> 
										<label className="radio"><input name="plan" type="radio" value="" />SCHOOL IMMUNIZATION FORM $30 </label> 
										<label className="radio"><input name="plan" type="radio" value="" />ATTENDING PHYSICIAN'S STATEMENT (WORK FORM ) $40</label> 
										<label className="radio"><input name="plan" type="radio" value="" />EI $20</label> 
										<label className="radio"><input name="plan" type="radio" value="" />SPARC FORM - PARKING DISABILITY $20</label> 
										<label className="radio"><input name="plan" type="radio" value="" /> HANDY - DART $20</label> 
										<label className="radio"><input name="plan" type="radio" value="" />TAX DISABILITY FORM $120</label> 
										<label className="radio"><input name="plan" type="radio" value="" />DENTAL FORM $80</label> 
										<label className="radio"><input name="plan" type="radio" value="" />DRIVER'S MEDICALYELLOW $180</label> 
										<label className="radio"><input name="plan" type="radio" value="" />DRIVER'S MEDICAL BLUE $120</label> 
										<label className="radio"><input name="plan" type="radio" value="" />DISABILITY FORMS (SHORT 6PG $120</label> 
										<label className="radio"><input name="plan" type="radio" value="" />DISABILITY FORMS (SHORT LONG>8PG $150</label> 
										<label className="radio"><input name="plan" type="radio" value="" />COMPLETE PHYSICAL FORMS $160</label> 
										<label className="radio"><input name="plan" type="radio" value="" />GRADUAL RETURN FORM (FOR WORK) $40</label> 
										<label className="radio"><input name="plan" type="radio" value="" />CRA ASKED PATIENT'S VISIT TO REFLECT THEIR TIME STAY IN CANADA $40</label> 
										<label className="radio"><input name="plan" type="radio" value="" />CHILD TAX CREDIT FOR CHILD TO REFLECT CHILD'S TIME BEING IN CANADA $40</label> 
										<label className="radio"><input name="plan" type="radio" value="" />AFFORDABLE CHILD BENEFIT $40</label> 
										<label className="radio"><input name="plan" type="radio" value="" />LETTER OF REQUIRED SUPPORT $40</label> 
										<label className="radio"><input name="plan" type="radio" value="" />PRIVATE VISIT $130</label> 
										<label className="radio"><input name="plan" type="radio" value="" />GUARDME $110</label> 
									</div>
									<div className="input-group mb-3">
									<input type="number" placeholder="Card Number" name="number" className="form-control" id="number" maxLength="16" />
									</div>
									<div className="input-group mb-3">
									<input type="number" placeholder="MM" name="exp_month" className="form-control" id="exp_month" maxLength="2" />
									<span className="input-group-text">/</span>
									<input type="number" placeholder="YY" name="exp_year" className="form-control" id="exp_year" maxLength="2" />
									<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
									<input type="number" placeholder="CVV" name="cvv" className="form-control" id="cvv" maxLength="3" />
									</div>
									<button type="button" className="btn btn-warning">
									<span id="icon">
										<i className="fas fa-circle-notch fa-spin"></i>
									</span>
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pay
									Now&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div className="paymentPage">
					<div className="paymentPage__location1">
						<div className="paymentPage__location1__infoDiv">
							<h2>医疗费用在线支付</h2>
							<div className="paymentPage__form">
								<form className="p-3 my-3">
									<div className="paymentPage__price">
										<label className="radio"><input name="plan" type="radio" value="" />SICK NOTES $50 </label> 
										<label className="radio"><input name="plan" type="radio" value="" />SCHOOL IMMUNIZATION FORM $30 </label> 
										<label className="radio"><input name="plan" type="radio" value="" />ATTENDING PHYSICIAN'S STATEMENT (WORK FORM ) $40</label> 
										<label className="radio"><input name="plan" type="radio" value="" />EI $20</label> 
										<label className="radio"><input name="plan" type="radio" value="" />SPARC FORM - PARKING DISABILITY $20</label> 
										<label className="radio"><input name="plan" type="radio" value="" /> HANDY - DART $20</label> 
										<label className="radio"><input name="plan" type="radio" value="" />TAX DISABILITY FORM $120</label> 
										<label className="radio"><input name="plan" type="radio" value="" />DENTAL FORM $80</label> 
										<label className="radio"><input name="plan" type="radio" value="" />DRIVER'S MEDICALYELLOW $180</label> 
										<label className="radio"><input name="plan" type="radio" value="" />DRIVER'S MEDICAL BLUE $120</label> 
										<label className="radio"><input name="plan" type="radio" value="" />DISABILITY FORMS (SHORT 6PG $120</label> 
										<label className="radio"><input name="plan" type="radio" value="" />DISABILITY FORMS (SHORT LONG>8PG $150</label> 
										<label className="radio"><input name="plan" type="radio" value="" />COMPLETE PHYSICAL FORMS $160</label> 
										<label className="radio"><input name="plan" type="radio" value="" />GRADUAL RETURN FORM (FOR WORK) $40</label> 
										<label className="radio"><input name="plan" type="radio" value="" />CRA ASKED PATIENT'S VISIT TO REFLECT THEIR TIME STAY IN CANADA $40</label> 
										<label className="radio"><input name="plan" type="radio" value="" />CHILD TAX CREDIT FOR CHILD TO REFLECT CHILD'S TIME BEING IN CANADA $40</label> 
										<label className="radio"><input name="plan" type="radio" value="" />AFFORDABLE CHILD BENEFIT $40</label> 
										<label className="radio"><input name="plan" type="radio" value="" />LETTER OF REQUIRED SUPPORT $40</label> 
										<label className="radio"><input name="plan" type="radio" value="" />PRIVATE VISIT $130</label> 
										<label className="radio"><input name="plan" type="radio" value="" />GUARDME $110</label> 
									</div>
									<div className="input-group mb-3">
									<input type="number" placeholder="Card Number" name="number" className="form-control" id="number" maxLength="16" />
									</div>
									<div className="input-group mb-3">
									<input type="number" placeholder="MM" name="exp_month" className="form-control" id="exp_month" maxLength="2" />
									<span className="input-group-text">/</span>
									<input type="number" placeholder="YY" name="exp_year" className="form-control" id="exp_year" maxLength="2" />
									<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
									<input type="number" placeholder="CVV" name="cvv" className="form-control" id="cvv" maxLength="3" />
									</div>
									<button type="button" className="btn btn-warning">
									<span id="icon">
										<i className="fas fa-circle-notch fa-spin"></i>
									</span>
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pay
									Now&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}
