import React, { Component } from 'react';

import EnglishPage from './AllNewsEnglish';
import ChinesePage from './AllNewsChinese';

export default class AllNews extends Component {
	render() {
		console.log(this.props);
		if (this.props.state.currentLanguage === 'english') {
			return (
				<div>
					<EnglishPage />
				</div>
			);
		} else {
			return (
				<div>
					<ChinesePage />
				</div>
			);
		}
	}
}
