import React, { Component } from 'react';

import DentalBackground from '../../assets/NewsBanner/rsz_dentalofficetwochairs.png';

export default class LotusSmileNews extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		if (this.props.state.currentLangauge === 'english') {
			return (
				<div className="lotusNews">
					<div className="lotusNews__center">
						<h3 className="lotusNews__center-header boldFont">
							Dental office offer a Great Deal for PATIENTS
						</h3>
						<img className="lotusNews__center-img" src={DentalBackground} alt="background" />
						<div className="lotusNews__center__textDiv">
							<h4>
								Our neighbor lotus smile dental offer good work dental treatment for patients. The best
								dentists always focus on their knowledge and skill. If you looking for a good skills
								dentists who are patient, kind, knowledgeable, put patients’ health first, please check
								them out. Lotus Smile Dental
							</h4>
							<div className="lotusNews__center-do">
								<h4>They Do:</h4>
								<ul>
									<li>White Fillings</li>
									<li>Crowns and Bridges</li>
									<li>Painless Extraction & wisdom teeth surgery</li>
									<li>Root Canal Therapy</li>
									<li>Emergency services including management of trauma and relief of pain</li>
									<li>Periodontal Therapy</li>
									<li>Botox</li>
									<li>Pediatric Dentistry</li>
									<li>Dentures</li>
									<li>Invisalign (clear braces)</li>
									<li>Implants</li>
									<li>Orthodontic (braces)</li>
									<li>Gingival and bone grafts</li>
									<li>Oral surgery</li>
								</ul>
							</div>
							<h4 className="lotusNews__center-bottomText">
								They also provide a great deal for patients. They understand after this pandemic, many
								people had lost their job, so they offer free $100 electronic tooth brush for new
								patients who has dental coverage. Patients without dental coverage can take advange of
								their great deal for cleaning and other treatments. Seniors and students also have good
								deal for them.
							</h4>
							<div className="lotusNews__center-bottomDiv">
								<h4>
									For more Information please check:{' '}
									<a href="https://www.lotussmile.ca/">LotusSmile.ca</a> or please call:
									(778)-379-8686, location is at{' '}
									<a href="https://www.google.com/maps/place/4470+Beresford+St,+Burnaby,+BC+V5H+2Y4/@49.2251322,-123.0059181,17z/data=!3m1!4b1!4m5!3m4!1s0x5486765e8f392a2b:0xa8ba7bbac17d91d9!8m2!3d49.2251322!4d-123.0037294">
										4470 beresford st, Burnaby BC
									</a>{' '}
								</h4>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div className="lotusNews">
					<div className="lotusNews__center">
						<h3 className="lotusNews__center-header boldFont">
							Dental office offer a Great Deal for PATIENTS
						</h3>
						<img className="lotusNews__center-img" src={DentalBackground} alt="background" />
						<div className="lotusNews__center__textDiv">
							<h4>
								Our neighbor lotus smile dental offer good work dental treatment for patients. The best
								dentists always focus on their knowledge and skill. If you looking for a good skills
								dentists who are patient, kind, knowledgeable, put patients’ health first, please check
								them out. Lotus Smile Dental
							</h4>
							<div className="lotusNews__center-do">
								<h4>They Do:</h4>
								<ul>
									<li>White Fillings</li>
									<li>Crowns and Bridges</li>
									<li>Painless Extraction & wisdom teeth surgery</li>
									<li>Root Canal Therapy</li>
									<li>Emergency services including management of trauma and relief of pain</li>
									<li>Periodontal Therapy</li>
									<li>Botox</li>
									<li>Pediatric Dentistry</li>
									<li>Dentures</li>
									<li>Invisalign (clear braces)</li>
									<li>Implants</li>
									<li>Orthodontic (braces)</li>
									<li>Gingival and bone grafts</li>
									<li>Oral surgery</li>
								</ul>
							</div>
							<h4 className="lotusNews__center-bottomText">
								They also provide a great deal for patients. They understand after this pandemic, many
								people had lost their job, so they offer free $100 electronic tooth brush for new
								patients who has dental coverage. Patients without dental coverage can take advange of
								their great deal for cleaning and other treatments. Seniors and students also have good
								deal for them.
							</h4>
							<div className="lotusNews__center-bottomDiv">
								<h4>
									For more Information please check:{' '}
									<a href="https://www.lotussmile.ca/">LotusSmile.ca</a> or please call:
									(778)-379-8686, location is at{' '}
									<a href="https://www.google.com/maps/place/4470+Beresford+St,+Burnaby,+BC+V5H+2Y4/@49.2251322,-123.0059181,17z/data=!3m1!4b1!4m5!3m4!1s0x5486765e8f392a2b:0xa8ba7bbac17d91d9!8m2!3d49.2251322!4d-123.0037294">
										4470 beresford st, Burnaby BC
									</a>{' '}
								</h4>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}
