import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import BackButton from '../../assets/icons/arrow-left-thick.svg';

export default class Insured extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		if (this.props.state.currentLanguage === 'english') {
			return (
				<div className="insured">
					<div className="insured__center">
						<h2>Life/ICBC Insurance</h2>
						<div>
							Any insurance company please fax your request to our office: 778-379-8399 or call to recieve
							the email address regarding patients’ chart notes
						</div>
						<div>
							Once we receive the request, we will create an invoice. Once we receive payment from you, we
							will email or fax you a copy of the requested patient information.
						</div>
						<div>Our Fax number: 778-379-8399</div>
					</div>
					<Link className="backButton" to="/">
						<img src={BackButton} alt="backButton" />Go Back
					</Link>
				</div>
			);
		} else {
			return (
				<div className="insured">
					<div className="insured__center">
						<h2>Life/ICBC Insurance</h2>
						<div>
							Any insurance company please fax your request to our office: 778-379-8399 or call to request
							the email address regarding patients’ chart notes
						</div>
						<div>
							Once we receive the request, we will create an invoice. Once we receive payment from you, we
							will email or fax you a copy of the requested patient information.
						</div>
						<div>Our Fax number: 778-379-8399</div>
					</div>
					<Link className="backButton" to="/">
						<img src={BackButton} alt="backButton" /> Go Back
					</Link>
				</div>
			);
		}
	}
}
