import React, { Component } from 'react';
import axios from 'axios';

export default class IdNews extends Component {
	constructor(props) {
		super(props);
		this.state = {
			newsInfo: [],
			isLoading: true
		};
	}

	componentDidMount() {
		let newsId = Object.values(this.props.match.params)[0];
		console.log(newsId);
		if (newsId === '5ed55550d00a4cb2742836d9') {
			window.location.href = '/acceptingnewpatientnews';
		} else if (newsId === '5edfe839845ea09677450d77') {
			window.location.href = '/qrcodenews';
		} else if (newsId === '5edfe59f845ea09677450d76') {
			window.location.href = '/lotussmilenews';
		} else {
			axios.get(`/englishNewsId/${newsId}`).then((res) => {
				this.setState({
					newsInfo: Object.values(res.data[0]),
					isLoading: false
				});
			});
		}
	}

	render() {
		const isLoading = this.state.isLoading;
		if (this.props.state.currentLanguage === 'english') {
			return isLoading ? (
				<div
					style={{
						width: '100vw',
						height: '100vh',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					loading
				</div>
			) : (
				<div className="newsId">
					<div className="newsId__center">
						<h2>{this.state.newsInfo[1]}</h2>
						<div>{this.state.newsInfo[2]}</div>
					</div>
				</div>
			);
		} else {
			return isLoading ? (
				<div
					style={{
						width: '100vw',
						height: '100vh',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					loading
				</div>
			) : (
				<div className="newsId">
					<div className="newsId__center">
						<h2>{this.state.newsInfo[3]}</h2>
						<div>{this.state.newsInfo[4]}</div>
					</div>
				</div>
			);
		}
	}
}
