import React, { Component } from 'react';
import virtualSchedule from '../assets/schedules/virtual_schedule.png';
import inClinicSchedule from '../assets/schedules/in-clinic_schedule.png';
import Female1 from '../assets/docImage/female1.jpeg';
export default class Schedule extends Component {
	render() {
		if (this.props.state.currentLanguage === 'english') {
			return (
				<div className="schedulePage">
					<div className='virtualTable'>
						<div className='first-block'>
							<div className='doctor-icon-container'>
								<img src={Female1} alt="virtual icon" className='doctor-icon' />
							</div>
							<h2>Virtual Schedule</h2>
						</div>

						<div className='table-container'>
							<img className="doctorTable" src={virtualSchedule} alt="virtual schedule" />
						</div>
					</div>

					<div className='inClinicTable'>
						<div className='second-block'>
							<div className='doctor-icon-container male-doctor'>
								<img src={Female1} alt="in clinic icon" className='doctor-icon' />
							</div>
							<h2>In-Clinic Schedule</h2>
						</div>

						<div className='table-container'>
							<img className="doctorTable" src={inClinicSchedule} alt="inclinic schedule" />
						</div>
					</div>

					<h4 style={{ color: 'black', marginTop: "2rem" }}>
						* Please Note this schedule is not always updated, the best option is to call us to check.{' '}
					</h4>
				</div>
			);
		} else {
			return (
				<div className="schedulePage">
					<div className='virtualTable'>
						<div className='first-block'>
							<div className='doctor-icon-container'>
								<img src={Female1} alt="virtual icon" className='doctor-icon' />
							</div>
							<h2>远程工作时间表</h2>
						</div>

						<div className='table-container'>
							<img className="doctorTable" src={virtualSchedule} alt="virtual schedule" />
						</div>
					</div>

					<div className='inClinicTable'>
						<div className='second-block'>
							<div className='doctor-icon-container male-doctor'>
								<img src={Female1} alt="in clinic icon" className='doctor-icon' />
							</div>
							<h2>诊所工作时间表</h2>
						</div>

						<div className='table-container'>
							<img className="doctorTable" src={inClinicSchedule} alt="inclinic schedule" />
						</div>
					</div>
					<h4 style={{ color: 'black', marginTop: "2rem" }}>*请注意，此时间表并非总是最新的，最好的选择是致电我们来得知医生的准确工作时间。</h4>
				</div>
			);
		}
	}
}
