import axios from 'axios';

//post 
async function  smsSignup(selectedDoctor, name, phone){
  const response = await axios.post('/smssignup', {
    selectedDoctor,
    name,
    phone
  })
  .catch((error) => {
    console.log(error);
  });
  return response;
}

async function  nextPatient(isVisitingPatient, nextPatient, smsPatients){
  const response = await axios.post('/nextPatient', {
    isVisitingPatient,
    nextPatient,
    smsPatients
  })
  .catch((error) => {
    console.log(error);
  });
  return response;
}

async function updateDoctorStatus (doctor){
  const response = await axios.post('/updateDoctorStatus', {
    doctor
  })
  .catch((error) => {
    console.log(error);
  });
  return response;
}

async function deleteDoctor (doctor){
  const response = await axios.post('/deleteDoctor', {
    doctor
  })
  .catch((error) => {
    console.log(error);
  });
  return response;
}

async function addDoctor (doctorName){
  const response = await axios.post('/addDoctor', {
    doctorName
  })
  .catch((error) => {
    console.log(error);
  });
  return response;
}

//query
async function  queryDoctor(isWorking=undefined){
  let response;
  if (isWorking !== undefined){
    response = await axios.get(`/queryDoctor/${isWorking}`)
    .catch((error) => {
      console.log(error);
    });
  } else {
    response = await axios.get('/queryDoctor/undefined')
    .catch((error) => {
      console.log(error);
    });
  }
  return response;
}

async function queryPatients(selectedDoctorObject) {
  const response = await axios.get(`/queryQueue/${selectedDoctorObject._id}`)
  .catch((error) => {
    console.log(error);
  });

  return response;
}

async function deletePatient(patient) {
  const response = await axios.post('/deletePatient', {
    patient
  })
  .catch((error) => {
    console.log(error);
  });
  return response;
}



export {
  smsSignup,
  queryDoctor,
  queryPatients,
  nextPatient,
  updateDoctorStatus,
  deletePatient,
  deleteDoctor,
  addDoctor
}
