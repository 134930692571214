import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import BackButton from '../../assets/icons/arrow-left-thick.svg';

export default class PrivatePatient extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		if (this.props.state.currentLanguage === 'english') {
			return (
				<div className="private">
					<div className="private__textDiv">
						<h2 className="private-header">Private Patient</h2>

						<h4>
							British Columbia’s Medical Services Plan (MSP) covers medically-necessary services,
							including doctor services’ and most medical tests.
						</h4>
						<h4>Only eligible residents of BC qualify for MSP coverage. Eligible residents can include:</h4>
						<ul>
							<li>a Canadian citizen</li>
							<li>a permanent resident of Canada (landed immigrant)</li>
							<li>a government-assisted refugee</li>
							<li>
								an international student with a study permit valid for six months or longer a temporary
								worker with a work permit valid for six months or longer
							</li>
						</ul>
						<h4>You may not have msp coverage due to this reason</h4>
						<ol>
							<li>Person doesnot have valid msp ( work or student visa’s msp expired)</li>
							<li>Person apply for msp but not activated yet, have to wait until it is activated</li>
							<li>
								Person not resident of BC and you need to pay individually(if you buy private insurance,
								we can give you a receipt, any acute disease covered by insurance, and you can claim the
								fee back)
							</li>
							<li>Your Guard.me Insurance does not cover certain visit</li>
							<li>Quebec’s care card not working in BC</li>
						</ol>
						<h4>These condition, patients have to pay us by debit,cash or etransfer.</h4>
						<h4>please call: (778)-379-8299 to request e-transfer email address</h4>
					</div>
					<Link className="backButton" to="/">
						<img src={BackButton} alt="backButton" /> Go Back
					</Link>
				</div>
			);
		} else {
			return (
				<div className="private1">
					<div className="private__textDiv1">
						<h2 className="private-header">自费项目</h2>

						<h4>BC的医疗服务计划（MSP）涵盖了医疗必需的服务，包括医生服务和大多数医疗检查。</h4>
						<h4>只有卑诗省符合资格的居民才有资格获得MSP保险。符合条件的居民可以包括：</h4>
						<ul>
							<li>加拿大公民</li>
							<li>加拿大永久居民（有地移民）</li>
							<li>政府援助的难民</li>
							<li>持有有效期六个月或以上的学习许可的国际学生，或持有有效期六个月或以上的工作许可的临时工</li>
						</ul>
						<h4>您可能因为如下原因没有医疗卡，或者是医疗卡失效了</h4>
						<ol>
							<li>病人没有有效的MSP（工作或学生签证的MSP已过期)</li>
							<li>病人申请了MSP但尚未激活，必须等到被激活</li>
							<li>病人不是卑诗省居民，您需要单独付款（如果您购买私人保险，我们可以给您收据，保险涵盖任何急性疾病，您可以和保险公司申请）</li>
							<li>您的学生Guard.me保险不包括某些访问</li>
							<ul>
								<li>Acne</li>
								<li>Annual / General Physical</li>
								<li>Blood Pressure Check</li>
								<li>
									Chronic Conditions (ex. Asthma, Hypertension, Cholesterol, Diabetes, Mental Health,
									ADHD, Eczema. Thyroidism, Triglycerides)
								</li>
								<li>
									Counselling (ex. Weight Control, Baldness, Dietary, Smoking Cessations, Sexual
									Health)
								</li>
								<li>Diagnostic Tests - MRI and CT Scans require prior approval</li>
								<li>Immunizations / Vaccinations (ex. HPV) / Injections / Immunity Screening </li>
								<li>TB Surveillance</li>
								<li>New Patient Exams</li>
								<li>
									Preventative, elective, or non-emergency treatment (ex. monitoring of chronic
									conditions)
								</li>
								<li>Congenital or Genetic Disorders</li>
								<li>Prescription Refills and Visits for Rx Refills</li>
								<li>Psychotherapy Counselling</li>
								<li>
									Sexual Health (ex. Birth Control / Contraception, STI Counselling, Infertility /
									Erectile Dysfunction)
								</li>
								<li>Form Completion</li>
								<li>Sick Notes</li>
								<li>Substance Use (ex. alcohol, drug)</li>
								<li>Seasonal Allergies</li>
								<li>Pregnancy-Related Visits (Call 1-888-756-8428 to confirm pregnancy coverage)</li>
							</ul>
							<li>魁北克的医疗卡卡无法在卑诗省使用</li>
							<li>搬到BC省，你的原省医疗卡失效，BC还没有申请到。</li>
						</ol>
						<h4>这些情况都是无法通过BC的医疗来帮助您，您需要自费</h4>
						<h4>在这种情况下，患者必须通过储蓄卡，现金或电子转账的方式向我们付款。</h4>
						<h4>请致电(778)-379-8299来获取网上转账邮箱</h4>
					</div>
					<Link className="backButton" to="/">
						<img src={BackButton} alt="backButton" />返回
					</Link>
				</div>
			);
		}
	}
}
