import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import BackButton from '../../assets/icons/arrow-left-thick.svg';

export default class ChartTransfer extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		if (this.props.state.currentLanguage === 'english') {
			return (
				<div className="chart">
					<div className="chart__center">
						<h3 className="chart__center-header">Chart Transfer Request</h3>
						<div>
							Medical services plan of BC does not cover the fee for transferring of medical records for
							patients. The patient is responsible for fees related to chart transfer. The fee is $50.
						</div>
						<div>
							Please fill out{' '}
							<a
								href="https://form.jotform.com/LotusJvon/tomr"
								target=" _blank"
								rel="noopener noreferrer"
							>
								RELEASE OF MEDICAL RECORDS
							</a>{' '}
							for release of information and provide payment.
						</div>
						<div>
							Once the information is received, the payment also received, the time for transfer of
							information is 15 business days.
						</div>
					</div>
					<Link className="backButton" to="/">
						<img src={BackButton} alt="backButton" /> Go Back
					</Link>
				</div>
			);
		} else {
			return (
				<div className="chart">
					<div className="chart__center">
						<h3 className="chart__center-header">转诊请求</h3>
						<div>卑诗省的医疗服务计划不包括为患者转移病历的费用。患者负责转诊有关的费用。费用为50元。</div>
						<div>
							请填写<a
								href="https://form.jotform.com/LotusJvon/tomr"
								target=" _blank"
								rel="noopener noreferrer"
							>
								此表格
							</a>{' '}
							并提交表格 并提供付款。
						</div>
						<div>收到信息后，也会收到付款，因此信息传输时间为15个工作日。</div>
					</div>
					<Link className="backButton" to="/">
						<img src={BackButton} alt="backButton" />返回
					</Link>
				</div>
			);
		}
	}
}
