import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// import './patientSection/NewPatientFamilySection'
import BackButton from '../../assets/icons/arrow-left-thick.svg';

export default class NewPatientFamily extends Component {
	constructor(props) {
		super(props);
		this.state = {
			agreedWalkIn: 'flex',
			agreedFamilyDoctor: 'flex',
			agreedVirtual: 'flex',
			minutes: 0,
			seconds: 3
			// language: this.props.state.
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		let checkFamilyDoctorToken = JSON.parse(localStorage.getItem('agreeNewFamilyDoctor'));
		if (checkFamilyDoctorToken === null || checkFamilyDoctorToken === undefined) {
			this.myInterval = setInterval(() => {
				const { seconds, minutes } = this.state;
				if (seconds > 0) {
					this.setState(({ seconds }) => ({
						seconds: seconds - 1
					}));
				}
				if (seconds === 0) {
					if (minutes === 0) {
						clearInterval(this.myInterval);
					} else {
						this.setState(({ minutes }) => ({
							minutes: minutes - 1,
							seconds: 59
						}));
					}
				}
			}, 1000);
		}
	}

	iAgreeFamilyDoctor = () => {
		if (this.state.minutes === 0 && this.state.seconds === 0) {
			localStorage.setItem('agreeNewFamilyDoctor', JSON.stringify('agreed'));
			this.setState({
				agreedFamilyDoctor: 'none'
			});
		}
	};

	render() {
		let checkFamilyDoctorToken = JSON.parse(localStorage.getItem('agreeNewFamilyDoctor'));
		if (checkFamilyDoctorToken === null || checkFamilyDoctorToken === undefined) {
			if (this.props.state.currentLanguage === 'english') {
				return (
					<div className="bookagreeDiv" style={{ display: this.state.agreedFamilyDoctor }}>
						<div className="bookagreeDiv__textDiv">
							<h4>Thank you for your interest in joining us!</h4>
							<h4>
								During the COVID-19 Pandemic, some of doctors are able to accept new patients both in
								clinic and Telemedicine visits.
							</h4>

							<h4>If you would like to become our patient, please follow these steps to register:</h4>
							<ol>
								<li>
									Please fill out the:
									<a
										href="https://form.jotform.com/LotusJvon/jm-pa-npr"
										target="_blank"
										rel="noopener noreferrer"
									>
										New Patient Registration Form
									</a>
								</li>
								<li> Read the clinic policy</li>
								<li>
									Please fill out the:
									<a
										href="https://form.jotform.com/LotusJvon/romr"
										target="_blank"
										rel="noopener noreferrer"
									>
										release of record form
									</a>
								</li>
							</ol>
							<h4>Clinic Policy</h4>
							<h4>I/WE have read, and agreed to the following:</h4>
							<h4>Online Booking:</h4>
							<h5>
								Jvon medical offer online booking. After you are a registered patient in our system we
								only accept online booking. We only help patients book online if patients are seniors,
								or having technical difficulties, or first-time patient.
							</h5>
							<h4>ICBC/WCB</h4>
							<h5>
								ICBC and WCB cases need to be closed before the doctor can accept you as a new patient.
								This is to ensure continuity of your care.
							</h5>
							<h4>Appointment Time</h4>
							<h5>
								Due to the high volume, our office encourages patients to discuss 1-2 medical issues per
								visit. If it is an emergency, the doctors with consider extra time discussing the issue.
								But if it is not an emergency, doctors will prioritize them in the allotted time and may
								ask you to schedule a follow-up visit to go through each issue thoroughly.
							</h5>
							<h5>
								It is our best interest to keep our appointments on schedule, if we do run into delays,
								please kindly wait and do not push the front staff for timing. We understand your time
								is valuable.
							</h5>
							<h4>You should See Your Own Doctors</h4>
							<h5>
								Once you are assigned to a family doctor, we will make every effort to book you an
								appointment with them. If your doctor is not available on the day you need to see a
								doctor, ie: emergency, we will book you with one of the other doctors that are on duty.
							</h5>
							<h5>
								Please see your own family doctor for your care. You cannot switch your family doctor in
								this clinic
							</h5>
							<h4>Cancelation Policy</h4>
							<h5>
								No Show Charge is $60; please provide a minimum 24-hour notice. No show charge
								appointment needs to be paid prior to the next appointment.
							</h5>
							<h4>Other medical Service</h4>
							<h5>
								I understand that some services are not covered by the provincial Medical Services Plan.
								Please ask staff and doctors for cost before you book the appointment. For example
								transfer to other clinics, sick notes, printing cost, forms, etc.
							</h5>
							<h4>Other rules</h4>

							<ol>
								<li>
									Please note we do not discuss lab reports or prescribe medication over the phone.
									Please book an appointment.
								</li>
								<li>
									Please ensure your phone number has voice mail capability, if not please provide us
									with a secondary contact number and email address. We ask this so we can contact you
									with any information regarding your care and specialist offices or other medical
									facilities may contact patients directly. If you have changed your phone number or
									address please remember to update it with us.
								</li>
								<li>
									In consideration of staff and other patients please supervise your children while in
									the waiting room and patients rooms. We are not responsible for their safety.
								</li>
								<li>Please do not use your cell phone in the reception area and room.</li>
								<li>Please note that we do not prescribe narcotics or benzodiazepines</li>
								<li>
									If you went to the hospital for an emergency, please tell the hospital the doctors’
									name instead of the clinic’s name since our system will not recognize clinic names
									but doctor names so your files can be linked together.
								</li>
								<li>When doctors leave our practice, you can follow the doctors to the new office.</li>
								<li>
									Any rude or verbal languages or rude behavior(verbal abuse, violence, threats,
									harassment and damage property) are unaccepted by our clinic. You may be asked to
									leave!
								</li>
								<li>Thank you again, and we look forward to hearing from you.</li>
							</ol>
							<div className="bookagreeDiv__emergencyDiv">
								<h3>EMERGENCIES OR CRITICAL CARE</h3>
								<h4>
									PLEASE DONOT BOOK APPOINTMENT IF YOU ARE EXPERIENCING DIFFICULTY OF BREATHING,CHEST
									PAIN OR SEVERE BLEEDING. PLEASE CALL 911 IMMIDIATLY OR GO THE NEARRST HOSPTIAL
									EMERGENCY DEPARTMENT
								</h4>
							</div>
							<button onClick={this.iAgreeFamilyDoctor}>
								I Agree {this.state.minutes}:{this.state.seconds < 10 ? (
									`0${this.state.seconds}`
								) : (
									this.state.seconds
								)}
							</button>
						</div>
					</div>
				);
			} else {
				return (
					<div className="bookagreeDiv" style={{ display: this.state.agreedFamilyDoctor }}>
						<div className="bookagreeDiv__textDiv">
							<h4>感谢您愿意选择我们诊所并且成为我们的病人</h4>
							<h4>由于新冠肺炎的疫情关系，我们的医生也可以通过网络视频接收新病人</h4>
							<h4>目前接收新病人的医生有Dr Wei, Dr Zong,Dr Gill</h4>
							<h4>如果您想成为我们的新病人 请您完成如下操作:</h4>
							<ol>
								<li>
									请网上填写我们的:
									<a
										href="https://form.jotform.com/LotusJvon/jm-pa-npr"
										target="_blank"
										rel="noopener noreferrer"
									>
										新病人表格
									</a>
								</li>

								<li>
									请填写上交
									<a
										href="https://form.jotform.com/LotusJvon/romr"
										target="_blank"
										rel="noopener noreferrer"
									>
										转病例表格
									</a>
								</li>
							</ol>
							<h4>如果需要成为J-Von Medical诊所病人，请遵守诊所如下规定</h4>

							<h4>网络预约</h4>
							<h5>
								我们诊所提供网上预约，注册之后可以在网上直接预约。我们会帮忙这些病人：A没有电脑的长者，B或者出现网络问题故障，C新病人没有来过诊所的病人。正常的情况下
								病人请到网站上直接预约。预约步骤已经贴出在诊所前台和房间里面（目前由于新冠疫情的原因，如果需要进入诊所，必须要通过电话预约）
							</h5>
							<h4>ICBC/WCB</h4>
							<h5>ICBC，WCB 的案件需要结案之后，家庭医生才能接受您为新病人，因为初次见面医生不了解之前的ICBC WCB的情况。</h5>
							<h4>预约时间</h4>
							<h5>
								因为家庭医生短缺，诊所每天会有很多病人希望就诊。请病人每次看诊尽量问1-2 个医疗问题. 如果您是遇到医疗紧急(emergency) 情况：
								医生会给您作详细的检查，会询问很多的问题
								不会当做单一问题处理。但是如果您不是性命攸关的紧急情况，医生会在规定的时间内优先考虑病情，并可能会要求您安排后续访问以彻底解决每个问题，请您跟前台再约一个时间
							</h5>
							<h5>
								医生看病的时间很难能够准确维持，所以难免需要您等待。请您耐心等待，只要您已经通报名字给给前台工作人。到时间前台工作人员一定会通知您进入房间就诊。请不要不断询问前台时间。
							</h5>
							<h4>看自己的家庭医生</h4>
							<h5>
								谁是您的家庭医生，请您约自己的家庭医生，因为这样他/她对您的情况了解，如果您的医生不在，您紧急生病了，可以预约其他座诊医生，但是我们这边没有换家庭医生的情况
								来注册时候你找哪位医生 那位医生就是您的家庭医生。其他的情况都是walk in 病人
							</h5>
							<h4>取消预约</h4>
							<h5>预约之后不通知取消预约，会有$60收费 . 麻烦您交齐之后才能下次预约。 取消通知时间至少提前24小时。</h5>
							<h4>医疗保险不包括的项目</h4>
							<h5>有些看诊费用政府医疗保险不包括：例如病假条，转诊材料邮寄费用，个别打针费用，各种表格费用等等，请您咨询前台之后，再约诊。</h5>
							<h4>其他条款</h4>

							<ol>
								<li>
									诊所不会在电话里面讨论报告结果，如果您做了检查请 记得回来诊所看报告。报告结果有问题的话,
									需要复诊治疗。检验结果正常医生也要回来复诊，因为医生会考虑需不需要做其他的检查 以确保病因。
								</li>
								<li>我们诊所不接受任何不礼貌的言语和行为(包括语言暴力，责骂，骂人，恐吓，毁坏诊所物件)。请您尊重我们的医生和工作人员。性质恶劣者会被通知离开诊所!</li>
							</ol>
							<h4>感谢您的合作</h4>
							<div className="bookagreeDiv__emergencyDiv">
								<h3>紧急情况</h3>
								<h4>如果您发现自己呼吸困难，严重的胸痛，或者大量出血，请您马上拨打911，或者去离你们家最近的医院赶紧就医!</h4>
							</div>
							<button onClick={this.iAgreeFamilyDoctor}>
								我同意 {this.state.minutes}:{this.state.seconds < 10 ? (
									`0${this.state.seconds}`
								) : (
									this.state.seconds
								)}
							</button>
						</div>
					</div>
				);
			}
		} else {
			if (this.props.state.currentLanguage === 'english') {
				return (
					<div className="newFamilyPatient">
						<div className="newFamilyPatient-center">
							<section>Thank you for your interest in joining us!</section>
							<h4>
								During the COVID-19 Pandemic, some of doctors are able to accept new patients both in
								clinic and Telemedicine visits.
							</h4>

							<h4>Make sure you have filled the following forms:</h4>
							<ol>
								<li>
									<a
										href="https://form.jotform.com/LotusJvon/jm-pa-npr"
										target="_blank"
										rel="noopener noreferrer"
									>
										Then click New Patient Medical History Registration Form
									</a>
								</li>

								<li>
									<a
										href="https://jvonebooking.mpeer.net:8881/appointment/"
										target="_blank"
										rel="noreferrer"
									>
										Register to be on waiting list.
									</a>
								</li>

								<li>
									<a
										href="https://form.jotform.com/LotusJvon/tomr"
										target="_blank"
										rel="noopener noreferrer"
									>
										Release of record form
									</a>
								</li>
							</ol>
							{/* <h3>
								If you would like to book an appointment virtually online, please click:{' '}
								<Link to="/bookvirtual">Book Virtual Appointment</Link>
							</h3> */}
							<h3>Please note: Our new patient’s waiting time is 3-6months.  After you submitting the form, you will be on waiting list and there is no guarantee you wil be acceted by our doctors. Thank you </h3>
						</div>
						<Link className="backButton" to="/bookfamilydoctor">
							<img src={BackButton} alt="backButton" /> Go Back
						</Link>
					</div>
				);
			} else {
				return (
					<div className="newFamilyPatient">
						<div className="newFamilyPatient-center">
							<section>感谢您有兴趣加入我们！</section>
							<h4>在COVID-19期间，一些医生能够在 诊所和远程医疗访问。</h4>

							<h4>确保您填写以下表格:</h4>
							<ol>
								<li>
									<a
										href="https://form.jotform.com/LotusJvon/jm-pa-npr"
										target="_blank"
										rel="noopener noreferrer"
									>
										然后点击新病人病史登记表
									</a>
								</li>

								<li>
									<a
										href="https://jvonebooking.mpeer.net:8881/appointment/"
										target="_blank"
										rel="noreferrer"
									>
										等候名单登记表register to be on waitlist
									</a>
								</li>

								<li>
									<a
										href="https://form.jotform.com/LotusJvon/tomr"
										target="_blank"
										rel="noopener noreferrer"
									>
										转诊资料（只有接收才需要填写）
									</a>
								</li>
							</ol>

							<h3>我们新病人的等候时间是3-6 个月。如果您一直没有收到我们的信息说明我们当下没有位置，请别一直等待，可以去寻找其他家庭医生。谢谢您的理解</h3>
						</div>
						<Link className="backButton" to="/bookfamilydoctor">
							<img src={BackButton} alt="backButton" /> 返回
						</Link>
					</div>
				);
			}
		}
	}
}
