import React, { Component } from 'react';

//component
import { Form, Button, Row, Col, Card, Spinner } from 'react-bootstrap';
import Modalup from './Modalup';
import DoctorEdit from './DoctorEdit';

//api
import { queryDoctor, queryPatients, nextPatient, updateDoctorStatus, deletePatient } from '../../api/sms';

export default class SmsManage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentDoctor: undefined,
			doctors: [],
			patients: [],
			isVisitingPatient: [],
			selectedPatient: undefined,
			isModalup: false,
			isLoading: false,
      isDelete: false,
      isSelectedPatientModalup: false,
      isEditDoctor: false
		};
		this.queryAllDoctor = this.queryAllDoctor.bind(this);
		this.selectDoctor = this.selectDoctor.bind(this);
		this.updateDoctorStatus = this.updateDoctorStatus.bind(this);
    this.refreshState = this.refreshState.bind(this);
    this.selectedNextPatient = this.selectedNextPatient.bind(this);
	}

	async componentDidMount() {
		this.setState({ ...this.state, isLoading: true });
		const resDoctors = await this.queryAllDoctor();
		const doctors = resDoctors.data;
		const currentDoctor = doctors[0];
		const resPatients = await queryPatients(currentDoctor);
		const patientArry = resPatients.data;
		const isVisitingPatient = patientArry.filter((patient) => patient.inQueue === false);
		const patients = patientArry.filter((patient) => patient.inQueue === true);
		return await this.setState({
			...this.state,
			doctors,
			currentDoctor,
			patients,
			isVisitingPatient,
			isLoading: false
		});
	}

	async refreshState(currentDoctor = undefined) {
    this.setState({ ...this.state, isLoading: true });
    const resDoctors = await this.queryAllDoctor();
		const doctors = resDoctors.data;
		let resPatients = {};
		if (currentDoctor) {
			resPatients = await queryPatients(currentDoctor);
		} else {
			resPatients = await queryPatients(this.state.currentDoctor);
		}
		const patientArry = resPatients.data;
		const isVisitingPatient = patientArry.filter((patient) => patient.inQueue === false);
		const patients = patientArry.filter((patient) => patient.inQueue === true);
		return await this.setState({ ...this.state, patients, isVisitingPatient, isLoading: false, doctors });
	}

	async queryAllDoctor() {
		return await queryDoctor();
	}

	async selectDoctor(event) {
		this.setState({ ...this.state, isLoading: true });
		const doctors = event.target.options;
		const selectedDoctor = doctors[event.target.selectedIndex].value;

		const selectedDoctorObject = this.state.doctors.filter((doctor) => {
			return doctor.name === selectedDoctor;
		});
		await this.refreshState(selectedDoctorObject[0]);
		return await this.setState({ ...this.state, currentDoctor: selectedDoctorObject[0], isLoading: false });
	}

	async nextPatient() {
		this.setState({ ...this.state, isLoading: true });
		let smsPatients = [];
		const { state } = this;
		if (state.patients[3]) {
			smsPatients.push(state.patients[3]);
		}
		const res = await nextPatient(state.isVisitingPatient, state.patients[0], smsPatients);
		await this.refreshState();
		return await this.setState({ ...this.state, isLoading: false, isModalup: false });
  }
  
  async selectedNextPatient() {
		const { state } = this;
    this.setState({ ...state, isLoading: true });
		//TO-DO: whether add one more text reminder for ppl in the queue
		// if (state.patients[6]){
		//   smsPatients.push(state.patients[6]);
		// }

		const res = await nextPatient(state.isVisitingPatient, state.selectedPatient, []);
		await this.refreshState();
		return await this.setState({ ...this.state, isLoading: false, isSelectedPatientModalup: false });
  }

	async updateDoctorStatus() {
		this.setState({ ...this.state, isLoading: true });
		const tempCurrentDoctor = this.state.currentDoctor;
		const response = await updateDoctorStatus(this.state.currentDoctor);
		tempCurrentDoctor.isWorking = !tempCurrentDoctor.isWorking;
		return await this.setState({ ...this.state, currentDoctor: tempCurrentDoctor, isLoading: false });
	}

	async deleteThePatient() {
		this.setState({ ...this.state, isLoading: true });
		const response = await deletePatient(this.state.selectedPatient);
		await this.refreshState();
		return await this.setState({ ...this.state, isLoading: false, isDelete: false });
	}

	render() {
		const { state } = this;
		let isVisitingPatientDom = state.isVisitingPatient.map((patient, index) => {
			const patientName = patient.name;
			const patientPhone = patient.phone;
			const createdAt = new Date(patient.createdAt).toLocaleString('en-US', { timeZone: 'America/Vancouver' });
			return (
				<Card key={index} bg="success" text="white">
					<Card.Header>Patient in Service</Card.Header>
					<Card.Body>
						<blockquote className="blockquote mb-0">
							<p>Patient Name: {patientName}</p>
							<p> Patient Phone: {patientPhone}</p>
							<p>Patient Sign up Time: {createdAt}</p>
						</blockquote>
					</Card.Body>
				</Card>
			);
		});

		let patientDom = state.patients.map((patient, index) => {
			const patientName = patient.name;
			const patientPhone = patient.phone;
			const createdAt = new Date(patient.createdAt).toLocaleString('en-US', { timeZone: 'America/Vancouver' });
			return (
				<Card key={index}>
					<Card.Header>
						<div>Position: {index + 1}</div>
						<Button
							size="lg"
							style={{ marginLeft: '0px' }}
							variant="danger"
							type="submit"
							onClick={() => {
								this.setState({ ...state, isDelete: true, selectedPatient: patient });
							}}
						>
							Delete
						</Button>
            <Button
							size="lg"
							style={{ marginLeft: '10px' }}
							variant="primary"
							type="submit"
							onClick={() => {
								this.setState({ ...state, isSelectedPatientModalup: true, selectedPatient: patient });
							}}
						>
							Select as Next
						</Button>
					</Card.Header>
					<Card.Body>
						<blockquote className="blockquote mb-0">
							<p>Patient Name: {patientName}</p>
							<p> Patient Phone: {patientPhone}</p>
							<p>Patient Sign up Time: {createdAt}</p>
						</blockquote>
					</Card.Body>
				</Card>
			);
		});

		let doctorDOM = state.doctors.map((doctor, index) => {
			return <option key={index}>{doctor.name}</option>;
		});

		return (
			<React.Fragment>
				{state.isLoading ? (
					<div className="d-flex align-items-center" style={{ position: 'fixed', top: '50%', left: '50%' }}>
						<Spinner animation="border" role="status" variant="primary" />
						<span style={{ paddingLeft: '5px' }}>Loading...</span>
					</div>
				) : (
					<div style={{ paddingTop: '200px', marginLeft: 20 }}>
						<Row>
							<Col>
								<center>
									<Form style={{ margin: '0px 0px 0px 15px' }}>
										<Form.Group controlId="doctors">
											<Form.Label>Select Doctors</Form.Label>
											<Form.Control
												as="select"
												size="lg"
												custom
												onChange={this.selectDoctor}
												value={state.currentDoctor && state.currentDoctor.name}
											>
												{doctorDOM}
											</Form.Control>
										</Form.Group>
									</Form>
								</center>
							</Col>
							<Col>
								<center>
                  <Button
										size="lg"
										style={{ margin: '25px 0px 0px 0px' }}
										variant="info"
										type="submit"
										onClick={() => {
											this.setState({ ...state, isEditDoctor: true });
										}}
									>
										Edit Doctor
									</Button>
									<Button
										size="lg"
										style={{ margin: '25px 0px 0px 10px' }}
										variant="primary"
										type="submit"
										onClick={() => {
											this.setState({ ...state, isModalup: true });
										}}
									>
										Next Patient
									</Button>
									<Button
										size="lg"
										style={{ margin: '25px 0px 0px 10px' }}
										variant="secondary"
										type="submit"
										onClick={async () => {
											return await this.refreshState();
										}}
									>
										<svg
											class="bi bi-arrow-counterclockwise"
											width="1em"
											height="1em"
											viewBox="0 0 16 16"
											fill="currentColor"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fill-rule="evenodd"
												d="M12.83 6.706a5 5 0 0 0-7.103-3.16.5.5 0 1 1-.454-.892A6 6 0 1 1 2.545 5.5a.5.5 0 1 1 .91.417 5 5 0 1 0 9.375.789z"
											/>
											<path
												fill-rule="evenodd"
												d="M7.854.146a.5.5 0 0 0-.708 0l-2.5 2.5a.5.5 0 0 0 0 .708l2.5 2.5a.5.5 0 1 0 .708-.708L5.707 3 7.854.854a.5.5 0 0 0 0-.708z"
											/>
										</svg>
										Refresh
									</Button>
								</center>
							</Col>
						</Row>
						<Row>
							<Form style={{ marginLeft: '30px' }}>
								<Form.Check
									size="lg"
									type="switch"
									checked={this.state.currentDoctor && this.state.currentDoctor.isWorking}
									id="custom-switch"
									label="Update Doctor Status "
									onChange={this.updateDoctorStatus}
								/>
							</Form>
						</Row>

						<h1>Patient in Service</h1>
						<div style={{ width: '70%' }}>{isVisitingPatientDom}</div>

						<h1>Patient is waiting</h1>
						<Row>{patientDom}</Row>

						{/* next patient update confirm */}
						<Modalup
							show={state.isModalup}
							buttons
							onHide={async () => {
								await this.refreshState();
								return await this.setState({ ...state, isModalup: false });
							}}
							title={'Warning'}
							body={'Are you sure you want to UPDATE to the next patient'}
							handleConfirm={async () => {
								return await this.nextPatient();
							}}
						/>
						{/* delete patient confirm */}
						<Modalup
							show={state.isDelete}
							buttons
							onHide={async () => {
								await this.refreshState();
								return await this.setState({ ...state, isDelete: false });
							}}
							title={'Warning'}
							body={'Are you sure you want to DELETE this patient'}
							handleConfirm={async () => {
								return await this.deleteThePatient();
							}}
						/>
            <Modalup
							show={state.isSelectedPatientModalup}
							buttons
							onHide={async () => {
								await this.refreshState();
								return await this.setState({ ...state, isSelectedPatientModalup: false });
							}}
							title={'Warning'}
							body={state.selectedPatient && `Are you sure you want to UPDATE ${state.selectedPatient.name} to the next patient`}
							handleConfirm={async () => {
								return await this.selectedNextPatient();
							}}
						/>      
    
            <DoctorEdit
							show={state.isEditDoctor}
							onHide={async () => {
								await this.refreshState();
								return await this.setState({ isEditDoctor: false });
							}}
							title={'Edit Dotors'}
						/>      
					</div>
				)}
			</React.Fragment>
		);
	}
}