import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';

export default function Modalup(props) {

  return (
    <React.Fragment>
      <Modal 
        show={props.show} 
        onHide={props.onHide} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.body}</Modal.Body>
        <Modal.Footer>
          { props.buttons ?
            <Row>
              <Col>
                <Button size="lg" variant="primary" onClick={props.handleConfirm}>
                    Ok
                </Button>
              </Col>
              <Col>
                <Button size="lg" variant="secondary" onClick={props.onHide}>
                  Cancel
                </Button>
              </Col>
            </Row>
            :
            <center>
              <Button size="lg" variant="secondary" onClick={props.onHide}>
                {props.button}
              </Button>
            </center>
          }
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}