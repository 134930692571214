import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import BackButton from '../../assets/icons/arrow-left-thick.svg';

export default class TestResult extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		if (this.props.state.currentLanguage === 'english') {
			return (
				<div className="testResult">
					<div className="testResult__center">
						<h2 className="testResult__center-header ">Looking for my test result</h2>
						<div>
							Patients should follow up their own results in a timely manner. If your results are
							abnormal, we will contact you through phone, email. Again make sure you have two phone
							numbers and voicemail set up and your email and phone numbers should be updated.
						</div>
						<div>
							Our clinic does not adopt the resulting policy of” you donot contact me meaning I am
							healthy”. No matter the result is normal or abnormal; you should both come to the clinic to
							see doctors review your results or do virtual visit with doctors.
						</div>
						<div>
							<h4>Can patient receive result by email?</h4>
							<section>
								Sorry, due to privacy protection, we donot provide the option to email patient copy of
								the result. If patients would like to obtain a copy of their test results, they should
								sign up for E-health while going to lifelabs. For all other results, patients can ask
								the front to help you print a hard copy after you discussing result with the doctor.
							</section>
						</div>
						<Link className="backButton" to="/">
							<img src={BackButton} alt="backButton" /> Go Back
						</Link>
					</div>
				</div>
			);
		} else {
			return (
				<div className="testResult">
					<div className="testResult__center">
						<h2 className="testResult__center-header ">寻找我的测试结果</h2>
						<div>患者应及时随访自己的报告结果。如果您的结果需要复诊，我们将通过电话，电子邮件与您联系。确保您设置了两个电话号码和语音邮件，并且应该更新您的电子邮件地址和电话号码。</div>
						<div>我们的诊所从来不遵循“您不联系我，就意味着我健康”的政策。无论结果是正常还是异常；患者都应该去诊所看医生检查您的结果或与医生进行网络访问。</div>
						<div>
							<h4>患者可以通过电子邮件接收结果吗？</h4>
							<section>
								抱歉，由于隐私保护，我们不提供通过电子邮件将结果的电子邮件发送给患者的选项。如果患者想获得其测试结果的副本，则应在前往实验室时申请可以在网上看，请您与
								lifelab联系。对于所有其他结果，患者可以在与医生讨论结果后要求前台帮助您打印纸质副本，但是会有打印费用。
							</section>
						</div>
						<Link className="backButton" to="/">
							<img src={BackButton} alt="backButton" /> 返回
						</Link>
					</div>
				</div>
			);
		}
	}
}
