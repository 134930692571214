import React, { Component } from 'react';

export default class ClinicVirutual extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    if (this.props.props.currentLanguage === 'english') {
      return (
        <div className="virtual">
          <div className="virtual__bigTextDiv">
            <h3 className="virtual__bigTextDiv-header">Resources </h3>
            <div className="virtual__bigTextDiv-firstDiv">

              <div className=''>
                <h3>
                  Xray and Ultrasound
                </h3>
                <a href="https://www.brookeimaging.ca/about.php" target="_blank"
                  rel="noopener noreferrer" className='resource-atag'>Brooke</a> <br />

                <a href="https://www.westcoastmedicalimaging.com/location-list" target="_blank"
                  rel="noopener noreferrer" className='resource-atag'>West Coast Medical Imaging</a><br /><br />
              </div>

              <div className='resourcePage-container'>
                <h3>Lab Tests and Results</h3>
                <a href="https://www.lifelabs.com/" target="_blank"
                  rel="noopener noreferrer" className='resource-atag'>  LifeLabs</a><br /><br />
              </div>

              <div className='resourcePage-container'>
                <h3>
                  Emergency
                </h3>
                <a href="https://www.fraserhealth.ca/Service-Directory/Service-At-Location/D/7/metrotown-urgent-and-primary-care-centre" target="_blank"
                  rel="noopener noreferrer" className='resource-atag'>Metrotown Urgent and Primary Care Centre</a><br />

                <a href="https://www.fraserhealth.ca/Service-Directory/Locations/Burnaby/burnaby-hospital#.X85wSchKhaQ" target="_blank"
                  rel="noopener noreferrer" className='resource-atag'>
                  Burnaby Hospital
                </a><br /><br />

              </div>

            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="virtual">
          <div className="virtual__bigTextDiv">
            <h3 className="virtual__bigTextDiv-header">Resources </h3>
            <div className="virtual__bigTextDiv-firstDiv">

              <div className=''>
                <h3>
                  X光片和超声波
                </h3>
                <a href="https://www.brookeimaging.ca/about.php" target="_blank"
                  rel="noopener noreferrer" className='resource-atag'>Brooke</a> <br />

                <a href="https://www.westcoastmedicalimaging.com/location-list" target="_blank"
                  rel="noopener noreferrer" className='resource-atag'>West Coast Medical Imaging</a><br /><br />
              </div>

              <div className='resourcePage-container'>
                <h3>实验室测试和结果</h3>
                <a href="https://www.lifelabs.com/" target="_blank"
                  rel="noopener noreferrer" className='resource-atag'>  LifeLabs</a><br /><br />
              </div>

              <div className='resourcePage-container'>
                <h3>
                  急救中心
                </h3>
                <a href="https://www.fraserhealth.ca/Service-Directory/Service-At-Location/D/7/metrotown-urgent-and-primary-care-centre" target="_blank"
                  rel="noopener noreferrer" className='resource-atag'>Metrotown Urgent and Primary Care Centre</a><br />

                <a href="https://www.fraserhealth.ca/Service-Directory/Locations/Burnaby/burnaby-hospital#.X85wSchKhaQ" target="_blank"
                  rel="noopener noreferrer" className='resource-atag'>
                  Burnaby Hospital
                </a><br /><br />

              </div>

            </div>
          </div>
        </div>
      );
    }
  }
}
