import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import ArrowRight from '../assets/icons/arrow-right.svg';

export default class BookWalkIn extends Component {
	constructor(props) {
		super(props);
		this.state = {
			wholeHover: 'flex',
			firstPop: 'flex',
			secondPop: 'none',
			thirdPop: 'none'
		};
	}
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	toSecondPop = () => {
		this.setState({
			firstPop: 'none',
			secondPop: 'flex',
			thirdPop: 'none'
		});
	};
	toThirdPop = () => {
		this.setState({
			firstPop: 'none',
			secondPop: 'none',
			thirdPop: 'flex'
		});
	};

	iAgree = () => {
		this.setState({
			wholeHover: 'none'
		});
	};

	render() {
		// console.log(this.props);
		// let checkVirtualToken = JSON.parse(localStorage.getItem('agreeVirtual'));
		let agreementDiv;
		if (this.props.state.currentLanguage === 'english') {
			agreementDiv = <div className="agreementDiv" style={{ display: this.state.wholeHover }}>
				<div className="agreementDiv__textDiv" style={{ display: this.state.firstPop }}>
					<section class="agreementDiv__section">Thank You for your patience</section>
					<h3>
						We are currently available for phone or video visits as well as in-clinic visits
					</h3>
					<h3>
						If you are a existing patient, for video or phone visits with a doctor, click:{' '}
						{/* <Link to="/bookvirtual">Book Virtually</Link>. */}
						<a
							href="https://jvonebooking.mpeer.net:8881/appointment/"
							target="_blank"
							rel="noopener noreferrer"
						>
							Book Virtually
						</a>.
					</h3>
					<h3>
						If you are a new patient that has never been here before, please click: {' '}
						<a class="bookingFamilyNewOrOld__new-link" href="https://jvonebooking.mpeer.net:8881/appointment/" target='_blank' rel="noreferrer" >New Patient Online Booking</a>.
					</h3>
					<h3>We are also available for same day walk in appointments</h3>
					<h3>
						But we DO NOT see these symptoms physically in the clinic but doctors can see you
						virtually (online)
					</h3>
					<ol style={{ marginTop: 20 }}>
						<li>Any cold symptoms (fever, cough, sore throat)</li>
						<li>Any Allergy symptoms (coughing, sneezing)</li>
						<li>
							Any covid-19 related similar symptoms (short of breath, conjunctivitis,
							bronchitis, loss of taste or smell, or the virus related to children (Kawasaki
							disease)
						</li>
					</ol>
					<h3>
						If you booked with any above symptoms, we have to switch you to the Virtual medical
						visit. Thank you for showing kindness and support in these difficult times.
					</h3>
					<div class="agreementDiv__buttonDiv">
						<button onClick={this.toSecondPop}>Next</button>
					</div>
				</div>
				<div className="agreementDiv__textDiv" style={{ display: this.state.secondPop }}>
					<section class="agreementDiv__section">Thank You for your patience</section>

					<h3>
						For everyone who wants to attend the clinic, you already knew we DONOT SEE cold
						systems and covid-19 systems and these symptoms only book online
					</h3>
					<ol style={{ marginTop: 20 }}>
						<li>
							Please make sure you wear a mask, without a mask cannot get in to the clinic
						</li>
						<li>
							Please DO NOT take your masks off unless the doctors ask you to, and after
							please wear them back.
						</li>
						<li>We need to check your body temperature before you get in.</li>
						<li>Only maximum 5 people can stay in the receptionist area</li>
						<li>
							Others patients please stay outside or wait in your car, or go to the coffee
							shop next to us.
						</li>
					</ol>
					<h3>We try our best to protect our patients.</h3>
					<div class="agreementDiv__buttonDiv">
						<button onClick={this.toThirdPop}>Next</button>
					</div>
				</div>
				<div className="agreementDiv__textDiv" style={{ display: this.state.thirdPop }}>
					<section class="agreementDiv__section">Thank You for your patience</section>

					<h3 style={{ textAlign: 'center' }}>Covid-19 J-Von Clinic’s New Protocol</h3>
					<h3>Start from: Oct 1, 2020.</h3>
					<h3>
						Due to the rise of Covid-19 cases, we have come out with new protocols for our clinic. Please respect
						our policy, our main purpose is to protect everybody, if any patients don&#39;t follow or break our rules we
						will give a warning and serious level we may ask that patient to leave because we have to protect our
						patients.
					</h3>
					<h3>Our new rules are here:</h3>
					<h3>Our office door will be locked.</h3>
					<ol style={{ marginTop: 20 }}>
						<li>Walk in pts needs to call us to book appointments before coming inside of the clinic.</li>
						<li>
							Patients that have appointments will need to wait from their cars or outside(a text message will
							be sent to them once they have checked-in using the QR code).Please wear enough clothes
							since the weather is getting colder.
						</li>
						<li>
							We will call individual patients when it is their turn and please come in one by one. If you wish
							to stay in your car, we can text you and once it is your turn please knock the door.
						</li>
						<li>
							At this time we are only seeing (babies, pregnant women, back pain, ICBC, WCB,) other issues
							will be by phone or virtual calls.
						</li>
						<li>
							For any patients who need assistance only 1 person is allowed to accompany the patient to
							come in.
						</li>
						<li>
							Doctors required patients to come inside the clinic, that individual can only be booked by the
							doctor who requested them to come.
						</li>
						<li>
							Due to Covid-19 Virus number keeps rising up, we cannot accommodate so many walk in pts.
							We can only offer registered patients for pap test at this time.
						</li>
						<li>
							When flu shots season coming, and we only have limited space for flu shots. You can also reach
							to public health or pharmacy to get the flu shots.
						</li>
					</ol>
					<h3>Thank you. Let us help each other. We are all in this together!</h3>
					<div class="agreementDiv__buttonDiv">
						<button onClick={this.iAgree}>I Agree</button>
					</div>
				</div>
			</div>
		} else {
			agreementDiv = <div className="agreementDiv" style={{ display: this.state.wholeHover }}>
				<div className="agreementDiv__textDiv" style={{ display: this.state.firstPop }}>
					<h3>为了能够更加保证每一个病人的安全，我们诊所最新出台了一系列保证病人安全的须知。</h3>
					<h3>目前，我们接受网络远程视频看病以及电话问诊，也可以打电话预约 我们允许病人进入诊所看病。</h3>
					<h3>
						如果您是现有患者,要与医生进行视频或电话访问，请点击{' '}
						<a
							href="https://jvonebooking.mpeer.net:8881/appointment/"
							target="_blank"
							rel="noopener noreferrer"
						>
							网络预约
						</a>。
					</h3>
					<h3>
						如果您从来没有来过，要与医生进行视频或电话访问，请点击<a class="bookingFamilyNewOrOld__new-link" href="https://jvonebooking.mpeer.net:8881/appointment/" target='_blank' rel="noreferrer" >新病人网络预约</a>.
					</h3>
					<h3>我们也接受当天的临时预约</h3>
					<h3>但是，有以下症状症状的人，医生会和您进行远程视频看病或者电话看诊</h3>
					<ol style={{ marginTop: 20 }}>
						<li>任何感冒症状（例如发烧，咳嗽，嗓子发炎）</li>
						<li>任何过敏症状，例如打喷嚏，咳嗽)</li>
						<li> 任何和新冠症状类似的情况（呼吸困难，红眼病，肺炎，失去味觉和嗅觉 儿童川崎病）</li>
					</ol>
					<h3>
						为了保证病人的安全，如上症状的我们都无法让您进入诊所看病，但是医生愿意在远程网络给你看病问诊，帮你开单子去做检查。其他的可以选择打电话预约进入诊所看病，或者是网上直接预约视频看病或者电话问诊
					</h3>
					<h4>谢谢</h4>
					<div class="agreementDiv__buttonDiv">
						<button onClick={this.toSecondPop}>下一页</button>
					</div>
				</div>
				<div className="agreementDiv__textDiv" style={{ display: this.state.secondPop }}>
					<h3>对于每个进入诊所看病的病人</h3>
					<ol style={{ marginTop: 20 }}>
						<li>你必须得戴口罩近来，没有口罩，不允许进到诊所</li>
						<li>口罩在看病途中都必须戴着 除非医生让你摘下来，之后请你赶紧戴回去</li>
						<li>我们必须检查你体温才能进去，如果你的体温超乎常规，我们会理解告诉你回家，给你安排网络视频</li>
						<li>不能有人陪同进来看病只能是需要看病的那个病人才能进来，小孩子可以由一个父母陪同。</li>
						<li>每次诊所前面最多可以容纳4-5个人在前台位置，不可以超过。</li>
					</ol>
					<h3>感谢您在这段特殊时期对我们的工作配合，这样会更好的保护每一个人的安全. 谢谢大家的配合!</h3>
					<div class="agreementDiv__buttonDiv">
						<button onClick={this.toThirdPop}>下一页</button>
					</div>
				</div>
				<div className="agreementDiv__textDiv" style={{ display: this.state.thirdPop }}>
					<h3 style={{ textAlign: 'center' }}>Covid-19 J-Von Clinic’s New Protocol</h3>
					<h3>
						因为疫情的数字比以前提升，我们诊所最新出台了一些新的诊所规定。为了大家好，请
						遵守我们的规章，也麻烦请大家配合我们的工作。 如果任何违法我们的规章，我们就无法帮助您看病
						了。我们主要是需要保护每一个病患每一个人的安全。
					</h3>
					<h3>我们的规定如下 10月1日开始， 诊所的门会锁上，只有预约的人我们核对名字门才会开。</h3>
					<ol style={{ marginTop: 20 }}>
						<li>
							没有预约的病人麻烦请不要直接进入诊所 我们只接受预约的病人进入。但是
							大家放心，可以在网络上预约电话问诊 都可以看病。只是方式变化了。 或者你们电话咨询我们 我
							们帮你们安排。 为了保证其他病人的安全，只有符合必要看病条件才能进入诊所看病.何为符合条件
							请参考第四条
						</li>
						<li>
							已经预约并且需要进入诊所的病人：为了保证其他病人安全，需要在外面等
							的记得请多穿衣服 秋季已经转凉。也可以在车里面等，我们可以发短信给你。没有被点名叫进来的病
							人不能进入诊所，请配合我们工作，以及体谅其他病人安全。
						</li>
						<li>
							到了您的时间，您如果在门口我们就喊您的名字。尽量开车，在车里我们用
							短信通知您。 麻烦被点名的病人进来
						</li>
						<li>
							我们目前进入诊所看诊只有此类看诊项目(婴儿常规检查，孕妇常规检查，背
							痛，车祸和工伤）其他的都需要电话预约来安排医生电话问诊
						</li>
						<li>
							为了规避一定人数请麻烦不要带家属进来，我们会在门口喊您的名字，预约
							的名字是谁那个人才能进入诊所，小宝宝和行动不方便的长者我们理解，允许一位家人陪伴。不是给
							大家添麻烦，是保护每一个人。
						</li>
						<li>
							医生要求进入诊所复诊的只能看那位医生。因为现在医生的看诊位置很紧
							张，其他的医生病人也需要看自己病人病。
						</li>
						<li>
							我们最近发现，一些其他诊所的病人 他们诊所不开，他们不给自己病人看妇科之后把这批病人转
							接到我们这里。而我们帮了好久的忙 现在无法负荷这些不断累积人数 疫情期间我们进来的人数是需
							要合理控制的 而且我们的病人 我们需要优先照顾目前没有来过我们诊所请和你的家庭医生商讨做妇
							科检查
						</li>
						<li>
							流感疫苗季节大约在11月份，由于控制人数，进入诊所打疫苗也是有限的。
							但是大家不用担心 可以去这个机构(public health)打流感疫苗还有药房都可以打疫苗。
						</li>
					</ol>
					<h3>
						困难时期 我们一起渡过，请大家保重身体，也感谢配合我们的工作。希望我们诊所进来每一个病
						人都安全.
					</h3>
					<div class="agreementDiv__buttonDiv">
						<button onClick={this.iAgree}>我同意</button>
					</div>
				</div>
			</div>
		}
		if (this.props.match.path === '/bookwalkin') {
			if (this.props.state.currentLanguage === 'english') {
				return (
					<div>
						{agreementDiv}
						<div className="bookingFamilyNewOrOld">
							<h3 className="bookingFamilyNewOrOld-header">Welcome to J-Von Medical Center</h3>
							<h4 className="bookingFamilyNewOrOld-subHeader">Book Walk In</h4>
							<h5 className="bookingFamilyNewOrOld-selectHeader">Please make a selection:</h5>
							<div className="bookingFamilyNewOrOld__new">
								<div className="bookingFamilyNewOrOld__old">
									<h4>New WalkIn Patient</h4>

									<a class="bookingFamilyNewOrOld__new-link" href="https://jvonebooking.mpeer.net:8881/appointment/" target='_blank' rel="noreferrer" >
										<div>
											New Walk in Patient wanting to book a walk in appointment<img src={ArrowRight} alt="arrow-right" />
										</div>
									</a>
								</div>
								<div className="bookingFamilyNewOrOld__old">
									<h4>Existing Patient</h4>
									<Link to="/oldwalkinpatient" className="bookingFamilyNewOrOld__new-link">
										<div>
											Existing J-Von Patient wanting to book a walkin appointmentt<img src={ArrowRight} alt="arrow-right" />
										</div>
									</Link>
								</div>
							</div>
						</div>
					</div>
				);
			} else {
				return (
					<div>
						{agreementDiv}
						<div className="bookingFamilyNewOrOld">
							<h3 className="bookingFamilyNewOrOld-header">欢迎来到康嘉西医诊所</h3>
							<h4 className="bookingFamilyNewOrOld-subHeader">想要预约临时看病</h4>
							<h5 className="bookingFamilyNewOrOld-selectHeader">请选择:</h5>
							<div className="bookingFamilyNewOrOld__new">
								<div className="bookingFamilyNewOrOld__old">
									<h4>新临时预约病人</h4>

									<a class="bookingFamilyNewOrOld__new-link" href="https://jvonebooking.mpeer.net:8881/appointment/" target='_self'>
										<div>
											新临时预约病人想要预约<img src={ArrowRight} alt="arrow-right" />
										</div>
									</a>
								</div>
								<div className="bookingFamilyNewOrOld__old">
									<h4>现有病人</h4>
									<Link to="/oldwalkinpatient" className="bookingFamilyNewOrOld__new-link">
										<div>
											现有病人想要预约临时门诊<img src={ArrowRight} alt="arrow-right" />
										</div>
									</Link>
								</div>
							</div>
						</div>
					</div>
				);
			}
		} else if (this.props.match.path === '/bookfamilydoctor') {
			if (this.props.state.currentLanguage === 'english') {
				return (
					<div>
						{agreementDiv}
						<div className="bookingFamilyNewOrOld">
							<h3 className="bookingFamilyNewOrOld-header">Welcome to J-Von Medical Center</h3>
							<h4 className="bookingFamilyNewOrOld-subHeader">Book Family Doctor</h4>
							<h5 className="bookingFamilyNewOrOld-selectHeader">Please make a selection:</h5>
							<div className="bookingFamilyNewOrOld__new">
								<div className="bookingFamilyNewOrOld__old">
									<h4>New Patient</h4>

									<Link className="bookingFamilyNewOrOld__new-link" to="/newbookinpatient">
										<div>
											New patient wanting to book for a family doctor<img src={ArrowRight} alt="arrow-right" />
										</div>
									</Link>
								</div>
								<div className="bookingFamilyNewOrOld__old">
									<h4>Existing Patient</h4>
									<Link to="/oldbookinpatient" className="bookingFamilyNewOrOld__new-link">
										<div>
											Existing J-Von Patient wanting to book a family doctor<img src={ArrowRight} alt="arrow-right" />
										</div>
									</Link>
								</div>
							</div>
						</div>
					</div>
				);
			} else {
				return (
					<div>
						{agreementDiv}
						<div className="bookingFamilyNewOrOld">
							<h3 className="bookingFamilyNewOrOld-header">欢迎来到康嘉西医诊所</h3>
							<h4 className="bookingFamilyNewOrOld-subHeader">预约家庭医生</h4>
							<h5 className="bookingFamilyNewOrOld-selectHeader">请选择:</h5>
							<div className="bookingFamilyNewOrOld__new">
								<div className="bookingFamilyNewOrOld__old">
									<h4>新病人</h4>

									<Link className="bookingFamilyNewOrOld__new-link" to="/newbookinpatient">
										<div>
											新病人想要预约家庭医生<img src={ArrowRight} alt="arrow-right" />
										</div>
									</Link>
								</div>
								<div className="bookingFamilyNewOrOld__old">
									<h4>现有病人</h4>
									<Link to="/oldbookinpatient" className="bookingFamilyNewOrOld__new-link">
										<div>
											现有病人想要预约网上家庭医生<img src={ArrowRight} alt="arrow-right" />
										</div>
									</Link>
								</div>
							</div>
						</div>
					</div>
				);
			}
		} else if (this.props.match.path === '/bookvirtual') {
			if (this.props.state.currentLanguage === 'english') {
				return (
					<div>
						<div className="bookingFamilyNewOrOld">
							<h3 className="bookingFamilyNewOrOld-header">Welcome to J-Von Medical Center</h3>
							<h4 className="bookingFamilyNewOrOld-subHeader">Book Doctor Viruatlly</h4>
							<h5 className="bookingFamilyNewOrOld-selectHeader">Please make a selection:</h5>
							<div className="bookingFamilyNewOrOld__new">
								<div className="bookingFamilyNewOrOld__old">
									<h4>New Patient</h4>

									<a class="bookingFamilyNewOrOld__new-link" href="https://jvonebooking.mpeer.net:8881/appointment/" target='_self'>
										<div>
											New Patient looking to book a doctor virtually<img src={ArrowRight} alt="arrow-right" />
										</div>
									</a>
								</div>
								<div className="bookingFamilyNewOrOld__old">
									<h4>Exisitng Patient</h4>
									<Link to="/oldvirtualpatient" className="bookingFamilyNewOrOld__new-link">
										<div>
											Existing Patient wanting to book a doctor virtually<img src={ArrowRight} alt="arrow-right" />
										</div>
									</Link>
								</div>
							</div>
						</div>
					</div>
				);
			} else {
				return (
					<div className="bookingFamilyNewOrOld">
						<h3 className="bookingFamilyNewOrOld-header">欢迎来到康嘉西医诊所</h3>
						<h4 className="bookingFamilyNewOrOld-subHeader">预约网络远程看病</h4>
						<h5 className="bookingFamilyNewOrOld-selectHeader">请选择:</h5>
						<div className="bookingFamilyNewOrOld__new">
							<div className="bookingFamilyNewOrOld__old">
								<h4>新病人</h4>

								<Link className="bookingFamilyNewOrOld__new-link" to="/newbookinpatient">
									<div>
										新病人想要预约家庭医生<img src={ArrowRight} alt="arrow-right" />
									</div>
								</Link>
							</div>
							<div className="bookingFamilyNewOrOld__old">
								<h4>现有病人</h4>
								<Link to="/oldbookinpatient" className="bookingFamilyNewOrOld__new-link">
									<div>
										现有病人想要预约网上家庭医生<img src={ArrowRight} alt="arrow-right" />
									</div>
								</Link>
							</div>
						</div>
					</div>
				);
			}
		}
	}
}
