import React, { Component } from 'react';

export default class ClinicService extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		if (this.props.props.currentLanguage === 'english') {
			return (
				<div className="clinicServiceDiv">
					{/* <h1 className="clinicServiceDiv-header"> our services</h1> */}
					<section className="clinicServiceDiv-section">
						J-Von Medical Clinic is to provide communities with medical services for your optimal health and
						wellness. Our experienced physicians are Canadian trained, and Board certified in family
						medicine. Our physicians work hard to fully understand their unique needs and build
						relationships with our customers. We work collaboratively to provide precisely tailored, timely,
						and predictable solutions at the right cost.
					</section>
					<ul className="clinicServiceDiv-ul">
						<li>Comprehensive family medicine</li>
						<li>women's health and prenatal maternity care</li>
						<li>Pediatric and infant medical care</li>
						<li>Men’s health and check-ups</li>
						<li>Prescription renewals and medical tests</li>
						<li>Chronic Disease management</li>
						<li>ICBC and WorkSafe BC claims (no walk-in ICBC)</li>
						<li>Insurance forms and doctor’s notes</li>
						<li>Referrals to specialists and allied health services</li>
						<li>Urgent walk-in care.</li>
						<li>Sports medicine, injuries, stitches, and wound care</li>
						<li>
							Travel medicine, vaccinations, and routine immunizations( we donot provide baby routine
							vaccination)
						</li>
					</ul>
				</div>
			);
		} else {
			return (
				<div className="clinicServiceDiv">
					{/* <h1 className="clinicServiceDiv-header">诊所服务项目</h1> */}
					<section className="clinicServiceDiv-section">
						J-Von Medical Clinic康嘉西医将为社区提供最佳健康和保健的医疗服务。<br />我们的服务包括:
					</section>
					<ul className="clinicServiceDiv-ul">
						<li>综合家庭医学</li>
						<li>妇女的健康和产前产妇保健</li>
						<li>儿科和婴儿医疗</li>
						<li>男性的健康和体检</li>
						<li>处方续签和医学检验</li>
						<li>慢性病管理</li>
						<li>ICBC和WorkSafe BC索赔（临时看病的ICBC我们不看）</li>
						<li>保险单和医生说明</li>
						<li>推荐给专家和相关的健康服务</li>
						<li>紧急门诊服务。</li>
						<li>运动医学，受伤，缝线和伤口护理</li>
						<li>旅行药品，疫苗接种和常规免疫接种（我们不提供婴儿常规疫苗接种）</li>
					</ul>
				</div>
			);
		}
	}
}
